define("comunidades-nafin/components/nom-035-stps/reportes/cards/acontecimientos-traumaticos-severos-card", ["exports", "comunidades-nafin/components/human-talent-components/h-t-card"], function (_exports, _hTCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTCard.default.extend({
    data: {
      total: 0,
      data: {
        aplicados: 0,
        restantes: 0,
        sin_ats: {
          total: 0
        },
        con_ats: {
          total: 0
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('data', this.data);
    },
    didInsertElement: function didInsertElement() {
      // Colocamos activa la tarjeta seleccionada
      var url = $(location).attr('href');
      var parts = url.split("/");
      var modulo = parts.pop();

      if (modulo === 'acontecimientos-traumaticos-severos') {
        $("#atsCard > .wrap_nom_hover").attr('style', 'opacity: 1 !important;');
        $("#atsCard > .items-center > p").attr('style', 'color: #FFFFFF; !important;');
        $("#atsCard .lbl_principal").attr('style', 'color: #FFFFFF !important;');
        $("#atsCard .text-theme").attr('style', 'color: #FFFFFF !important;');
      }
    },
    actions: {}
  });

  _exports.default = _default;
});