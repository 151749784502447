define("comunidades-nafin/routes/nom-037/dashboard", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: 'Estatus de los cuestionarios por trabajador',
    clase: 'nom037/administrador',
    metodo: 'audienciaResumenPorTrabajador',
    reporte: 'nom035/export/audienciaReporteResumenPorTrabajador',
    periodo_k: null,
    params: {},
    usuario_k: 0,
    direccion_visita_k: 0,
    id_colaborador_direccion_k: 0,
    datatableName: "colaboradoresTable",
    tabColaboradores: "",
    detailRows: [],
    deleteAddress: null,
    _controller: null,
    currentDomicilio: null,
    headers: [{
      dataIndex: '',
      title: ''
    }, {
      dataIndex: 'url_reporte_pdf',
      title: ''
    }, {
      dataIndex: 'datos_trabajador',
      title: 'Datos de Trabajador'
    }, {
      dataIndex: 'centro_trabajo',
      title: 'Centro de Trabajo'
    }, {
      dataIndex: 'estatus',
      title: 'Estatus'
    }, {
      dataIndex: 'fecha_ultima_actualizacion',
      title: 'Ultima Actualización'
    }, {
      dataIndex: 'evaluacion',
      title: 'Lista de verificación'
    }, {
      dataIndex: 'estatus_candidato_teletrabajo',
      title: 'Estatus candidato'
    }, {
      dataIndex: 'motivo_rechazo',
      title: 'Motivo rechazo'
    }, {
      dataIndex: 'usuario_k',
      title: ''
    }],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.resetNewEvent(this);
    },
    setupController: function setupController(controller) {
      var self = this;
      self._controller = controller;
      setTimeout(function () {
        $("#loading").fadeOut(1500); //self.loadComboData(controller);

        controller.set('title', self.get("title"));
        controller.set('headersTable', self.get("headers"));
        controller.set('datatableName', self.get("datatableName"));
        controller.set('reporteExcelColaboradores', window.constant.APIURL + "nom037/administrador/reporteExcelColaboradores");
        controller.set("direcciones", []);
        controller.set("colaborador_header", "");
        controller.set("domicilio_visita", "");
        controller.set("id_colaborador_direccion_k", "");
        controller.set("url_google_maps", "");
        controller.set("domicilio", "");
        controller.set("anios", []);
        controller.set("anio_actual", "");
        controller.set("mes_actual", "");
        controller.set("historial", []);
        controller.set("lbl_form", "");
        controller.set("lbl_campo", "");
        controller.set("nombre_usuario", "");
        controller.set("evidencia_usuario_k", "");
        controller.set("tipo_evidencia", ""); // equipo o medica

        controller.set('ap_apoyo_k', "");
        controller.set('ap_usuario_k', "");
        controller.set('ap_tipo_evidencia', "");
        self.loadData(controller);
      }, 1000);
    },
    loadComboData: function loadComboData(controller) {
      var self = this;
      /*
       * Workaround. cargamos dependencias de combo
       */

      $.ajax({
        url: constant.APIURL + 'nom035stps/reporteadministrador/listarcentrosdetrabajo',
        type: "post",
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            controller.set('inputGroupSelectCentrosDeTrabajoData', response.data);
            controller.inputGroupSelectCentrosDeTrabajoData = response.data;
          }

          console.log(controller.get('inputGroupSelectCentrosDeTrabajoData'));
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    detalle_apoyos: function detalle_apoyos(self, usuario_k, tipo_evidencia) {
      var div = $('<div/>').addClass('loading').text('Cargando...');
      $.ajax({
        url: constant.APIURL + String(self.get("clase")) + '/detalle_apoyo',
        type: "post",
        data: {
          usuario_k: usuario_k,
          tipo_evidencia: tipo_evidencia
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        success: function success(html) {
          div.html(html).removeClass('loading');
        }
      });
      return div;
    },
    loadData: function loadData(controller) {
      var self = this;

      self._controller.send("fullcalendar");

      if (String(self.get("metodo")).length > 0 && String(self.get("clase")).length > 0) {
        $.ajax({
          url: constant.APIURL + String(self.get("clase")) + '/' + String(self.get("metodo")),
          type: "post",
          data: {
            params: self.params,
            start: self.start
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              controller.set('dataTable', response.data);
              controller.set('total_records', response.numFilas);

              if (self.start + self.pageSize > self.total_records) {
                self.set('buttonNextIsDisabled', true);
              } else {
                self.set('buttonNextIsDisabled', false);
              }

              setTimeout(function () {
                self.tabColaboradores = $("#" + self.get('datatableName')).DataTable({
                  language: {
                    "decimal": "",
                    "emptyTable": "Sin registros",
                    "info": "Mostrando del _START_ al  _END_ de _TOTAL_ Registros",
                    "infoEmpty": "Mosrando 0 to 0 de 0 registros",
                    "infoFiltered": "(Filtro _MAX_ registros totales)",
                    "infoPostFix": "",
                    "thousands": ",",
                    "lengthMenu": "Mostrar _MENU_ registros",
                    "loadingRecords": "Cargando...",
                    "processing": "",
                    "search": "Buscar:",
                    "zeroRecords": "Sin coincidencias",
                    "paginate": {
                      "first": "Primero",
                      "last": "Último",
                      "next": "Siguiente",
                      "previous": "Anterior"
                    },
                    "aria": {
                      "sortAscending": ": activate to sort column ascending",
                      "sortDescending": ": activate to sort column descending"
                    }
                  },
                  initComplete: function initComplete() {
                    this.api().columns().every(function () {
                      var column = this;
                      var select = $('<select><option value=""></option></select>').appendTo($(column.header())).on('change', function () {
                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                        column.search(val ? '^' + val + '$' : '', true, false).draw();
                      });
                      column.data().unique().sort().each(function (d, j) {
                        select.append('<option value="' + d + '">' + d + '</option>');
                      });
                    });
                  },
                  columnDefs: [{
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                    targets: 0
                  }, {
                    target: 9,
                    visible: false,
                    searchable: false
                  }],
                  order: [[2, 'asc']]
                }); // Eliminamos los header de busqueda de las 2 primeras columnas

                $("#colaboradoresTable thead tr th:eq(0)").find("select").remove();
                $("#colaboradoresTable thead tr th:eq(1)").find("select").remove();
                $("#colaboradoresTable thead tr th:eq(2)").find("select").remove();
                $("table").find("tr").find("td:first").attr("nowrap", "nowrap");
                $("table").find("tr").each(function () {
                  $(this).find("td").each(function () {
                    if ($(this).text() == "No aprobado") $(this).parent().attr("style", "color:red !important");
                  });
                }); // Evento para mostrar/editar domicilios

                $("#" + self.get('datatableName')).on("click", ".domicilios", function () {
                  controller.currentDomicilio = $(this);
                  controller.usuario_k = $(this).data("usuario_k");
                  self.send("showAddress", $(this), controller);
                });
                $("#" + self.get('datatableName')).on("click", ".validar", function () {
                  controller.currentDomicilio = $(this);
                  controller.usuario_k = $(this).data("usuario_k");
                  controller.periodo_k = $(this).data("periodo_k");
                  $("#confirm_candidato_modal").modal("show"); //self.send("showAddress", $(this), controller);
                });
                $("#" + self.get('datatableName')).on("click", ".rechazar", function () {
                  controller.currentDomicilio = $(this);
                  controller.usuario_k = $(this).data("usuario_k");
                  controller.periodo_k = $(this).data("periodo_k");
                  $("#deny_candidato_modal").modal("show");
                }); // Asignación de equipo

                $("#" + self.get('datatableName')).on("click", ".asignaciondeequipo", function () {
                  self._controller.set('updateStore', self.store.createRecord('nom-035-stps/acciones-internas/evidencia', {}));

                  self._controller.set('createDeleteCommand', 'create');

                  self._controller.set('isVisibleCreateUpdateForm', true);

                  self._controller.set('isCommandUpdate', false);

                  self._controller.set('lbl_form', 'Asignación de equipo');

                  self._controller.set('lbl_campo', 'Apoyo');

                  self._controller.set('nombre_usuario', $(this).data("nombre"));

                  self._controller.set('evidencia_usuario_k', $(this).data("usuariok"));

                  self._controller.set('tipo_evidencia', 'equipo');

                  $('#button-save-changes').attr("disabled", "disabled");
                  $("#documentoNombre").val('');
                  $("#documentoRuta").val('');
                  $('#filesattached').html('');
                  $("#apoyo_nombre").val('');
                  $("#apoyo_descripcion").val('');
                  $("#createUpdateFormApoyo").modal({});
                }); // Evidencia medica

                $("#" + self.get('datatableName')).on("click", ".evidenciamedica", function () {
                  self._controller.set('updateStore', self.store.createRecord('nom-035-stps/acciones-internas/evidencia', {}));

                  self._controller.set('createDeleteCommand', 'create');

                  self._controller.set('isVisibleCreateUpdateForm', true);

                  self._controller.set('isCommandUpdate', false);

                  self._controller.set('lbl_form', 'Evidencia medica');

                  self._controller.set('lbl_campo', 'Evidencia medica');

                  self._controller.set('nombre_usuario', $(this).data("nombre"));

                  self._controller.set('evidencia_usuario_k', $(this).data("usuariok"));

                  self._controller.set('tipo_evidencia', 'medica');

                  $('#button-save-changes').attr("disabled", "disabled");
                  $("#documentoNombre").val('');
                  $("#documentoRuta").val('');
                  $('#filesattached').html('');
                  $("#apoyo_nombre").val('');
                  $("#apoyo_descripcion").val('');
                  $("#createUpdateFormApoyo").modal("show");
                });
                self.tabColaboradores.on('click', 'tbody td.dt-control', function (e) {
                  var tr = e.target.closest('tr');
                  var row = self.tabColaboradores.row(tr);

                  if (row.child.isShown()) {
                    // This row is already open - close it
                    row.child.hide();
                  } else {
                    // Open this row
                    var datos = row.data(),
                        usuario_k = datos[9],
                        tab_apoyo = self.detalle_apoyos(self, usuario_k, 'EQUIPO'),
                        html = $("<form><div class='form-row'><div class='col-2'><select class='form-control form-control-sm sltipoapoyo' id='cbtipoapoyo_" + usuario_k + "' data-usuario_k='" + usuario_k + "'><option value='EQUIPO' selected>Asignación de equipo</option><option value='MEDICA'>Evidencia medica</option></select></div></div></form>"),
                        wrapp = $("<div class='wrapp_apoyo_" + usuario_k + "'></div>").append(tab_apoyo);
                    row.child([html, wrapp]).show();
                  }
                });
              }, 2000);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }

      $("#" + self.get('datatableName')).on("click", ".sltipoapoyo", function () {
        var usuario_k = $(this).data('usuario_k'),
            opcion = $(this).val(),
            tab_apoyo = self.detalle_apoyos(self, usuario_k, opcion);
        $(".wrapp_apoyo_" + usuario_k).html("").append(tab_apoyo);
      });
      $("#" + self.get('datatableName')).on("click", ".btn_borrar_apoyo", function () {
        self.controller.set('ap_apoyo_k', $(this).data('apoyo_k'));
        self.controller.set('ap_usuario_k', $(this).data('usuario_k'));
        self.controller.set('ap_tipo_evidencia', $(this).data('tipo_evidencia'));
        $("#confirm_apoyo_modal").modal("show");
      });
      /* Listado de años */

      self.obtiene_anios(controller);
      $("#recibos_modal").on("hidden.bs.modal", function () {
        self.controller.currentDomicilio.click();
      });
      $('#nav-tab button').on('click', function (event) {
        event.preventDefault();

        if ($(this).attr('id') == 'nav-historial-tab') {
          var id_colaborador_direccion_k = $("#recibos_modal").data("id_colaborador_direccion_k"),
              tipo = $("#recibos_modal").data("tipo");

          if (self.tabla_historial != null) {
            self.tabla_historial.destroy();
          }

          self.carga_historial(tipo, id_colaborador_direccion_k, controller);
        }

        $(this).tab('show');
      });
    },
    obtiene_anios: function obtiene_anios(controller) {
      var fecha_actual = new Date(),
          anio_actual = fecha_actual.getFullYear(),
          anio_anterior = anio_actual - 1,
          anios = [];
      anios.push(anio_actual);
      anios.push(anio_anterior);
      controller.set("anios", anios);
      controller.set("anio_actual", anio_actual);
      controller.set("mes_actual", fecha_actual.getMonth() + 1);
    },
    carga_historial: function carga_historial() {
      var tipo = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var id_colaborador_direccion_k = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var controller = arguments.length > 2 ? arguments[2] : undefined;
      var self = this;
      $.ajax({
        url: constant.APIURL + "nom037/administrador/comprobantesHistorial",
        type: "post",
        data: {
          usuario_k: window.datosPersonales.usuario_k,
          tipo: tipo,
          id_colaborador_direccion_k: id_colaborador_direccion_k
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        async: false,
        success: function success(response) {
          if (response.success == true) {
            controller.set("historial", response.data);
          }
        }
      });
    },
    getPeriodo: function getPeriodo() {
      var fecha = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var datos_fecha = {
        anio: null,
        mes: null
      };

      if (fecha != '') {
        var datos = fecha.split("-");
        datos_fecha.anio = datos[0];
        datos_fecha.mes = datos[1];
      }

      return datos_fecha;
    },
    actions: {
      denyCandidate: function denyCandidate() {
        var self = this;
        $.ajax({
          url: constant.APIURL + "nom037/administrador/denegarCandidato",
          type: "post",
          data: {
            usuario_k: self.controller.get("usuario_k"),
            periodo_k: self.controller.get("periodo_k"),
            motivo_rechazo: $("#motivo_rechazo").val()
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              window.location.reload(); //controller.set("direcciones", response.data);
              //controller.set("usuario_k", e.data("usuariok"));
            }
          }
        });
      },
      confirmCandidate: function confirmCandidate() {
        var self = this;
        $.ajax({
          url: constant.APIURL + "nom037/administrador/confirmarCandidato",
          type: "post",
          data: {
            usuario_k: self.controller.get("usuario_k"),
            periodo_k: self.controller.get("periodo_k")
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              window.location.reload(); //controller.set("direcciones", response.data);
              //controller.set("usuario_k", e.data("usuariok"));
            }
          }
        });
      },
      confirmBorrarApoyo: function confirmBorrarApoyo() {
        var self = this,
            apoyo_k = self.controller.get('ap_apoyo_k'),
            usuario_k = self.controller.get('ap_usuario_k'),
            tipo_evidencia = self.controller.get('ap_tipo_evidencia');
        $.ajax({
          url: constant.APIURL + String(self.get("clase")) + '/borrar_apoyo',
          type: "post",
          data: {
            apoyo_k: apoyo_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          success: function success(html) {
            $('#modal-avisos .modal-header').text('Registro');
            $('#modal-avisos .modal-body').text('Registro eliminado correctamente.');
            $('#modal-avisos').modal('show');
            $(".wrapp_apoyo_" + usuario_k).html("").append(self.detalle_apoyos(self, usuario_k, tipo_evidencia));
          }
        });
      },
      filterAndReload: function filterAndReload() {
        var self = this;
        var params = {
          estatus: $('#inputGroupSelectEstatus').val(),
          centro_trabajo_k: $('#inputGroupSelectCentroTrabajoK').val()
        };
        $("#" + this.get("datatableName")).DataTable().destroy();
        setTimeout(function () {
          self._controller.set('dataTable', []);

          Ember.set();
        }, 2000); //this.set("params",JSON.stringify(params));

        this.set("params", params); //this.setParamsCentroTrabajoK( $('#inputGroupSelectCentroTrabajoK').val() );
        //this.setParamsEstatus( $('#inputGroupSelectEstatus').val() );
        //this.setParamsUsuario( $('#inputGroupTextUsuario').val() );
        //this.loadData(this._controller);
      },
      showAddress: function showAddress(e, controller) {
        $("#direcciones").removeClass("d-none");
        $(".colaboradores").addClass("d-none");
        var target_offset = $("#direcciones").parent().parent().offset();
        var target_top = target_offset.top;
        $('html, body').animate({
          scrollTop: target_top
        }, 500, 'easeInSine');
        controller.set("colaborador_header", e.data("nombre"));
        $.ajax({
          url: constant.APIURL + "nom037/administrador/direccionesUsuario",
          type: "post",
          data: {
            usuario_k: e.data("usuariok")
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              controller.set("direcciones", response.data);
              controller.set("usuario_k", e.data("usuariok"));
            }
          }
        });
      },
      loadMap: function loadMap() {
        $("#table-direcciones").addClass("d-none");
        $("#mapa").removeClass("d-none");
        var mapOptions,
            map,
            marker,
            searchBox,
            city,
            infoWindow = '',
            addressEl = document.querySelector('#map-search'),
            latEl = document.querySelector('.latitude'),
            longEl = document.querySelector('.longitude'),
            element = document.getElementById('map-canvas');
        city = document.querySelector('.reg-input-city');
        mapOptions = {
          // How far the maps zooms in.
          zoom: 9,
          // Current Lat and Long position of the pin/
          center: new google.maps.LatLng(19.4136491, -99.1033026),
          // center : {
          // 	lat: -34.397,
          // 	lng: 150.644
          // },
          disableDefaultUI: false,
          // Disables the controls like zoom control on the map if set to true
          scrollWheel: true,
          // If set to false disables the scrolling on the map.
          draggable: true // If set to false , you cannot move the map around.
          // mapTypeId: google.maps.MapTypeId.HYBRID, // If set to HYBRID its between sat and ROADMAP, Can be set to SATELLITE as well.
          // maxZoom: 11, // Wont allow you to zoom more than this
          // minZoom: 9  // Wont allow you to go more up.

        };
        /**
         * Creates the map using google function google.maps.Map() by passing the id of canvas and
         * mapOptions object that we just created above as its parameters.
         *
         */
        // Create an object map with the constructor function Map()

        map = new google.maps.Map(element, mapOptions); // Till this like of code it loads up the map.

        /**
         * Creates the marker on the map
         *
         */

        marker = new google.maps.Marker({
          position: mapOptions.center,
          map: map,
          // icon: 'http://pngimages.net/sites/default/files/google-maps-png-image-70164.png',
          draggable: true
        });
        /**
         * Creates a search box
         */

        searchBox = new google.maps.places.SearchBox(addressEl);
        /**
         * When the place is changed on search box, it takes the marker to the searched location.
         */

        google.maps.event.addListener(searchBox, 'places_changed', function () {
          var places = searchBox.getPlaces(),
              bounds = new google.maps.LatLngBounds(),
              i,
              place,
              lat,
              long,
              resultArray,
              addresss = places[0].formatted_address;

          for (i = 0; place = places[i]; i++) {
            bounds.extend(place.geometry.location);
            marker.setPosition(place.geometry.location); // Set marker position new.
          }

          map.fitBounds(bounds); // Fit to the bound

          map.setZoom(15); // This function sets the zoom to 15, meaning zooms to level 15.
          // console.log( map.getZoom() );

          lat = marker.getPosition().lat();
          long = marker.getPosition().lng();
          latEl.value = lat;
          longEl.value = long;
          resultArray = places[0].address_components; // Get the city and set the city input value to the one selected

          for (var i = 0; i < resultArray.length; i++) {
            if (resultArray[i].types[0] && 'administrative_area_level_2' === resultArray[i].types[0]) {
              citi = resultArray[i].long_name;
              city.value = citi;
            }
          } // Closes the previous info window if it already exists


          if (infoWindow) {
            infoWindow.close();
          }
          /**
           * Creates the info Window at the top of the marker
           */


          infoWindow = new google.maps.InfoWindow({
            content: addresss
          });
          infoWindow.open(map, marker);
        });
        /**
         * Finds the new position of the marker when the marker is dragged.
         */

        google.maps.event.addListener(marker, "dragend", function (event) {
          var lat, long, address, resultArray, citi;
          console.log('i am dragged');
          lat = marker.getPosition().lat();
          long = marker.getPosition().lng();
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({
            latLng: marker.getPosition()
          }, function (result, status) {
            if ('OK' === status) {
              // This line can also be written like if ( status == google.maps.GeocoderStatus.OK ) {
              address = result[0].formatted_address;
              resultArray = result[0].address_components; // Get the city and set the city input value to the one selected

              for (var i = 0; i < resultArray.length; i++) {
                if (resultArray[i].types[0] && 'administrative_area_level_2' === resultArray[i].types[0]) {
                  citi = resultArray[i].long_name;
                  console.log(citi);
                  city.value = citi;
                }
              }

              addressEl.value = address;
              latEl.value = lat;
              longEl.value = long;
            } else {
              console.log('Geocode was not successful for the following reason: ' + status);
            } // Closes the previous info window if it already exists


            if (infoWindow) {
              infoWindow.close();
            }
            /**
             * Creates the info Window at the top of the marker
             */


            infoWindow = new google.maps.InfoWindow({
              content: address
            });
            infoWindow.open(map, marker);
          });
        });
      },
      cancelNewAddress: function cancelNewAddress() {
        $("#table-direcciones").removeClass("d-none");
        $("#mapa").addClass("d-none");
        $("#map-search").val('');
        $("#mapa #longitude").val('');
        $("#mapa #latitude").val('');
      },
      saveAddress: function saveAddress() {
        var self = this;
        var data = {
          "usuario_k": $("#mapa #usuario_k").val(),
          "domicilio": $("#map-search").val(),
          "longitud": $("#mapa #longitude").val(),
          "latitud": $("#mapa #latitude").val(),
          "url_google_maps": "https://www.google.com.mx/maps/place/" + $("#map-search").val() + "/@" + $("#mapa #longitude").val() + "," + $("#mapa #latitude").val()
        };
        $.ajax({
          url: constant.APIURL + "nom037/administrador/guardarDireccion",
          type: "post",
          data: data,
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              $("#map-search").val('');
              $("#mapa #longitude").val('');
              $("#mapa #latitude").val('');

              self._controller.set("direcciones", response.data);

              self.send("cancelNewAddress");
            }
          }
        });
      },
      showList: function showList() {
        var self = this;
        $("#direcciones").addClass("d-none");
        $(".colaboradores").removeClass("d-none");
        $("#calendar-container").html("").html("<div id='calendar'></div>");

        self._controller.send("fullcalendar");

        $("#map-search").val('');
        $("#mapa #longitude").val('');
        $("#mapa #latitude").val('');
      },
      agendar: function agendar(d) {
        var self = this;
        console.log(d);

        self._controller.set("domicilio_visita", d.domicilio);

        self._controller.set("id_colaborador_direccion_k", d.id_colaborador_direccion_k);

        self._controller.set("url_google_maps", d.url_google_maps);

        self._controller.set("domicilio", d.domicilio);

        self._controller.set("email_usuario", d.email_usuario);

        $("#visita_modal").modal("show");
        $('#dtpicker').datetimepicker({
          locale: 'es',
          format: 'YYYY-MM-DD HH:mm',
          ignoreReadonly: true,
          // inline: true,
          sideBySide: true
        });
      },
      save: function save() {
        var self = this;
        var regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        var error = false; // Valid name

        if ($("#nombre_visitante").val() == "" || $("#nombre_visitante").val().length <= 3) {
          $("#nombre_visitante").focus();
          error = true;
          return false;
        } // Valid email


        if (!regex.test($("#email_visitante").val()) || $("#email_visitante").val() == "") {
          $("#email_visitante").focus();
          error = true;
          return false;
        } // Valid date


        if ($("#fecha_hora_visita").val() == "") {
          $("#opendtpicker").click();
          error = true;
          return false;
        }

        if (!error) {
          var data = {
            "direccion_visita_k": 0,
            "id_colaborador_direccion_k": $("#id_colaborador_direccion_k").val(),
            "nombre_visitante": $("#nombre_visitante").val(),
            "nombre_colaborador": $("#nombre_colaborador").val(),
            "email_visitante": $("#email_visitante").val(),
            "telefono_visitante": $("#telefono_visitante").val(),
            "fecha_hora_visita": $("#fecha_hora_visita").val(),
            "domicilio": $("#domicilio").val(),
            "url_google_maps": $("#url_google_maps").val(),
            "email_usuario": self._controller.email_usuario,
            "usuario_k": self._controller.usuario_k
          };
          var sfulldate = $("#fecha_hora_visita").val().split(" ");
          var sdate = sfulldate[0].split("-");
          var shour = sfulldate[1].split(":"); //start: new Date(y, m, d, 10, 30),

          var start = new Date(sdate[0], sdate[1] - 1, sdate[2], shour[0], shour[1]);
          var obj = {
            title: "Visita a " + $("#nombre_colaborador").val(),
            start: start,
            allDay: false,
            className: 'important',
            data: data
          };
          console.log("=====");
          $('#calendar').fullCalendar('renderEvent', obj, true);
          console.log("=====");
          $("#visita_modal").modal("hide");
          $("#loading").fadeIn(1500);
          $.ajax({
            url: constant.APIURL + "nom037/administrador/agendarVisita",
            type: "post",
            data: data,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            xhrFields: {
              withCredentials: true
            },
            dataType: "json",
            success: function success(response) {
              $("#loading").fadeOut(1500);

              if (response.success == true) {
                self._controller.set("direcciones", response.data);
              }
            },
            error: function error(e) {
              $("#loading").fadeOut(1500);
              console.log(e);
            }
          });
        }
      },
      isEmail: function isEmail(email) {
        return;
      },
      fullcalendar: function fullcalendar() {
        var self = this;
        $.ajax({
          url: constant.APIURL + "nom037/administrador/visitas",
          type: "get",
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          async: false,
          success: function success(response) {
            var events = [];

            if (response.success == true) {
              $.each(response.data, function (i, v) {
                var sfulldate = v.fecha_hora_visita.split(" ");
                var sdate = sfulldate[0].split("-");
                var shour = sfulldate[1].split(":"); //start: new Date(y, m, d, 10, 30),

                var start = new Date(sdate[0], sdate[1] - 1, sdate[2], shour[0], shour[1]);
                var obj = {
                  title: v.colaborador,
                  start: start,
                  allDay: false,
                  className: v.estatus == "Validado" ? 'bg-success bgcalendar pointer' : 'bg-info bgcalendar',
                  data: v
                };
                events.push(obj);
              });
              console.log(events);
              /* initialize the calendar
              -----------------------------------------------------------------*/

              var date = new Date();
              var d = date.getDate();
              var m = date.getMonth();
              var y = date.getFullYear();
              /*  className colors
              
              className: default(transparent), important(red), chill(pink), success(green), info(blue)
              
              */

              /* initialize the external events
              -----------------------------------------------------------------*/

              $('#external-events div.external-event').each(function () {
                // create an Event Object (http://arshaw.com/fullcalendar/docs/event_data/Event_Object/)
                // it doesn't need to have a start or end
                var eventObject = {
                  title: $.trim($(this).text()) // use the element's text as the event title

                }; // store the Event Object in the DOM element so we can get to it later

                $(this).data('eventObject', eventObject); // make the event draggable using jQuery UI

                $(this).draggable({
                  zIndex: 999,
                  revert: true,
                  // will cause the event to go back to its
                  revertDuration: 0 //  original position after the drag

                });
              });
              /* initialize the calendar
              -----------------------------------------------------------------*/

              var calendar = $('#calendar').fullCalendar({
                header: {
                  left: 'title',
                  center: 'prev,next',
                  //right: 'prev,next today'
                  right: 'month,agendaWeek'
                },
                aspectRatio: 1,
                height: 300,
                editable: false,
                firstDay: 1,
                //  1(Monday) this can be changed to 0(Sunday) for the USA system
                selectable: true,
                defaultView: 'agendaWeek',
                monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
                dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
                buttonText: {
                  prev: "<span class='fc-text-arrow'>‹</span>",
                  next: "<span class='fc-text-arrow'>›</span>",
                  prevYear: "<span class='fc-text-arrow'>«</span>",
                  nextYear: "<span class='fc-text-arrow'>»</span>",
                  today: 'Hoy',
                  month: 'Mes',
                  week: 'Semana',
                  day: 'Dia'
                },
                axisFormat: 'h:mm',
                columnFormat: {
                  month: 'ddd',
                  // Mon
                  week: 'ddd d',
                  // Mon 7
                  day: 'dddd M/d',
                  // Monday 9/7
                  agendaDay: 'dddd d'
                },
                titleFormat: {
                  month: 'MMMM yyyy',
                  // September 2009
                  week: "MMMM yyyy",
                  // September 2009
                  day: 'MMMM yyyy' // Tuesday, Sep 8, 2009

                },
                allDaySlot: false,
                selectHelper: false,
                eventClick: function eventClick(info) {
                  self.send("visitDetails", info.data);
                },
                viewRender: function viewRender(view, element) {
                  setTimeout(function () {
                    $(".bgcalendar").removeClass("fc-event").removeClass("fc-event-hori").removeClass("fc-event-start").removeClass("fc-event-end");
                    $(".grafica-colaboradores").css({
                      'height': $("#calendar-container").height() + 'px'
                    });
                  }, 100);
                },
                _select: function _select(start, end, allDay) {
                  var title = prompt('Event Title:');

                  if (title) {
                    calendar.fullCalendar('renderEvent', {
                      title: title,
                      start: start,
                      end: end,
                      allDay: allDay
                    }, true // make the event "stick"
                    );
                  }

                  calendar.fullCalendar('unselect');
                },
                droppable: false,
                // this allows things to be dropped onto the calendar !!!
                drop: function drop(date, allDay) {
                  // this function is called when something is dropped
                  // retrieve the dropped element's stored Event Object
                  var originalEventObject = $(this).data('eventObject'); // we need to copy it, so that multiple events don't have a reference to the same object

                  var copiedEventObject = $.extend({}, originalEventObject); // assign it the date that was reported

                  copiedEventObject.start = date;
                  copiedEventObject.allDay = allDay; // render the event on the calendar
                  // the last `true` argument determines if the event "sticks" (http://arshaw.com/fullcalendar/docs/event_rendering/renderEvent/)

                  $('#calendar').fullCalendar('renderEvent', copiedEventObject, true); // is the "remove after drop" checkbox checked?

                  if ($('#drop-remove').is(':checked')) {
                    // if so, remove the element from the "Draggable Events" list
                    $(this).remove();
                  }
                },
                events: events
              });
            }

            $(".bgcalendar").removeClass("fc-event").removeClass("fc-event-hori").removeClass("fc-event-start").removeClass("fc-event-end");
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      visitDetails: function visitDetails(info) {
        var self = this;
        $("#visitValid").show();
        if (!info.direccion_visita_k) $("#visitValid").hide();
        $("#visitCancel").removeClass("d-none");
        $("#visitValid").removeClass("d-none");

        if (info.estatus == "Validado") {
          $("#visitValid").addClass("d-none");
          $("#visitCancel").addClass("d-none");
        }

        self._controller.direccion_visita_k = info.direccion_visita_k;
        self._controller.id_colaborador_direccion_k = info.id_colaborador_direccion_k;
        $(".nombre_colaborador").text(info.colaborador);
        $(".domicilio_visita").text(info.domicilio);
        $(".url_google_maps").attr("href", info.url_google_maps).text(info.url_google_maps);
        $(".nombre_visitante").text(info.nombre_visitante);
        $(".email_visitante").text(info.email_visitante);
        $(".telefono_visitante").text(info.telefono_visitante);
        $(".fecha_hora_visita").text(info.fecha_hora_visita);
        $("#detalles_modal").modal("show");
      },
      visitCancel: function visitCancel() {
        var self = this;
        $.ajax({
          url: constant.APIURL + 'nom037/administrador/cancelarVisita',
          type: "post",
          data: {
            direccion_visita_k: self._controller.direccion_visita_k,
            id_colaborador_direccion_k: self._controller.id_colaborador_direccion_k,
            usuario_k: self._controller.usuario_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              $("#calendar-container").html("").html("<div id='calendar'></div>");

              self._controller.send("fullcalendar");

              self._controller.set("direcciones", response.data);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      valid: function valid() {
        var self = this;
        $.ajax({
          url: constant.APIURL + 'nom037/administrador/validarDireccion',
          type: "post",
          data: {
            direccion_visita_k: self._controller.direccion_visita_k,
            id_colaborador_direccion_k: self._controller.id_colaborador_direccion_k,
            usuario_k: self._controller.usuario_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              $("#calendar-container").html("").html("<div id='calendar'></div>");

              self._controller.send("fullcalendar");

              self._controller.set("direcciones", response.data);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      uploadEvidences: function uploadEvidences(d) {
        var self = this;
        $("#loading").fadeIn(500);
        $("#evidencias_modal").data("id_colaborador_direccion_k", d.id_colaborador_direccion_k);
        $.ajax({
          url: constant.APIURL + "nom037/administrador/listarEvidencias",
          type: "post",
          data: {
            id_colaborador_direccion_k: d.id_colaborador_direccion_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              localStorage.setItem("prevEvidences", JSON.stringify(response.data));
              $("#evidencias_modal").modal("show");
              $("#evidencias_modal").on("hidden.bs.modal", function () {
                self.controller.currentDomicilio.click();
              });
            }
          }
        });
        return false;
      },
      deleteAddress: function deleteAddress(d) {
        var self = this;
        self._controller.deleteAddress = d;
        $("#confirm_delete_modal").modal("show");
      },
      confirmDeleteAddress: function confirmDeleteAddress() {
        var self = this;
        var evidencia_k = $(this).data("evidencia_k");
        $.ajax({
          url: constant.APIURL + "nom037/administrador/eliminarDireccion",
          type: "post",
          data: {
            id_colaborador_direccion_k: self._controller.deleteAddress.id_colaborador_direccion_k,
            direccion_visita_k: self._controller.deleteAddress.direccion_visita_k,
            usuario_k: self._controller.usuario_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              self._controller.set("direcciones", response.data);
            }
          }
        });
      },
      uploadEvidencesInternet: function uploadEvidencesInternet(d, tipo) {
        var self = this;
        $("#recibos_modal").modal("show");
        $("#lbl_evidencia").html("");
        /*
        $("#recibos_modal").on("hidden.bs.modal", function () {
            self.controller.currentDomicilio.click();
        });
        */

        $("#recibos_modal").data("id_colaborador_direccion_k", d.id_colaborador_direccion_k);
        $("#recibos_modal").data("tipo", tipo); // En caso de que se tenga el periodo, se coloca en los select

        var datos_fecha_luz = self.getPeriodo(d.periodo_luz),
            datos_fecha_internet = self.getPeriodo(d.periodo_internet);
        $("#recibo_periodo_mes").val("");
        $("#recibo_periodo_anio").val(self._controller.get("anio_actual"));
        var urlRecibo = false;

        if (tipo == "luz") {
          urlRecibo = d.documento_luz != null ? d.documento_luz : false;

          if (d.periodo_luz != '') {
            $("#recibo_periodo_mes").val(datos_fecha_luz.mes);
            $("#recibo_periodo_anio").val(datos_fecha_luz.anio);
          }
        } else {
          urlRecibo = d.documento_internet != null ? d.documento_internet : false;

          if (d.periodo_internet != '') {
            $("#recibo_periodo_mes").val(datos_fecha_internet.mes);
            $("#recibo_periodo_anio").val(datos_fecha_internet.anio);
          }
        }

        if (urlRecibo) {
          $("#ifr-recibo").attr("src", constant.BASEURL + urlRecibo); // En caso de que se tenga un documento cargado, se muestra

          $("#wrap_evidencias").removeClass("d-none");
          $("#carga_evidencia").addClass("d-none");
        } else {
          $("#ifr-recibo").attr("src", "");
          $("#wrap_evidencias").addClass("d-none");
          $("#carga_evidencia").removeClass("d-none");
        }
        /*$.ajax({
            url: constant.APIURL + "nom037/administrador/listarEvidencias",
            type: "post",
            data: {
                id_colaborador_direccion_k: d.id_colaborador_direccion_k
            },
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            xhrFields: { withCredentials: true },
            dataType: "json",
            success: function (response) {
                if (response.success == true) {
                     localStorage.setItem("prevEvidences", JSON.stringify(response.data));
                    $("#evidencias_modal").modal("show");
                }
            }
        });*/


        $("#recibo_periodo_mes").on('change', function () {
          var recibo_periodo_mes = $(this).val(),
              recibo_periodo_anio = $("#recibo_periodo_anio").val();
          $("#wrap_evidencias").addClass("d-none");
          $("#carga_evidencia").removeClass("d-none");

          if (recibo_periodo_mes != '' && recibo_periodo_anio != '') {
            $("#wrap_evidencias").removeClass("d-none");
            $("#carga_evidencia").addClass("d-none");
          }
        });
        $("#recibo_periodo_anio").on('change', function () {
          var recibo_periodo_anio = $(this).val(),
              recibo_periodo_mes = $("#recibo_periodo_mes").val();
          $("#wrap_evidencias").addClass("d-none");
          $("#carga_evidencia").removeClass("d-none");

          if (recibo_periodo_mes != '' && recibo_periodo_anio != '') {
            $("#wrap_evidencias").removeClass("d-none");
            $("#carga_evidencia").addClass("d-none");
          }
        });
        $("#lbl_evidencia").html(" de " + tipo.toUpperCase());
        /* $('#nav-tab .nav-link:first-child').tab('show'); */

        $('#nav-tab .js-tabs-button:first-child').tab('show');
        return false;
      },
      afterCreate: function afterCreate(dataObject) {},
      afterUpdate: function afterUpdate(dataObject) {}
    }
  });

  _exports.default = _default;
});