define("comunidades-nafin/routes/nom-035-stps/acontecimientos-traumaticos-severos/administrador/acciones-internas/evidencias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    clase: 'nom035stps/accionesinternas',
    metodo: 'listarevidencias',
    isStriped: true,
    searchValue: '',
    isEditing: false,
    isSearching: false,
    isLoading: false,
    deleteStore: null,
    isVisibleDeleteForm: false,
    createDeleteCommand: 'create',
    isVisibleCreateUpdateForm: false,
    beforeModel: function beforeModel() {},
    model: function model(controller) {
      this.set('start', 0);
      var data_array = [];
      var start = this.get('start');
      var self = this;
      Ember.$('#btn-load-more-elements').attr('disabled', 'disabled');
      Ember.$.ajax({
        url: constant.APIURL + String(this.clase) + '/' + String(this.metodo),
        type: 'POST',
        sync: true,
        xhrFields: {
          withCredentials: true
        },
        data: 'start=' + start,
        contenType: 'application/x-www-form-urlencoded',
        success: function success(response_string) {
          var responseObject = JSON.parse(response_string);
          responseObject.data.forEach(function (data) {
            data.evidencia_ruta_documento = constant.BASEURL + data.evidencia_ruta_documento;
            data_array.pushObject(self.store.createRecord('nom-035-stps/acciones-internas/evidencia', data));
          });

          if (responseObject.data.length > 0) {
            self.set('start', start + constant.PAGESIZE);
          }

          Ember.$('#btn-load-more-elements').removeAttr('disabled');
        },
        error: function error(_error) {
          Ember.$('#btn-load-more-elements').removeAttr('disabled');
        }
      });
      return data_array;
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('app', this.app);
      controller.set('searchValue', this.searchValue);
      controller.set('isEditing', this.isEditing);
      controller.set('isSearching', this.isSearching);
      controller.set('isLoading', this.isLoading);
      controller.set('createDeleteCommand', this.createDeleteCommand);
      controller.set('isVisibleCreateUpdateForm', this.isVisibleCreateUpdateForm);
      controller.set('deleteStore', this.deleteStore);
      controller.set('isVisibleDeleteForm', this.isVisibleDeleteForm);
      setTimeout(function () {
        Ember.$('#tab_acciones').DataTable({
          retrieve: true,
          language: {
            "processing": "Procesando...",
            "lengthMenu": "Mostrar _MENU_ registros",
            "zeroRecords": "No se encontraron resultados",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "search": "Buscar:",
            "infoThousands": ",",
            "loadingRecords": "Cargando...",
            "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
          }
        });
      }, 2000);
    },
    onLoadMore: function onLoadMore() {
      var self = this;
      var start = this.get('start');
      Ember.$('#btn-load-more-elements').attr('disabled', 'disabled');
      this.controller.set('isLoading', true);
      Ember.$.ajax({
        url: constant.APIURL + String(this.clase) + '/' + String(this.metodo),
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: 'start=' + start,
        contenType: 'application/x-www-form-urlencoded',
        success: function success(response_text) {
          var response = Ember.$.parseJSON(response_text);

          if (response.success) {
            response.data.forEach(function (recordObject) {
              var is_record_in_store = false;
              self.controller.model.toArray().forEach(function (item) {
                if (item.get('evidencia_k') == recordObject.evidencia_k) {
                  is_record_in_store = true;
                }
              });

              if (!is_record_in_store) {
                var element = self.store.createRecord('nom-035-stps/acciones-internas/evidencia', recordObject);
                self.controller.model.pushObject(element);
              }
            });

            if (response.data.length > 0) {
              self.set('start', start + constant.PAGESIZE);
              Ember.$('#btn-load-more-elements').removeAttr('disabled');
            } else {
              Ember.$('#btn-load-more-elements').text('Ups! No hay mas elementos');
            }
          } else {
            Ember.$('#btn-load-more-elements').removeAttr('disabled');
          }

          self.controller.set('isLoading', false);
        },
        error: function error(_error2) {
          Ember.$('#btn-load-more-elements').removeAttr('disabled');
          self.controller.set('isLoading', false);
        }
      });
    },
    recordIndexOf: function recordIndexOf(emberArray, emberObject) {
      var indexOf = -1;
      emberArray.forEach(function (_record, _index, _array) {
        if (indexOf == -1) {
          if (_record.evidencia_k == emberObject.evidencia_k) {
            indexOf = _index;
          }
        }
      });
      return indexOf;
    },
    actions: {
      go: function go() {
        return false;
      },
      loadMore: function loadMore() {
        this.onLoadMore();
      },
      filterFromOmniBar: function filterFromOmniBar(search_value) {
        var self = this;
        this.controller.model.forEach(function (itemObject) {
          itemObject.set('filtro', search_value);
        });

        if (typeof search_value == 'undefined') {
          return;
        }

        if (search_value.length == 0) {
          return;
        }

        this.controller.set('isLoading', true);
        Ember.$.ajax({
          url: constant.APIURL + String(this.clase) + '/' + String(this.metodo),
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          data: {
            filter: search_value,
            start: 0,
            limit: 100
          },
          contenType: 'application/x-www-form-urlencoded',
          success: function success(response_text) {
            var response = Ember.$.parseJSON(response_text);

            if (response.success) {
              response.data.forEach(function (recordObject) {
                var is_record_in_store = false;
                self.controller.model.toArray().forEach(function (item) {
                  if (item.get('evidencia_k') == recordObject.evidencia_k) {
                    is_record_in_store = true;
                  }
                });

                if (!is_record_in_store) {
                  var element = self.store.createRecord('nom-035-stps/acciones-internas/evidencia', recordObject);
                  self.controller.model.pushObject(element);
                }
              });
              self.controller.model.forEach(function (itemObject) {
                itemObject.set('filtro', search_value);
              });
            }

            self.controller.set('isLoading', false);
          },
          error: function error(_error3) {
            self.controller.set('isLoading', false);
          }
        });
      },
      updateFilterFromOmniBar: function updateFilterFromOmniBar(search_value) {
        this.controller.model.forEach(function (itemObject) {
          itemObject.set('filtro', search_value);
        });
      },
      toggleDetail: function toggleDetail(record) {
        var _this = this;

        var evidencia_k = Ember.get(record, 'evidencia_k');
        var detalle_visible = Ember.get(record, 'detalle_visible');
        var isDetalleVisible = Ember.get(record, 'isDetalleVisible');
        Ember.set(record, 'detalle_visible', isDetalleVisible ? 0 : 1);
      },

      /*
       *
       *
       */
      goToURL: function goToURL(record) {
        var evidencia_k = record.get ? record.get('evidencia_k') : record.evidencia_k;
        var evidencia_ruta_documento = record.get ? record.get('evidencia_ruta_documento') : record.evidencia_ruta_documento;
        var url = constant.BASEURL + evidencia_ruta_documento;
        var myWindow = window.open(url, "_blank");
      },
      showDeleteModal: function showDeleteModal(recordToDelete) {
        this.controller.set('deleteStore', recordToDelete);
        this.controller.set('isVisibleDeleteForm', true);
        Ember.$('#deleteFormEvidencia').modal({});
      },
      showCreateModal: function showCreateModal() {
        this.controller.set('updateStore', this.store.createRecord('nom-035-stps/acciones-internas/evidencia', {}));
        this.controller.set('createDeleteCommand', 'create');
        this.controller.set('isVisibleCreateUpdateForm', true);
        this.controller.set('isCommandUpdate', false);
        /**
         * [YURAM] HACK Begin
         *
         * Los cambios aplicados a continuación fueron aplicados a causa de una incompatibilidad entre componentes y elementos
         *
         */

        Ember.$('#button-save-changes').attr("disabled", "disabled");
        Ember.$("#evidenciaK").val(null);
        Ember.$('#inputUsuarioK  option[value=null]').prop("selected", true);
        Ember.$('#inputUsuarioK').prop("disabled", false);
        Ember.$('#inputFechaRegistro').datepicker("setDate", null);
        Ember.$("#evidenciaNombreDocumento").val('');
        Ember.$("#evidenciaRutaDocumento").val('');
        Ember.$('#filesattached').html('');
        Ember.$('#createUpdateFormEvidencia').modal({});
        /**
         * [YURAM] HACK End
         */
      },
      afterCreate: function afterCreate(dataObject) {
        var newRecord = this.store.createRecord('nom-035-stps/acciones-internas/evidencia', dataObject);
        this.controller.model.insertAt(0, newRecord);
      },
      showUpdateModal: function showUpdateModal(updateRecord) {
        var updateRecordEmberObject = this.store.createRecord('nom-035-stps/acciones-internas/evidencia', updateRecord);
        this.controller.set('updateStore', updateRecordEmberObject);
        this.controller.set('createDeleteCommand', 'update');
        this.controller.set('isVisibleCreateUpdateForm', true);
        this.controller.set('isCommandUpdate', true);
        /**
         * [YURAM] HACK Begin
         *
         * Los cambios aplicados a continuación fueron aplicados a causa de una incompatibilidad entre componentes y elementos
         *
         */

        Ember.$('#button-save-changes').removeAttr('disabled');
        Ember.$("#evidenciaK").val(Ember.get(updateRecord, 'evidencia_k'));
        Ember.$('#inputUsuarioK').prop("disabled", true);
        Ember.$('#inputUsuarioK  option[value=' + Ember.get(updateRecord, 'usuario_k') + ']').prop("selected", true);
        Ember.$('#inputFechaRegistro').datepicker("setDate", Ember.get(updateRecord, 'fecha_registro'));
        Ember.$("#evidenciaNombreDocumento").val(Ember.get(updateRecord, 'evidencia_nombre_documento'));
        Ember.$("#evidenciaRutaDocumento").val(Ember.get(updateRecord, 'evidencia_ruta_documento'));
        var html = '<a target="_blank" href="' + constant.BASEURL + Ember.get(updateRecord, 'evidencia_ruta_documento') + '">' + Ember.get(updateRecord, 'evidencia_nombre_documento') + '</a>';
        Ember.$('#filesattached').html(html);
        Ember.$('#createUpdateFormEvidencia').modal({});
        Ember.$('#createUpdateFormEvidencia').modal({});
        /**
         * [YURAM] HACK End
         */
      },
      afterUpdate: function afterUpdate(dataObject) {
        var new_element = this.store.createRecord('nom-035-stps/acciones-internas/evidencia', dataObject);
        var indexReplaceOf = this.recordIndexOf(this.controller.model, dataObject);

        if (indexReplaceOf != -1) {
          this.controller.model.removeAt(indexReplaceOf);
          this.controller.model.insertAt(indexReplaceOf, new_element);
        }
      },
      showPreviewModal: function showPreviewModal(record) {
        var evidencia_ruta_documento = record.get ? record.get('evidencia_ruta_documento') : record.evidencia_ruta_documento;
        var url = constant.BASEURL + evidencia_ruta_documento;
        var extension = url.split(".")[url.split(".").length - 1];
        var extensiones = ['pdf', 'gif', 'jpg', 'jpeg', 'png', 'bmp'];

        if (extensiones.includes(extension)) {
          Ember.$("#previewDocumentIFrame").attr("src", url);
          Ember.$('#previewDocumentModal').modal({});
        } else {
          showModal({
            titulo: 'No se puede previsualizr el documento.',
            mensaje: 'Solamente se puede previsualizar imagenes, documentos PDF. Te recomendamos descargar el documento para consultarlo.'
          });
        }
      }
    }
  });

  _exports.default = _default;
});