define("comunidades-nafin/components/empresa/description-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Esto indica que el componente no debe envolverse en un div adicional
    classNames: [],
    // Esto asegura que no se apliquen clases adicionales al div
    self: void 0,
    empresaObject: {
      informacion_de_empresa_visible_en_comunidades: false,
      imagen: constant.BASERECURSOSURL + '/img/comunidades/comunidades/default_comunidad.png',
      nombre: 'Lorem ipsum dolor sit amet.',
      descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis euismod egestas tellus, eu bibendum sapien egestas auctor. Morbi molestie in leo eu scelerisque. Sed vitae sapien lectus. Pellentesque scelerisque ante vitae ipsum sollicitudin, sed sagittis magna malesuada. Cras venenatis leo ut purus aliquet facilisis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Suspendisse volutpat ultrices erat, eu pellentesque turpis pharetra quis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Maecenas interdum nisi quis ex tristique interdum. Donec aliquet ac nisl sed bibendum. Suspendisse id est eu tellus maximus ullamcorper quis eu ligula.'
    },

    /**
     * didReceiveAttr - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2021-10-11
     * @param   void
     * @return  void
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('empresaObject', this.empresaObject);
      console.dir(this.get('empresaObject'));
    }
    /**
     * setupController - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2021-10-11
     * @param   void
     * @return  void
     */

  });

  _exports.default = _default;
});