define("comunidades-nafin/components/diagnostic-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      localStorage.setItem('seccion-anterior', Ember.$(".titulo-seccion").text()); // Busqueda de Cuestionarios NOM035/NOM037

      Ember.$('.container').on('keyup', '#cuestionario_busqueda', function () {
        var value = Ember.$(this).val().toLowerCase();
        Ember.$("#cuestionarios_busqueda #cuestionarioCard").filter(function () {
          Ember.$(this).toggle(Ember.$(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    },
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      var url_page = new URLSearchParams(window.location.search);
      var tipo = url_page.get('tipo');

      if (tipo != null && tipo == "misDiagnosticos") {
        self.set("diagnostic_state", false);
      } else {
        self.set("diagnostic_state", true);
      }

      if (window.instanciaConfiguracion) {
        if (window.instanciaConfiguracion.evaluaciones_dinamicas_listar_inscripciones) {
          Ember.$.ajax({
            url: constant.APIURL + 'evaluacionesdinamicas/evaluaciondinamica/listarevaluacionesdinamicasdesdecatalogoinscritasporusuario',
            type: 'POST',
            xhrFields: {
              withCredentials: true
            },
            contentType: 'application/x-www-form-urlencoded',
            success: function success(response) {
              var r = JSON.parse(response);

              if (r.numFilas != 0) {
                Object.keys(r.data).forEach(function (idx) {
                  /*
                                           *
                                           */
                  if (parseInt(r.data[idx].tipo_evaluacion) == 3 || parseInt(r.data[idx].tipo_evaluacion) == 4) {
                    r.data[idx].isEvaluacionUnUnicoIntento = true;
                  } else {
                    r.data[idx].isEvaluacionUnUnicoIntento = false;
                  }
                  /*
                                           *
                                           */


                  if (r.data[idx].ultimo_estatus == "1" || r.data[idx].ultimo_estatus == "60") {
                    r.data[idx].cursoIniciado = true;

                    if (r.data[idx].ultimo_estatus == "1") {
                      r.data[idx].evaluacionPendiente = true;
                    } else {
                      r.data[idx].evaluacionPendiente = false;
                    }
                  } else {
                    r.data[idx].cursoIniciado = false;
                  }
                  /*
                                           *
                                           */


                  r.data[idx].inicioElCurso = true;

                  if (r.data[idx].ultimo_fecha_hora_termino != null && r.data[idx].ultimo_fecha_hora_termino != undefined) {
                    r.data[idx].ultimo_fecha_hora_inicio = true;
                    var date_time = r.data[idx].ultimo_fecha_hora_termino.split(' ');
                    var date = date_time[0].split('-');
                    var month = date[1];

                    if (month === "01") {
                      r.data[idx].monthFormath = "Enero";
                    } else if (month === "02") {
                      r.data[idx].monthFormath = "Febrero";
                    } else if (month === "03") {
                      r.data[idx].monthFormath = "Marzo";
                    } else if (month === "04") {
                      r.data[idx].monthFormath = "Abril";
                    } else if (month === "05") {
                      r.data[idx].monthFormath = "Mayo";
                    } else if (month === "06") {
                      r.data[idx].monthFormath = "Junio";
                    } else if (month === "07") {
                      r.data[idx].monthFormath = "Julio";
                    } else if (month === "08") {
                      r.data[idx].monthFormath = "Agosto";
                    } else if (month === "09") {
                      r.data[idx].monthFormath = "Septiembre";
                    } else if (month === "10") {
                      r.data[idx].monthFormath = "Octubre";
                    } else if (month === "11") {
                      r.data[idx].monthFormath = "Noviembre";
                    } else {
                      r.data[idx].monthFormath = "Diciembre";
                    }

                    r.data[idx].dayFormath = date[2];
                    r.data[idx].yearFormath = date[0];
                  } else if (r.data[idx].ultimo_fecha_hora_inicio != null && r.data[idx].ultimo_fecha_hora_inicio != undefined) {
                    r.data[idx].ultimo_fecha_hora_termino = r.data[idx].ultimo_fecha_hora_inicio;
                    var date_time = r.data[idx].ultimo_fecha_hora_termino.split(' ');
                    var date = date_time[0].split('-');
                    var month = date[1];

                    if (month === "01") {
                      r.data[idx].monthFormath = "Enero";
                    } else if (month === "02") {
                      r.data[idx].monthFormath = "Febrero";
                    } else if (month === "03") {
                      r.data[idx].monthFormath = "Marzo";
                    } else if (month === "04") {
                      r.data[idx].monthFormath = "Abril";
                    } else if (month === "05") {
                      r.data[idx].monthFormath = "Mayo";
                    } else if (month === "06") {
                      r.data[idx].monthFormath = "Junio";
                    } else if (month === "07") {
                      r.data[idx].monthFormath = "Julio";
                    } else if (month === "08") {
                      r.data[idx].monthFormath = "Agosto";
                    } else if (month === "09") {
                      r.data[idx].monthFormath = "Septiembre";
                    } else if (month === "10") {
                      r.data[idx].monthFormath = "Octubre";
                    } else if (month === "11") {
                      r.data[idx].monthFormath = "Noviembre";
                    } else {
                      r.data[idx].monthFormath = "Diciembre";
                    }

                    r.data[idx].dayFormath = date[2];
                    r.data[idx].yearFormath = date[0];
                  } else {
                    r.data[idx].ultimo_fecha_hora_inicio = false;
                  }

                  r.data[idx].ruta_imagen = solveImageURL(r.data[idx].ruta_imagen);

                  if (r.data[idx].ultimo_calificacion != null && r.data[idx].ultimo_calificacion != undefined) {
                    r.data[idx].ultimo_calificacion = parseFloat(r.data[idx].ultimo_calificacion).toFixed(2);
                  } else {
                    r.data[idx].ultimo_calificacion = parseFloat(r.data[idx].pimer_calificacion).toFixed(2);
                  }
                });
                self.setDiagnostico(r.data);
              } else {
                self.withoutDiagnostic();
              }
            },
            error: function error(e) {
              console.log(e);
            }
          });
        } // if( window.instanciaConfiguracion.evaluaciones_dinamicas_listar_inscripciones )

      } // EndOf if( window.instanciaConfiguracion )


      if (window.instanciaConfiguracion) {
        if (window.instanciaConfiguracion.evaluaciones_dinamicas_listar_disponibles) {
          Ember.$.ajax({
            url: constant.APIURL + '/evaluacionesdinamicas/evaluaciondinamica/listarevaluacionesdinamicasdesdecatalogodisponibleporusuario',
            type: 'POST',
            async: false,
            xhrFields: {
              withCredentials: true
            },
            contentType: 'application/x-www-form-urlencoded',
            success: function success(response) {
              var r = JSON.parse(response);
              setInterval(function () {
                if (r.numFilas == 0) {
                  self.withoutDiagnosticAvailable();
                }
              }, 0);
              Object.keys(r.data).forEach(function (idx) {
                /*
                                      *
                                      */
                if (parseInt(r.data[idx].tipo_evaluacion) == 3) {
                  r.data[idx].isEvaluacionUnUnicoIntento = true;
                } else {
                  r.data[idx].isEvaluacionUnUnicoIntento = false;
                }
                /*
                                      *
                                      */


                r.data[idx].ruta_imagen = solveImageURL(r.data[idx].ruta_imagen);
              });
              self.set('all_diagnostics', r.data);
            },
            error: function error(e) {
              console.log(e);
            }
          });
        } // EndOf if( window.instanciaConfiguracion.evaluaciones_dinamicas_listar_disponibles ){

      } // EndOf if( window.instanciaConfiguracion ){


      self.set("diagnostico_empresarial_buscador", false);

      if (window.instanciaConfiguracion) {
        if (window.instanciaConfiguracion.diagnostico_empresarial_buscador) {
          if (window.instanciaConfiguracion.diagnostico_empresarial_buscador != false) {
            self.set("diagnostico_empresarial_buscador", true);
          }
        }
      }

      self.set("diagnostico_empresarial_titulo_principal", false);

      if (window.instanciaConfiguracion) {
        if (window.instanciaConfiguracion.diagnostico_empresarial_titulo_principal) {
          if (window.instanciaConfiguracion.diagnostico_empresarial_titulo_principal != false) {
            self.set("diagnostico_empresarial_titulo_principal", true);
          }
        }
      } // Colocamos el contenedor de las tarjetas


      if (window.location.hash === '#/diagnostico-empresarial/0') {
        self.set("contenedor_principal", "dashboard__content bg-light-4");
      } else {
        self.set("contenedor_principal", "");
      }
    },
    setDiagnostico: function setDiagnostico(data) {
      this.set('diagnostico', data);
      Ember.$('#btns .btn_circle').click(function () {
        Ember.$('#btns .btn_circle').toggleClass('active');
      });

      if (this.get('mis_diagnosticos')) {
        this.send('my_diagnostic');
      }
    },
    withoutDiagnosticAvailable: function withoutDiagnosticAvailable() {
      var html = '';
      Ember.$('#tarjeta_diagnostico_empresarial').html(html);
    },
    withoutDiagnostic: function withoutDiagnostic() {
      var html = '';
      html += '<div class="col-12 text-center margin_ud_10px">';
      html += '<h2>No ha inscrito ningun diagnóstico</h2>';
      html += '</div>';
      Ember.$('#sin_inscripciones').html(html);
    },
    withoutDate: function withoutDate() {
      var html = '';
      var idx_fecha = 0;
      html += '<p class="card-text">No ha terminado el diagnóstico</p>';
      Ember.$('.margin_title_diag #fecha_aplicacion_' + idx_fecha++).html(html);
    },
    withoutGrade: function withoutGrade() {
      var html = '';
      var idx_calif = 0;
      html += '<p class="card-text absolute bottom right margin_10px padding_10px">Sin calificación</p>';
      Ember.$('#calificacion_' + idx_calif++).html(html);
    },
    actions: {
      diagnostic: function diagnostic() {
        this.set("diagnostic_state", true);
      },
      my_diagnostic: function my_diagnostic() {
        this.set("diagnostic_state", false);
      },
      search: function search(event) {
        var input = Ember.$('#inputSearchDiagnostic').val(); //console.log('Buscando ' + input);

        if (input.length < 3) {
          Ember.$('.card-search').each(function () {
            Ember.$(this).parent().parent().parent().show();
          });
        } else {
          Ember.$('.card-search').each(function () {
            var text = Ember.$(this).text().toLowerCase();

            if (text.search(input.toLowerCase()) > -1) {
              Ember.$(this).parent().parent().parent().show();
            } else {
              Ember.$(this).parent().parent().parent().hide();
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});