define("comunidades-nafin/templates/mediateca", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OhyxUdIS",
    "block": "{\"symbols\":[],\"statements\":[[2,\" <link rel=\\\"stylesheet\\\" type=\\\"text/css\\\" href=\\\"css/cms.css\\\"/> \"],[0,\"\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\"],[14,\"loading\",[]],[0,\"\\n\\n\"],[1,[20,\"admin-components/mediateca\"],false],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\" \\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/mediateca.hbs"
    }
  });

  _exports.default = _default;
});