define("comunidades-nafin/templates/lms/reportes/seguimiento_individual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FfRdO5j6",
    "block": "{\"symbols\":[],\"statements\":[[1,[20,\"menu-header\"],false],[0,\"\\n\"],[2,\" \\n<div class=\\\"container-fluid\\\">\\n    <div class=\\\"row\\\">\\n        <div class=\\\"col-12 col-md-12\\\" id=\\\"espacioIframe\\\" style=\\\"height: 100vh;\\\">\\n<iframe id=\\\"diviFrame\\\" style=\\\"overflow:auto;width:100%;height:100%; background: white;\\\" frameborder=\\\"0\\\" src=\\\"{{ reporte }}\\\" ></iframe>\\n\\n        </div>\\n    </div>\\n</div>\\n\"],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"content-wrapper  js-content-wrapper\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"data-anim-wrap\",\"\"],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"row y-gap-50\"],[8],[0,\"\\n            \"],[6,\"div\"],[10,\"class\",\"col-12 col-md-12\"],[10,\"id\",\"espacioIframe\"],[10,\"style\",\"height: 100vh;\"],[8],[0,\"\\n                \"],[6,\"iframe\"],[10,\"id\",\"diviFrame\"],[10,\"style\",\"overflow:auto;width:100%;height:100%; background: white;\"],[10,\"frameborder\",\"0\"],[11,\"src\",[27,[[20,\"reporte\"]]]],[8],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\"\\n\"],[1,[20,\"sub-footer\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/lms/reportes/seguimiento_individual.hbs"
    }
  });

  _exports.default = _default;
});