define("comunidades-nafin/routes/cursos/curso-especifico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      /*
      let promise = new RSVP.Promise(function(resolve) {
      		later(this, function() {
      		resolve("ok");
      	}, 1000);
      });
      return promise;
      */
      if (!window.constant.BAND_RELOAD) {
        $("body").html("");
        window.location.reload();
      }
    },
    setupController: function setupController(controller) {
      if (!window.isDevice) controller.set("classContainer", "container");
      var curso = JSON.parse(localStorage.getItem("curso"));
      console.log('CURSO: ', curso);
      controller.set("curso", curso);
    },
    actions: {
      goBack: function goBack() {
        /* history.back(); */
        window.document.location.href = '/#/cursos';
        window.location.reload();
      }
    }
  });

  _exports.default = _default;
});