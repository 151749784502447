define("comunidades-nafin/routes/novedades", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      controller.set('mostrar_mediateca', false);
    },
    actions: {
      mostrar_mediateca: function mostrar_mediateca() {
        this.controller.set('mostrar_mediateca', true);
      }
    }
  });

  _exports.default = _default;
});