define("comunidades-nafin/components/body-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Esto indica que el componente no debe envolverse en un div adicional
    classNames: [] // Esto asegura que no se apliquen clases adicionales al div

  });

  _exports.default = _default;
});