define("comunidades-nafin/routes/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      console.log('EVIDENCIAS 0');
      console.log('window.constant.BAND_RELOAD 1: ', window.constant.BAND_RELOAD);

      if (!window.constant.BAND_RELOAD) {
        $("body").html("");
        window.location.reload();
      }
    },
    didInsertElement: function didInsertElement() {},
    setupController: function setupController(controller) {
      /* this._super(...arguments); */
      this._super(controller);

      var periodo_k = localStorage.getItem("periodo_k"); //window.location.href.substring( window.location.href.lastIndexOf( '/' ) + 1 );

      controller.set('periodo_k', periodo_k);
      controller.set('isVisibleGuia2', false);
      controller.set('isVisibleGuia3', false);
      controller.set('menu_dashboard', false); // Validamos si mostramos el Menu principal

      if (window.location.hash === '#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/0') {
        controller.set('band_menu_principal', true);
        controller.set('nombre_modulo', null);
      } else {
        controller.set('band_menu_principal', false);
        controller.set('nombre_modulo', localStorage.getItem('nombre_modulo'));
      }

      setTimeout(function () {
        $(".elemento").click(function () {
          $(".arrowRight").addClass("d-none");
          $(".elemento").removeClass("active");
          $(".elemento").parent().removeClass("text-right");
          $(this).addClass("active");
          $(this).parent().addClass("text-right");
          $(this).find(".arrowRight").removeClass("d-none");
          $("#card-header-open").text($(this).text());
        });
        $("#opcion-evidencias").change(function () {
          window.location.href = $(this).find("option:selected").data("url");
        });

        if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
          //if(1){  
          $("#contenedorEvidencia").removeAttr("style");
        }
        /* Mostramos menu dashboard en caso de movil */

        /* Se comenta por que se quito el menu lateral en el dashboard */


        if (window.innerWidth <= 768) {// $('#btn_menu_dashboard').show();
        } else {// $('#btn_menu_dashboard').css( 'cssText', 'display: none !important;' );
          }
      }, 1000);
      this.guias_asignadas();
    },
    guias_asignadas: function guias_asignadas(controller) {
      var periodo_k = localStorage.getItem("periodo_k");
      var self = this;
      $.ajax({
        url: constant.APIURL + '/nom035stps/evidenciasats/obtener_guias_por_periodo',
        type: 'POST',
        sync: true,
        xhrFields: {
          withCredentials: true
        },
        data: 'periodo_k=' + periodo_k,
        contenType: 'application/x-www-form-urlencoded',
        success: function success(response_string) {
          var responseObject = JSON.parse(response_string);
          responseObject.data.forEach(function (data) {
            if (data.guia_asignada == 'GUIA 2') {
              self.controller.set('isVisibleGuia2', true);
            }

            if (data.guia_asignada == 'GUIA 3') {
              self.controller.set('isVisibleGuia3', true);
            }
          });
        },
        error: function error(_error) {
          console.log(_error);
        }
      });
    },
    actions: {
      goAccionesInternas: function goAccionesInternas(nombre_modulo) {
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k"); // $("body").html("");

        window.document.location.href = '/#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/0/acciones-internas/' + periodo_k; // window.location.reload();
        // this.get('router').transitionTo('/#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/acciones-internas/' + periodo_k);

        document.location.reload();
      },
      goPoliticasRiesgo: function goPoliticasRiesgo(nombre_modulo) {
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/0/politicas-de-riesgo/' + periodo_k;
        window.location.reload();
      },
      goBuzonQuejas: function goBuzonQuejas(nombre_modulo) {
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/0/consultas/' + periodo_k;
        window.location.reload();
      },
      goSensibilizacion: function goSensibilizacion(nombre_modulo) {
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/0/estatus-de-capacitacion/' + periodo_k;
        window.location.reload();
      },
      goDistribucionGuias: function goDistribucionGuias(nombre_modulo) {
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k"); // window.document.location.href='#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/distribucion-guias/' + periodo_k;
        // window.location.reload();

        window.location.href = '#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/0/distribucion-guias/' + periodo_k;
        window.location.reload();
      },
      goEstatusAplicacion: function goEstatusAplicacion(nombre_modulo) {
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/0/estatus-aplicacion-guias/' + periodo_k;
        window.location.reload();
      },
      goUsuariosATS: function goUsuariosATS(nombre_modulo) {
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/0/colaboradores-con-ats/' + periodo_k;
        window.location.reload();
      },
      goGuia2: function goGuia2(nombre_modulo) {
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/0/resultados-guia-2/' + periodo_k;
        window.location.reload();
      },
      goGuia3: function goGuia3(nombre_modulo) {
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/0/resultados-guia-3/' + periodo_k;
        window.location.reload();
      },
      goPlanTrabajo: function goPlanTrabajo(nombre_modulo) {
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/0/plan-de-trabajo/' + periodo_k;
        window.location.reload();
      },
      gonom035STPSCarpetaEvidencias: function gonom035STPSCarpetaEvidencias(periodo_k) {
        $("body").html("");
        localStorage.setItem("periodo_k", periodo_k);
        document.location.href = '/#/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/0';
        document.location.reload();
      }
    }
  });

  _exports.default = _default;
});