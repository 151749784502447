define("comunidades-nafin/components/offline/detalle-ruta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bus: Ember.inject.service('evaluacion-curso'),
    didInsertElement: function didInsertElement() {
      var self = this;
      document.addEventListener("deviceready", onDeviceReady, false);

      function onDeviceReady() {
        self.inicializar();
      }
    },
    inicializar: function inicializar() {
      var self = this;
      var ra = localStorage.getItem("ruta-especifica");
      var detalles = localStorage.getItem("ruta-detalles");
      Ember.$("body").css("padding-top", "35px");
      Ember.$(".menu-mobile .online").addClass("d-none");
      Ember.$(".menu-mobile .offline").removeClass("d-none");
      if (ra == null || detalles == null) window.location.href = window.location.href.split("#")[0] + "#/cursos-offline";
      ra = JSON.parse(ra);
      detalles = JSON.parse(detalles); // Detalles generales de curso

      var curso = {
        imagen_raprendizaje: detalles.imagen_ra,
        nombre: detalles.ra_nombre,
        descripcion: detalles.ra_descripcion
      };
      self.set("curso", curso); // Lista de evaluaciones

      var progreso = JSON.parse(ra.progreso); // Objetos

      var objetos = ra.objetos;
      var cursoObjeto = [];
      Ember.$.each(progreso, function (i, v) {
        var objetoEnRa = false;

        for (var k = 0; k < objetos.length; k++) {
          if (objetos[k].propietario_recurso_k == v.propietario_recurso_k) {
            // El objeto fue descargado
            objetos[k].disponibleOffline = true;
            objetos[k].pendiente = false; // El objeto es descargado pero dependiente

            if (objetos[k].estatus == null) objetos[k].pendiente = true;
            console.log(objetos[k]);
            cursoObjeto.push(objetos[k]);
            objetoEnRa = true;
          }
        }

        if (!objetoEnRa) {
          v.disponibleOffline = false;

          if (v.tabla_recurso == 'lms_evaluaciones' && v.evaluacion_autodiagnostica == "1") {
            v.icon = window.constant.URL_THEME.img_evaluacion;
          } else if (v.tabla_recurso == 'lms_cursos_online') {
            v.icon = window.constant.URL_THEME.img_scorm;
          } else if (v.tabla_recurso == 'lms_encuestas') {
            v.icon = window.constant.URL_THEME.img_encuesta;
          } else if (v.tabla_recurso == 'lms_evaluaciones') {
            v.icon = window.constant.URL_THEME.img_evaluacion;
          } else if (v.tabla_recurso == 'lms_actividad') {
            v.icon = window.constant.URL_THEME.img_actividad;
          } else {
            v.icon = window.constant.URL_THEME.img_presencial;
          }

          cursoObjeto.push(v);
        }
      });
      self.set("evaluacion", cursoObjeto); // Porcentaje de avance

      self.set("porcentaje_avance", ra.porcentaje_avance);
      Ember.$('#loading').fadeOut('slow');
    },
    numOfTimes: Ember.computed('times', function () {
      return [1, 2, 3, 4, 5];
    }),
    actions: {
      goBack: function goBack() {
        var url = window.location.href.split("#")[0] + "#/cursos-offline";
        window.location.href = url;
        window.location.reload();
      },
      scorm: function scorm() {
        Ember.$("#modal-scorm-saving").modal("show");
        Ember.$(".modal-dialog #ifr").attr("onload", "$('#modal-scorm-saving').modal('hide');");
        setTimeout(function () {
          Ember.$('#modal-scorm').modal('hide');
          Ember.$(".modal-dialog #ifr").attr("src", "");
        }, 1000);
      },
      grade: function grade(event) {
        var numberStart = Ember.$(event.target).parent().attr('id');

        if (numberStart === 'uno_estrellas') {
          Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas').html('<i class="far fa-star"></i>');
          Ember.$('#tres_estrellas').html('<i class="far fa-star"></i>');
          Ember.$('#cuatro_estrellas').html('<i class="far fa-star"></i>');
          Ember.$('#cinco_estrellas').html('<i class="far fa-star"></i>'); //console.log('1 estrella');
        } else if (numberStart === 'dos_estrellas') {
          Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas').html('<i class="far fa-star"></i>');
          Ember.$('#cuatro_estrellas').html('<i class="far fa-star"></i>');
          Ember.$('#cinco_estrellas').html('<i class="far fa-star"></i>'); //console.log('2 estrellas');
        } else if (numberStart === 'tres_estrellas') {
          Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#cuatro_estrellas').html('<i class="far fa-star"></i>');
          Ember.$('#cinco_estrellas').html('<i class="far fa-star"></i>'); //console.log('3 estrellas');
        } else if (numberStart === 'cuatro_estrellas') {
          Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#cuatro_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#cinco_estrellas').html('<i class="far fa-star"></i>'); //console.log('4 estrellas');
        } else if (numberStart === 'cinco_estrellas') {
          Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#cuatro_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#cinco_estrellas').html('<i class="fas fa-star"></i>'); //console.log('5 estrellas');
        } else {
          console.warn(numberStart);
        }
      },
      grade_platform: function grade_platform(event) {
        var numberStart = Ember.$(event.target).parent().attr('id');

        if (numberStart === 'uno_estrellas_pltf') {
          Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas_pltf').html('<i class="far fa-star"></i>');
          Ember.$('#tres_estrellas_pltf').html('<i class="far fa-star"></i>');
          Ember.$('#cuatro_estrellas_pltf').html('<i class="far fa-star"></i>');
          Ember.$('#cinco_estrellas_pltf').html('<i class="far fa-star"></i>'); //$('#modal_grade').modal();
        } else if (numberStart === 'dos_estrellas_pltf') {
          Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas_pltf').html('<i class="far fa-star"></i>');
          Ember.$('#cuatro_estrellas_pltf').html('<i class="far fa-star"></i>');
          Ember.$('#cinco_estrellas_pltf').html('<i class="far fa-star"></i>'); //$('#modal_grade').modal();
        } else if (numberStart === 'tres_estrellas_pltf') {
          Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#cuatro_estrellas_pltf').html('<i class="far fa-star"></i>');
          Ember.$('#cinco_estrellas_pltf').html('<i class="far fa-star"></i>'); //$('#modal_grade').modal();
        } else if (numberStart === 'cuatro_estrellas_pltf') {
          Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#cuatro_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#cinco_estrellas_pltf').html('<i class="far fa-star"></i>'); //$('#modal_grade').modal();
        } else if (numberStart === 'cinco_estrellas_pltf') {
          Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#cuatro_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#cinco_estrellas_pltf').html('<i class="fas fa-star"></i>'); //$('#modal_grade').modal();
        } else {
          console.warn(numberStart);
        }
      },
      listDir: function listDir(path) {
        window.resolveLocalFileSystemURL(path, function (fileSystem) {
          var reader = fileSystem.createReader();
          reader.readEntries(function (entries) {
            console.log(entries);
          }, function (err) {
            console.log(err);
          });
        }, function (err) {
          console.log(err);
        });
      },
      send_grade: function send_grade() {},
      evaluacion: function evaluacion(_evaluacion) {
        var self = this;
        console.log(_evaluacion);
        self.send("listDir", _evaluacion.path);
        localStorage.setItem("rutaScorm", _evaluacion.path);
        localStorage.setItem("launchScorm", _evaluacion.ruta_scorm);
        localStorage.setItem("evaluacion", JSON.stringify(_evaluacion));
        if (_evaluacion.estatus == 8) localStorage.setItem("scormtest", true);else localStorage.setItem("scormtest", false);
        var url = window.location.href.split("#")[0] + "#/scorm-offline";
        window.location.href = url;
        window.location.reload();
      },
      scormOffline: function scormOffline() {}
    }
  });

  _exports.default = _default;
});