define("comunidades-nafin/routes/comunidades/comunidad-especifica", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      /*
      let promise = new RSVP.Promise(function(resolve) {
      		later(this, function() {
      		resolve("ok");
      	}, 1000);
      });
      return promise;
      */

      /**
       * Validamos si es necesario recargar la pagina para cargar todos los componenetes del template nuevo
       */
      if (!window.constant.BAND_RELOAD) {
        $("body").html("");
        window.location.reload();
      }
    },
    actions: {
      go: function go() {
        return false;
      },
      goComunidades: function goComunidades() {
        window.document.location.href = '/#/comunidades';
        window.location.reload();
      },
      goBack: function goBack() {
        history.back();
      }
    }
  });

  _exports.default = _default;
});