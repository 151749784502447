define("comunidades-nafin/routes/noticia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (!window.constant.BAND_RELOAD) {
        Ember.$("body").html("");
        window.location.reload();
      }
    },
    setupController: function setupController(controller) {
      var self = this;
      var idioma = {};

      if (window.idioma) {
        idioma = window.idioma;
      }

      controller.set("idioma", idioma);
      var params = window.location.href.split("/");
      params = new URLSearchParams(params[params.length - 1]);
      var noticias_k = params.get('noticia_k');
      console.log('URL: ', params, noticias_k);

      if (noticias_k != null && noticias_k != '' && noticias_k != undefined) {
        var _tipo_noticia;

        Ember.$.ajax({
          url: constant.APIURL + 'noticia/listar',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          xhrFields: {
            withCredentials: true
          },
          data: 'params={"noticia_k":"' + noticias_k + '"}',
          success: function success(r) {
            Ember.$('#loading').fadeOut('slow');
            var respons = JSON.parse(r);
            var dia, mes, mesNumero, año, fecha;
            console.log(respons.data);

            if (respons.data[0].cuerpo.indexOf('<div>') != 0) {
              respons.data[0].cuerpo = '<div>' + respons.data[0].cuerpo + '</div>';
            }

            var detalle = Ember.$.parseHTML(respons.data[0].cuerpo, document, true);

            if (Ember.$(detalle).find('a').length) {
              Ember.$(detalle).find('a').attr('target', '_blank');
              var url = Ember.$(detalle).find('a').attr('href');

              if (url && url.indexOf('http') != 0) {
                Ember.$(detalle).find('a').attr('href', '//' + url);
              }

              respons.data[0].cuerpo = Ember.$(detalle).html();
            }

            Object.keys(respons.data).forEach(function (idx) {
              // Se uso para los botones de compartir, si se requiere ocupar la consulta necesita realizarce fuera del bucle

              /*
              $.ajax({
              	url:            constant.APIURL + 'usuario/datospersonales/',
              	type:           'POST',
              	contentType:    'application/x-www-form-urlencoded; charset=UTF-8',
              	xhrFields:      { withCredentials: true },
              	success( r ) {
                                        var data = JSON.parse(r);
                                        console.log(data.configuracion.general_compatir_noticias_en_linkedin_habilitar);
                                        //Redes sociales
                                        if(data.configuracion.general_compatir_noticias_en_facebook_habilitar == false){
                                            $("#face_vis").hide();
                                        }
                                        else{
                                            $("#face_vis").show();
                                        }
                                        if(data.configuracion.general_compatir_noticias_en_twitter_habilitar == false){
                                            $("#twitter_vis").hide();
                                        }
                                        else{
                                            $("#twitter_vis").show();
                                        }
                                        if(data.configuracion.general_compatir_noticias_en_linkedin_habilitar == false){
                                            $("#link_vis").hide();
                                        }
                                        else{
                                            $("#link_vis").show();
                                        }
                                    },
                                    error(e) 
                                    {
                                        console.log(e);
              	}
              });
              */
              dia = respons.data[idx].timestamp_creacion.substring(8, 10);
              mesNumero = parseInt(respons.data[idx].timestamp_creacion.substring(5, 7));

              switch (mesNumero) {
                case 1:
                  mes = 'Enero';
                  break;

                case 2:
                  mes = 'Febrero';
                  break;

                case 3:
                  mes = 'Marzo';
                  break;

                case 4:
                  mes = 'Abril';
                  break;

                case 5:
                  mes = 'Mayo';
                  break;

                case 6:
                  mes = 'Junio';
                  break;

                case 7:
                  mes = 'Julio';
                  break;

                case 8:
                  mes = 'Agosto';
                  break;

                case 9:
                  mes = 'Septiembre';
                  break;

                case 10:
                  mes = 'Octubre';
                  break;

                case 11:
                  mes = 'Noviembre';
                  break;

                case 12:
                  mes = 'Diciembre';
                  break;

                default:
                  break;
              }

              año = respons.data[idx].timestamp_creacion.substring(0, 4);
              fecha = dia + ' de ' + mes + ' de ' + año;
              respons.data[idx].timestamp_creacion = fecha;

              if (respons.data[idx].imagen.split('/')[0] === 'uploads') {
                respons.data[idx].imagen = constant.BASEURL + respons.data[idx].imagen;
              }

              _tipo_noticia = respons.data[idx].tipo;
            });
            console.log('ESPECIFICO: ', respons.data);
            controller.set('hilo-noticias_especifico', respons.data);
            setTimeout(function () {
              self.initRedesSociales();
            }, 0); //tracking

            self.goTrackingNoticia(noticias_k, _tipo_noticia, '');
            Ember.$('.btn-lista-noticias').removeClass('hideBtnListaNoticias');
            Ember.$('.bucardor_tag').hide();
          },
          error: function error(e) {
            console.error(e);
          }
        });
      } else {
        document.location.href = "#/noticias/0";
        document.location.reload();
      }
    },
    goTrackingNoticia: function goTrackingNoticia(_noticias_k, _tipo_noticia, _url) {
      var _data;

      if (_url == '') {
        _data = 'noticia_k=' + _noticias_k + '&tipo_noticia=' + _tipo_noticia;
      } else {
        _data = 'noticia_k=' + _noticias_k + '&tipo_noticia=' + _tipo_noticia + '&url=' + _url;
      } //tracking


      Ember.$.ajax({
        url: constant.APIURL + 'noticia/agregarTrackingNoticia',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        data: _data,
        success: function success(response) {
          var r = JSON.parse(response).data;
          console.log(r);
        },
        error: function error(response) {
          console.log(response);
        }
      });
    },
    initRedesSociales: function initRedesSociales() {
      this._super();

      var self = this;
      Ember.$.ajax({
        url: constant.APIURL + 'usuario/datospersonales/',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          var data = JSON.parse(r);

          if (data.configuracion.general_compatir_noticias_en_facebook_habilitar == false) {
            Ember.$("#face_vis").hide();
          } else {
            Ember.$("#face_vis").show();
          }

          if (data.configuracion.general_compatir_noticias_en_twitter_habilitar == false) {
            Ember.$("#twitter_vis").hide();
          } else {
            Ember.$("#twitter_vis").show();
          }

          if (data.configuracion.general_compatir_noticias_en_linkedin_habilitar == false) {
            Ember.$("#link_vis").hide();
          } else {
            Ember.$("#link_vis").show();
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
      Ember.$('.twitterShare').click(function (e) {
        var classRow = Ember.$(e.target).parent().parent().parent().parent().parent().children()[1];
        var classRowTextNew = Ember.$(e.target).parent().parent().parent().parent().parent().children()[4];
        var primerParrafo = Ember.$(classRowTextNew).children().children()[0];
        var primerParrafoText = Ember.$(primerParrafo).text();
        var tituloNoticia = Ember.$(classRow).text().trim();
        var twitterMsm = tituloNoticia.replace("%", " porciento") + ' -- ' + primerParrafoText.replace("%", " porciento");
        var noticia_k = Ember.$(e.target).closest("div[data-noticia]").data('noticia');
        var url = constant.SHAREURL + 'noticia/' + noticia_k;
        var width = 575,
            height = 400,
            left = (Ember.$(window).width() - width) / 2,
            top = (Ember.$(window).height() - height) / 2,
            url = 'https://twitter.com/share?url=' + url + '&text=' + twitterMsm,
            opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
        window.open(url, 'twitter', opts);
        window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_TWITTER_COMPARTIR_NOTICIA, noticia_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_TWITTER);
      });
      Ember.$('.facebookShare').click(function (e) {
        var noticia_k = Ember.$(e.target).closest("div[data-noticia]").data('noticia');
        shareFB(constant.SHAREURL + 'noticia/' + noticia_k);
        window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_FACEBOOK_COMPARTIR_NOTICIA, noticia_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_FACEBOOK);
      });
      Ember.$('.linkedInShare').click(function (e) {
        var noticia_k = Ember.$(e.target).closest("div[data-noticia]").data('noticia');
        shareLI(constant.SHAREURL + 'noticia/' + noticia_k);
        window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_LINKEDIN_COMPARTIR_NOTICIA, noticia_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_LINKEDIN);
      });
    },
    actions: {
      go: function go() {
        return false;
      },
      goListaNoticias: function goListaNoticias() {
        document.location.href = "#/noticias/0";
        document.location.reload();
      },
      clearModal: function clearModal() {
        Ember.$('#tipo_noticia').val("");
        Ember.$('#mostrar_en').val("");
        Ember.$('#titulo').val("");
        Ember.$('#tags').val("");
        Ember.$('#noticia').val("");
        Ember.$('[data-role="remove"]').click();
        Ember.$('#cont-imagen-noticia .texto-subio-imagen').css("display", "none");
        Ember.$('#cont-imagen-noticia #imagen_seleccionada').css("display", "none");
        Ember.$('.search-choice-close').click();
      },
      addNoticia: function addNoticia() {
        Ember.$('#loading-modal').show();
        Ember.$("#modalNoticia").modal({
          backdrop: "static",
          keyboard: false
        });
        Ember.$('#modalNoticia #imagen-cargando').removeClass('d-none');
        Ember.$("#modalNoticia #noticia").jqte();
        this.send('clearModal');
        Ember.$.ajax({
          url: constant.APIURL + 'noticia/mostrarEn',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'start=0&limit=20&params={"bandera":"1"}',
          success: function success(r) {
            var respons = JSON.parse(r);
            console.log(respons);
            var html = '';
            Object.keys(respons.data).forEach(function (idx) {
              var selected = '';

              if (respons.data[idx].llave_k == '3') {
                selected = "selected='selected'";
              }

              html += '<option value="' + respons.data[idx].llave_k + '" ' + selected + '>' + respons.data[idx].nombre + '</option>';
            });
            Ember.$('#tipo_noticia').html(html);
            Ember.$('.cls-noticia-mostrar-en').css('display', 'block');
          },
          error: function error(e) {
            console.log(e);
          }
        });
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listar_comunidades_por_usuario',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'start=0&limit=200&params={"noticias_activo":"1"}',
          success: function success(r) {
            Ember.$('#loading-modal').hide();
            var respons = JSON.parse(r);

            var htmls = [],
                _htmls;

            Object.keys(respons.data).forEach(function (idx) {
              var html = '<option value="' + respons.data[idx].comunidad_k + '">' + respons.data[idx].nombre + '</option>';
              htmls.push(html);
            });
            _htmls = htmls.join(''); //console.log(_htmls);

            Ember.$('#mostrar_en')[0].innerHTML = _htmls;
            Ember.$('.form-control-chosen').chosen({
              allow_single_deselect: true,
              width: '100%'
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    }
  });

  _exports.default = _default;
});