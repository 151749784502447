define("comunidades-nafin/routes/nom-037/evidencias/estatus-de-capacitacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('periodo_k', model.periodo_k);
      $(".sidebar__item").removeClass("-is-active -dark-bg-dark-2");
      $("#wrap_capacitacion").addClass("-is-active -dark-bg-dark-2");
      /* Mostramos menu dashboard en caso de movil 
      if (window.innerWidth <= 768) 
      {
          $('#btn_menu_dashboard').show();
      }
      else
      {
          $('#btn_menu_dashboard').css( 'cssText', 'display: none !important;' );
      }
      */

      setTimeout(function () {
        $(".table-responsive").find(".table").DataTable({
          language: {
            "decimal": "",
            "emptyTable": "Sin registros",
            "info": "Mostrando del _START_ al  _END_ de _TOTAL_ Registros",
            "infoEmpty": "Mosrando 0 to 0 de 0 registros",
            "infoFiltered": "(Filtro _MAX_ registros totales)",
            "infoPostFix": "",
            "thousands": ",",
            "lengthMenu": "Mostrar _MENU_ registros",
            "loadingRecords": "Cargando...",
            "processing": "",
            "search": "Buscar:",
            "zeroRecords": "Sin coincidencias",
            "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "aria": {
              "sortAscending": ": activate to sort column ascending",
              "sortDescending": ": activate to sort column descending"
            }
          }
          /*,
          initComplete: function () {
              this.api()
                  .columns()
                  .every(function () {
                      var column = this;
                      var select = $('<select><option value=""></option></select>')
                          .appendTo($(column.header()))
                          .on('change', function () {
                              var val = $.fn.dataTable.util.escapeRegex($(this).val());
                               column.search(val ? '^' + val + '$' : '', true, false).draw();
                          });
                       column
                          .data()
                          .unique()
                          .sort()
                          .each(function (d, j) {
                              select.append('<option value="' + d + '">' + d + '</option>');
                          });
                  });
          }*/

        }); // Eliminamos los header de busqueda de las 2 primeras columnas

        $("#table-estatus-capacitacion").find("table thead tr th:eq(0)").find("select").remove();
        $("#table-estatus-capacitacion").find("table thead tr th:eq(1)").find("select").remove();
      }, 3000); // Obtenemos la URL del sitio actual

      var url_actual = window.location.href;
      var url_reg = url_actual.split("/");
      var nombre_page = url_reg[url_reg.length - 2];
      setTimeout(function () {
        $(".sidebar__item").removeClass("-is-active -dark-bg-dark-2");

        if (nombre_page === 'estatus-de-capacitacion') {
          $("#wrap_capacitacion").addClass("-is-active -dark-bg-dark-2");
        }
      }, 500);
    },
    actions: {
      showFormRequestGenerateZip: function showFormRequestGenerateZip(record) {
        /**
         * [YURAM] HACK Begin
         *
         * Los cambios aplicados a continuación fueron aplicados a causa de una incompatibilidad entre componentes y elementos
         *
         */
        $("#compresoK").val(null);
        $("#inputEmail").val('');
        $('#requestFormMassiveUserReports').modal({});
        setTimeout(function () {}, 3000);
        /**
         * [YURAM] HACK End
         */
      },
      bindScopeToReportAtsGeneracionMasivaReportesComponent: function bindScopeToReportAtsGeneracionMasivaReportesComponent(scope_reporte_ats_generacion_masiva_reportes) {
        this.reporteGeneracionMasivaReportesScope = scope_reporte_ats_generacion_masiva_reportes;
      },
      afterCreate: function afterCreate() {
        if (this.reporteGeneracionMasivaReportesScope) {
          if (isFunction(this.reporteGeneracionMasivaReportesScope.propagationAfterCreateEvent)) {
            this.reporteGeneracionMasivaReportesScope.propagationAfterCreateEvent();
          }
        }
      },
      afterUpdate: function afterUpdate() {}
    }
  });

  _exports.default = _default;
});