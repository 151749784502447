define("comunidades-nafin/components/nom037/reportes/reports/carpeta-evidencias-consultas", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    title: 'Listado de las consultas generadas a través de la plataforma.',
    clase: 'consulta',
    metodo: 'listartodaslasconsultasporempresaporperiodo',
    reporte: 'nom035stps/export/consultasporempresa',
    periodo_k: null,
    headers: [{
      title: 'Fecha creación',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Usuario Creación',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Titulo',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Descripción',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Tipo',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Trabajadores involucrados',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Fecha ocurrencia',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Visualizar',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('params', {
        'periodo_k': this.periodo_k
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      console.log('Colcoar datatable');
      setTimeout(function () {
        $("#tabConsultaBuzon").DataTable({
          retrieve: true,
          language: {
            "decimal": "",
            "emptyTable": "Sin registros",
            "info": "Mostrando del _START_ al  _END_ de _TOTAL_ Registros",
            "infoEmpty": "Mosrando 0 to 0 de 0 registros",
            "infoFiltered": "(Filtro _MAX_ registros totales)",
            "infoPostFix": "",
            "thousands": ",",
            "lengthMenu": "Mostrar _MENU_ registros",
            "loadingRecords": "Cargando...",
            "processing": "",
            "search": "Buscar:",
            "zeroRecords": "Sin coincidencias",
            "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "aria": {
              "sortAscending": ": activate to sort column ascending",
              "sortDescending": ": activate to sort column descending"
            }
          }
        });
      }, 1000);
    },
    action: function action() {}
  });

  _exports.default = _default;
});