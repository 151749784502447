define("comunidades-nafin/routes/comunidades", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    tag: 'div',
    classNames: ['foo'],

    /**
     * didReceiveAttr - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2021-10-11
     * @param   void
     * @return  void
     */
    beforeModel: function beforeModel() {
      /*
      let promise = new RSVP.Promise(function(resolve) {
      		later(this, function() {
      		resolve("ok");
      	}, 2000);
      });
      return promise;
      */

      /**
       * Validamos si es necesario recargar la pagina para cargar todos los componenetes del template nuevo
       */
      if (!window.constant.BAND_RELOAD) {
        $("body").html("");
        window.location.reload();
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('empresaObject', window.constant.configuracionEmpresa);
      setTimeout(function () {
        $('#loading').fadeOut('slow');
      }, 2000);
    },
    actions: {
      go: function go() {
        return false;
      },
      goNovedades: function goNovedades() {
        // Obtenemos la primera comunidad asignada del usuario
        var comunidad_k = localStorage.getItem("primer_id_comunidad");
        window.document.location.href = '/#/novedades/' + comunidad_k;
        window.location.reload();
      }
    }
  });

  _exports.default = _default;
});