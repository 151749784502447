define("comunidades-nafin/templates/comunidades/comunidad-especifica", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZMJBuCJL",
    "block": "{\"symbols\":[],\"statements\":[[2,\" \\n<link rel=\\\"stylesheet\\\" type=\\\"text/css\\\" href=\\\"../css/comunidades.css\\\"/>\\n<link rel=\\\"stylesheet\\\" type=\\\"text/css\\\" href=\\\"../css/foros.css\\\"/>\\n<link rel=\\\"stylesheet\\\" type=\\\"text/css\\\" href=\\\"../css/news-cards.css\\\"/>\\n<link rel=\\\"stylesheet\\\" type=\\\"text/css\\\" href=\\\"../css/events-list.css\\\"/>\\n<link rel=\\\"stylesheet\\\" type=\\\"text/css\\\" href=\\\"../css/cms.css\\\"/>\\n\"],[0,\"\\n\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\"],[14,\"loading\",[]],[0,\"\\n\\n\\t\"],[2,\" <div class=\\\"container-fluid margin_ud_10px\\\"> \"],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"content-wrapper  js-content-wrapper\"],[8],[0,\"\\n\\n\\t\\t\"],[1,[20,\"community-wall\"],false],[0,\"\\n\\n\\t\\t\"],[1,[20,\"body-footer\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[2,\" </div> \"],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n \"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/comunidades/comunidad-especifica.hbs"
    }
  });

  _exports.default = _default;
});