define("comunidades-nafin/routes/nom-037/evidencias-galeria", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      var self = this;
      controller.set('usuario_k', atob(model.usuario_k));
      controller.set('periodo_k', model.periodo_k);
      controller.set('nombre_colaborador', "");
      controller.set('direccion_selected', "");
      controller.set('anios', []);
      controller.set('anio_actual', "");
      controller.set('mes_actual', "");
      controller.set('logo', window.constant.URL_THEME.logo);
      controller.set('url_recibo-luz', "");
      controller.set('url_recibo-internet', "");
      controller.set('id_colaborador_direccion_k', 0);
      /* Listado de años */

      self.obtiene_anios(controller);
      /* Cargamos las direcciones del usuario */

      self.cargardirecciones(controller);
      setTimeout(function () {
        self.cargarApoyos('EQUIPO', 'wrapper_tabequipoasignado', controller);
        self.cargarApoyos('MEDICA', 'wrapper_tabevidenciamedica', controller);
      }, 1000);
    },
    obtiene_anios: function obtiene_anios(controller) {
      var fecha_actual = new Date(),
          anio_actual = fecha_actual.getFullYear(),
          anio_anterior = anio_actual - 1,
          anios = [],
          aux_mes = fecha_actual.getMonth() + 1,
          mes = aux_mes < 10 ? '0' + aux_mes : aux_mes;
      anios.push(anio_actual);
      anios.push(anio_anterior);
      controller.set("anios", anios);
      controller.set("anio_actual", anio_actual);
      controller.set("mes_actual", mes);
    },
    cargardirecciones: function cargardirecciones(controller) {
      var self = this;
      $.ajax({
        url: constant.APIURL + 'nom037/evidenciagaleria/listardirecciones',
        type: "POST",
        async: false,
        data: {
          usuario_k: controller.get("usuario_k"),
          periodo_k: controller.get("periodo_k")
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          setTimeout(function () {
            $("#loading").fadeOut(1500);
          }, 2000);
          controller.set('isVisibleBtnCerrarGaleria', true);

          if (response.numFilas == 0) {
            window.showModal({
              titulo: 'Evidencias.',
              mensaje: 'El usuario no cuenta con domicilio(s) cargados.'
            });
            return false;
          } else if (response.numFilas == 1) {
            controller.set('isVisibleBtnCerrarGaleria', false);
            controller.set('nombre_colaborador', response.data[0].nombre_colaborador);
            controller.set('direccion_selected', response.data[0].domicilio); // En caso de tener una sola dirección pasar directo a las evidencias

            setTimeout(function () {
              /* Cargamos evidencias del usuario */
              controller.set('id_colaborador_direccion_k', response.data[0].id_colaborador_direccion_k);
              self.cargarlistaevidencias(controller, response.data[0].id_colaborador_direccion_k);
              /* Cargamos el Recibo de Luz */

              self.cargar_recibo(controller, 'luz', controller.get('anio_actual'), controller.get('mes_actual'), response.data[0].id_colaborador_direccion_k, 'url_recibo-luz');
              /* Cargamos el Recibo de Internet */

              self.cargar_recibo(controller, 'internet', controller.get('anio_actual'), controller.get('mes_actual'), response.data[0].id_colaborador_direccion_k, 'url_recibo-internet');
            }, 1000);
          }

          controller.set('nombre_colaborador', response.data[0].nombre_colaborador);
          controller.set('direcciones', response.data);
        },
        error: function error(e) {
          window.showModalError();
          console.log(e);
        }
      });
    },
    cargarlistaevidencias: function cargarlistaevidencias(controller, id_colaborador_direccion_k) {
      $.ajax({
        url: constant.APIURL + 'nom037/evidenciagaleria/listarevidencia',
        type: "POST",
        async: false,
        data: {
          usuario_k: controller.get("usuario_k"),
          periodo_k: controller.get("periodo_k"),
          id_colaborador_direccion_k: id_colaborador_direccion_k
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          var imagenes = []; //$.each(response.data,function(i,v){
          //  i = { path_ruta: constant.BASEURL + elem.ruta };
          // imagenes.push();
          //});
          //controller.set( 'imagenes', imagenes );

          if (response.evidencias.numFilas > 0) {
            $.each(response.evidencias.data, function (ind, elem) {
              if (elem.tipo == "Domicilio") imagenes[ind] = {
                path_ruta: constant.BASEURL + elem.ruta
              };
            });
            controller.set('imagenes', imagenes);
            controller.set('luz', "");
            controller.set('internet', "");
            $.each(response.internet.data, function (ind, elem) {
              controller.set('internet', constant.BASEURL + elem.ruta);
            });
            $.each(response.luz.data, function (ind, elem) {
              controller.set('luz', constant.BASEURL + elem.ruta);
            });
            setTimeout(function () {
              var swiper = new Swiper(".mySwiper", {
                zoom: true,
                spaceBetween: 30,
                effect: "fade",
                autoplay: {
                  delay: 3000,
                  disableOnInteraction: false
                },
                pagination: {
                  el: ".swiper-pagination",
                  type: "progressbar"
                },
                navigation: {
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev"
                }
              });
              $("#loading").fadeOut(1500);
            }, 1000);
            /* Mostrar evidencias */

            $("#warpper-direcciones").addClass("d-none");
            $("#warpper-imagenes").removeClass("d-none");
          } else {
            $("#loading").fadeOut(1500);
            /* Mostrar error en caso de que el domicilio no tenga evidencias */

            window.showModal({
              titulo: 'Evidencias.',
              mensaje: 'El domicilio no cuenta con evidencia cargada.'
            });
          }
        },
        error: function error(e) {
          window.showModalError();
          console.log(e);
        }
      });
    },
    cargar_recibo: function cargar_recibo(controller, tipo_recibo, anio, mes, id_colaborador_direccion_k, id_iframe) {
      // Limpiamos contenedor
      controller.set(id_iframe, ""); // Obtenemos el archivo

      $.ajax({
        url: constant.APIURL + 'nom037/evidenciagaleria/obtener_recibo',
        type: "post",
        data: {
          id_colaborador_direccion_k: id_colaborador_direccion_k,
          tipo_recibo: tipo_recibo,
          periodo: anio + '-' + mes + '-01'
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        success: function success(response) {
          if (response.trim() != '') {
            controller.set(id_iframe, constant.BASEURL + response);
          }
        }
      });
    },
    cargarApoyos: function cargarApoyos(tipo_evidencia, wrapper, controller) {
      var div = $('#' + wrapper).addClass('loading').text('Cargando...');
      $.ajax({
        url: constant.APIURL + 'nom037/administrador/detalle_apoyo',
        type: "post",
        data: {
          usuario_k: controller.get("usuario_k"),
          tipo_evidencia: tipo_evidencia,
          vista: 'usuario'
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        success: function success(html) {
          div.html(html).removeClass('loading');
        }
      });
    },
    actions: {
      obtenerevidencias: function obtenerevidencias() {
        var self = this;
        $("#loading").show();
        var id_colaborador_direccion_k = $("#cb_direcciones").val().trim();
        self.controller.set('direccion_selected', $("#cb_direcciones option:selected").text());

        if (id_colaborador_direccion_k != "") {
          /* Cargamos evidencias del usuario */
          self.controller.set('id_colaborador_direccion_k', id_colaborador_direccion_k);
          self.cargarlistaevidencias(self.controller, id_colaborador_direccion_k);
          /* Cargamos el Recibo de Luz */

          self.cargar_recibo(self.controller, 'luz', self.controller.get('anio_actual'), self.controller.get('mes_actual'), id_colaborador_direccion_k, 'url_recibo-luz');
          /* Cargamos el Recibo de Internet */

          self.cargar_recibo(self.controller, 'internet', self.controller.get('anio_actual'), self.controller.get('mes_actual'), id_colaborador_direccion_k, 'url_recibo-internet');
        } else {
          window.showModal({
            titulo: 'Evidencias.',
            mensaje: 'Selecciona un domicilio para mostrar las evidencias.'
          });
        }
      },
      obtenerrecibo: function obtenerrecibo(tipo_recibo) {
        var self = this,
            iframe = tipo_recibo == 'luz' ? 'url_recibo-luz' : 'url_recibo-internet',
            anio = '',
            mes = '';

        if (tipo_recibo == 'luz') {
          anio = $("#recibo_periodo_anio_luz").val().trim(), mes = $("#recibo_periodo_mes_luz").val().trim();
        } else {
          anio = $("#recibo_periodo_anio_internet").val().trim(), mes = $("#recibo_periodo_mes_internet").val().trim();
        }

        self.cargar_recibo(self.controller, tipo_recibo, anio, mes, self.controller.get('id_colaborador_direccion_k'), iframe);
      },
      cerrar_galeria: function cerrar_galeria() {
        /* Mostrar direcciones */
        $("#warpper-imagenes").addClass("d-none");
        $("#warpper-direcciones").removeClass("d-none");
      }
    }
  });

  _exports.default = _default;
});