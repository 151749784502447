define("comunidades-nafin/templates/components/nom-035-stps/reportes/charts/simple-pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NYpryGPN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n<div class=\\\"card\\\">\\n    <div class=\\\"card-header\\\" id=\\\"headingOne\\\">\\n        <h2 class=\\\"mb-0\\\">\\n            <button class=\\\"btn btn-link\\\" type=\\\"button\\\"\\n                    data-toggle=\\\"collapse\\\" data-target=\\\"#collapseOne\\\" aria-expanded=\\\"true\\\" aria-controls=\\\"collapseOne\\\">\\n                {{title}}\\n            </button>\\n        </h2>\\n    </div>\\n    <div id=\\\"collapseOne\\\" class=\\\"collapse show\\\" aria-labelledby=\\\"headingOne\\\" data-parent=\\\"#accordionExample\\\">\\n        <div class=\\\"card-body\\\">\\n            <div id=\\\"{{chartID}}\\\" style=\\\"width: 100%; height: 500px;\\\"></div>\\n        </div>\\n    </div>\\n</div>\\n\"],[0,\"\\n\"],[6,\"div\"],[11,\"id\",[27,[[20,\"chartID\"]]]],[10,\"style\",\"width: 100%; height: 500px;\"],[8],[9],[0,\"\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/nom-035-stps/reportes/charts/simple-pie-chart.hbs"
    }
  });

  _exports.default = _default;
});