define("comunidades-nafin/routes/nom-035-stps/acontecimientos-traumaticos-severos/administrador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      console.log('window.constant.BAND_RELOAD 00: ', window.constant.BAND_RELOAD);

      if (!window.constant.BAND_RELOAD) {
        $("body").html("");
        window.location.reload();
      }
    },
    setupController: function setupController(controller) {
      console.log('window.constant.BAND_RELOAD 11: ', window.constant.BAND_RELOAD);
      setTimeout(function () {
        $('#loading').fadeOut('slow');
      }, 2000);
    }
  });

  _exports.default = _default;
});