define("comunidades-nafin/components/nom-035-stps/reportes/reports/audiencia-resumen-por-trabajador-reporte", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    title: 'Estatus de los cuestionarios por trabajador',
    clase: 'nom035stps/reporteadministrador',
    metodo: 'audienciaResumenPorTrabajador',
    reporte: 'nom035stps/export/audienciaReporteResumenPorTrabajador',
    periodo_k: null,
    headers: [{
      dataIndex: 'datos_trabajador',
      title: 'Datos de Trabajador'
    }, {
      dataIndex: 'centro_trabajo',
      title: 'Centro de Trabajo'
    }, {
      dataIndex: 'estatus',
      title: 'Estatus'
    }, {
      dataIndex: 'url_reporte_pdf',
      title: 'Reporte de respuestas'
    }],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('params', {
        'periodo_k': this.periodo_k
      });
      this.set('params', {
        'periodo_k': this.periodo_k
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      setTimeout(function () {
        $('#tab_resumen').dataTable({
          retrieve: true,
          language: {
            "processing": "Procesando...",
            "lengthMenu": "Mostrar _MENU_ registros",
            "zeroRecords": "No se encontraron resultados",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "search": "Buscar:",
            "infoThousands": ",",
            "loadingRecords": "Cargando...",
            "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
          }
          /*
          initComplete: function () {
              this.api()
                  .columns()
                  .every(function () {
                      let column = this;
                                    // Create select element
                      let select = document.createElement('select');
                      select.add(new Option(''));
                      column.footer().replaceChildren(select);
                                    // Apply listener for user change in value
                      select.addEventListener('change', function () {
                          column
                              .search(select.value, {exact: true})
                              .draw();
                      });
                                    // Add list of options
                      column
                          .data()
                          .unique()
                          .sort()
                          .each(function (d, j) {
                              select.add(new Option(d));
                          });
                  });
          }
          */

        });
      }, 2000);
    },
    setParamsCentroTrabajoK: function setParamsCentroTrabajoK(centro_trabajo_k) {
      this.params.centro_trabajo_k = centro_trabajo_k;
    },
    setParamsEstatus: function setParamsEstatus(estatus) {
      this.params.estatus = estatus;
    },
    setParamsUsuario: function setParamsUsuario(usuario) {
      this.params.nombre_usuario = usuario;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.loadComboData();
    },
    loadComboData: function loadComboData() {
      var self = this;
      /*
       * Workaround. cargamos dependencias de combo
       */

      $.ajax({
        url: constant.APIURL + 'nom035stps/reporteadministrador/listarcentrosdetrabajo',
        type: "post",
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            self.set('inputGroupSelectCentrosDeTrabajoData', response.data);
            self.inputGroupSelectCentrosDeTrabajoData = response.data;
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    actions: {
      filterAndReload: function filterAndReload() {
        this.setParamsCentroTrabajoK($('#inputGroupSelectCentroTrabajoK').val());
        this.setParamsEstatus($('#inputGroupSelectEstatus').val());
        this.setParamsUsuario($('#inputGroupTextUsuario').val());
        this.loadData();
      }
    }
  });

  _exports.default = _default;
});