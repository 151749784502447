define("comunidades-nafin/templates/components/human-talent-components/charts/clustered-column-chart-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zX9p8A4G",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n<div class=\\\"card\\\">\\n\\n    <div class=\\\"card-header\\\">\\n        <div align=\\\"left\\\">Distribución</div>\\n    </div>\\n\\n    <div id=\\\"collapseOne\\\" class=\\\"collapse show\\\" aria-labelledby=\\\"headingOne\\\" data-parent=\\\"#accordionExample\\\">\\n        <div class=\\\"card-body\\\" >\\n            <div id=\\\"{{chartID}}\\\" class=\\\"grafica-colaboradores\\\" style=\\\"width: 100%; height: 350px;\\\"></div>\\n        </div>\\n    </div>\\n\\n</div>\\n\"],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"d-flex justify-between items-center py-20 px-30 border-bottom-light\"],[8],[0,\"\\n    \"],[6,\"h2\"],[10,\"class\",\"text-17 lh-1 fw-900\"],[8],[0,\"Distribución\"],[9],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"text-14\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"py-40 px-30\"],[8],[0,\"\\n    \"],[6,\"div\"],[11,\"id\",[27,[[20,\"chartID\"]]]],[10,\"class\",\"grafica-colaboradores\"],[10,\"style\",\"width: 100%; height: 350px;\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/human-talent-components/charts/clustered-column-chart-horizontal.hbs"
    }
  });

  _exports.default = _default;
});