define("comunidades-nafin/routes/nom-037/mis-evidencias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    _controller: null,
    currentDomicilio: null,
    setupController: function setupController(controller) {
      var self = this;
      self._controller = controller;
      self.loadData(controller);
      setTimeout(function () {
        $("#loading").fadeOut(1500);
        controller.set("direcciones", []);
        controller.set("colaborador_header", window.datosPersonales.nombre);
        controller.set("domicilio_visita", "");
        controller.set("id_colaborador_direccion_k", "");
        controller.set("url_google_maps", "");
        controller.set("domicilio", "");
        controller.set("anios", []);
        controller.set("anio_actual", "");
        controller.set("mes_actual", "");
        self.loadData(controller);
      }, 1000);
    },
    loadData: function loadData(controller) {
      var self = this;
      /* Carga de Domicilios */

      self.carga_domicilios(controller);
      /* Listado de años */

      self.obtiene_anios(controller);
    },
    carga_domicilios: function carga_domicilios(controller) {
      $.ajax({
        url: constant.APIURL + "nom037/administrador/direccionesUsuario",
        type: "post",
        data: {
          usuario_k: window.datosPersonales.usuario_k
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            controller.set("direcciones", response.data);
          }
        }
      });
    },
    obtiene_anios: function obtiene_anios(controller) {
      var fecha_actual = new Date(),
          anio_actual = fecha_actual.getFullYear(),
          anio_anterior = anio_actual - 1,
          anios = [];
      anios.push(anio_actual);
      anios.push(anio_anterior);
      controller.set("anios", anios);
      controller.set("anio_actual", anio_actual);
      controller.set("mes_actual", fecha_actual.getMonth() + 1);
    },
    getPeriodo: function getPeriodo() {
      var fecha = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var datos_fecha = {
        anio: null,
        mes: null
      };

      if (fecha != '') {
        var datos = fecha.split("-");
        datos_fecha.anio = datos[0];
        datos_fecha.mes = datos[1];
      }

      return datos_fecha;
    },
    actions: {
      loadMap: function loadMap() {
        $("#table-direcciones").addClass("d-none");
        $("#mapa").removeClass("d-none");
        var mapOptions,
            map,
            marker,
            searchBox,
            city,
            infoWindow = '',
            addressEl = document.querySelector('#map-search'),
            latEl = document.querySelector('.latitude'),
            longEl = document.querySelector('.longitude'),
            element = document.getElementById('map-canvas');
        city = document.querySelector('.reg-input-city');
        mapOptions = {
          // How far the maps zooms in.
          zoom: 9,
          // Current Lat and Long position of the pin/
          center: new google.maps.LatLng(19.4136491, -99.1033026),
          // center : {
          // 	lat: -34.397,
          // 	lng: 150.644
          // },
          disableDefaultUI: false,
          // Disables the controls like zoom control on the map if set to true
          scrollWheel: true,
          // If set to false disables the scrolling on the map.
          draggable: true // If set to false , you cannot move the map around.
          // mapTypeId: google.maps.MapTypeId.HYBRID, // If set to HYBRID its between sat and ROADMAP, Can be set to SATELLITE as well.
          // maxZoom: 11, // Wont allow you to zoom more than this
          // minZoom: 9  // Wont allow you to go more up.

        };
        /**
         * Creates the map using google function google.maps.Map() by passing the id of canvas and
         * mapOptions object that we just created above as its parameters.
         *
         */
        // Create an object map with the constructor function Map()

        map = new google.maps.Map(element, mapOptions); // Till this like of code it loads up the map.

        /**
         * Creates the marker on the map
         *
         */

        marker = new google.maps.Marker({
          position: mapOptions.center,
          map: map,
          // icon: 'http://pngimages.net/sites/default/files/google-maps-png-image-70164.png',
          draggable: true
        });
        /**
         * Creates a search box
         */

        searchBox = new google.maps.places.SearchBox(addressEl);
        /**
         * When the place is changed on search box, it takes the marker to the searched location.
         */

        google.maps.event.addListener(searchBox, 'places_changed', function () {
          var places = searchBox.getPlaces(),
              bounds = new google.maps.LatLngBounds(),
              i,
              place,
              lat,
              long,
              resultArray,
              addresss = places[0].formatted_address;

          for (i = 0; place = places[i]; i++) {
            bounds.extend(place.geometry.location);
            marker.setPosition(place.geometry.location); // Set marker position new.
          }

          map.fitBounds(bounds); // Fit to the bound

          map.setZoom(15); // This function sets the zoom to 15, meaning zooms to level 15.
          // console.log( map.getZoom() );

          lat = marker.getPosition().lat();
          long = marker.getPosition().lng();
          latEl.value = lat;
          longEl.value = long;
          resultArray = places[0].address_components; // Get the city and set the city input value to the one selected

          for (var i = 0; i < resultArray.length; i++) {
            if (resultArray[i].types[0] && 'administrative_area_level_2' === resultArray[i].types[0]) {
              citi = resultArray[i].long_name;
              city.value = citi;
            }
          } // Closes the previous info window if it already exists


          if (infoWindow) {
            infoWindow.close();
          }
          /**
           * Creates the info Window at the top of the marker
           */


          infoWindow = new google.maps.InfoWindow({
            content: addresss
          });
          infoWindow.open(map, marker);
        });
        /**
         * Finds the new position of the marker when the marker is dragged.
         */

        google.maps.event.addListener(marker, "dragend", function (event) {
          var lat, long, address, resultArray, citi;
          console.log('i am dragged');
          lat = marker.getPosition().lat();
          long = marker.getPosition().lng();
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({
            latLng: marker.getPosition()
          }, function (result, status) {
            if ('OK' === status) {
              // This line can also be written like if ( status == google.maps.GeocoderStatus.OK ) {
              address = result[0].formatted_address;
              resultArray = result[0].address_components; // Get the city and set the city input value to the one selected

              for (var i = 0; i < resultArray.length; i++) {
                if (resultArray[i].types[0] && 'administrative_area_level_2' === resultArray[i].types[0]) {
                  citi = resultArray[i].long_name;
                  console.log(citi);
                  city.value = citi;
                }
              }

              addressEl.value = address;
              latEl.value = lat;
              longEl.value = long;
            } else {
              console.log('Geocode was not successful for the following reason: ' + status);
            } // Closes the previous info window if it already exists


            if (infoWindow) {
              infoWindow.close();
            }
            /**
             * Creates the info Window at the top of the marker
             */


            infoWindow = new google.maps.InfoWindow({
              content: address
            });
            infoWindow.open(map, marker);
          });
        });
      },
      cancelNewAddress: function cancelNewAddress() {
        $("#table-direcciones").removeClass("d-none");
        $("#mapa").addClass("d-none");
        $("#map-search").val('');
        $("#mapa #longitude").val('');
        $("#mapa #latitude").val('');
      },
      saveAddress: function saveAddress() {
        var self = this;
        var data = {
          "usuario_k": window.datosPersonales.usuario_k,
          "domicilio": $("#map-search").val(),
          "longitud": $("#mapa #longitude").val(),
          "latitud": $("#mapa #latitude").val(),
          "url_google_maps": "https://www.google.com.mx/maps/place/" + $("#map-search").val() + "/@" + $("#mapa #longitude").val() + "," + $("#mapa #latitude").val()
        };
        $.ajax({
          url: constant.APIURL + "nom037/administrador/guardarDireccion",
          type: "post",
          data: data,
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              $("#map-search").val('');
              $("#mapa #longitude").val('');
              $("#mapa #latitude").val('');

              self._controller.set("direcciones", response.data);

              self.send("cancelNewAddress");
            }
          }
        });
      },
      uploadEvidencesInternet: function uploadEvidencesInternet(d, tipo) {
        var self = this;
        $("#recibos_modal").modal("show");
        $("#recibos_modal").on("hidden.bs.modal", function () {
          /* self.controller.currentDomicilio.click(); */
        });
        $("#recibos_modal").data("id_colaborador_direccion_k", d.id_colaborador_direccion_k);
        $("#recibos_modal").data("tipo", tipo); // En caso de que se tenga el periodo, se coloca en los select

        var datos_fecha_luz = self.getPeriodo(d.periodo_luz),
            datos_fecha_internet = self.getPeriodo(d.periodo_internet);
        $("#recibo_periodo_mes").val("");
        var urlRecibo = false;

        if (tipo == "luz") {
          urlRecibo = d.documento_luz != null ? d.documento_luz : false;

          if (d.periodo_luz != '') {
            $("#recibo_periodo_mes").val(datos_fecha_luz.mes);
            $("#recibo_periodo_anio").val(datos_fecha_luz.anio);
          }
        } else {
          urlRecibo = d.documento_internet != null ? d.documento_internet : false;

          if (d.periodo_internet != '') {
            $("#recibo_periodo_mes").val(datos_fecha_internet.mes);
            $("#recibo_periodo_anio").val(datos_fecha_internet.anio);
          }
        }

        if (urlRecibo) $("#ifr-recibo").attr("src", constant.BASEURL + urlRecibo);else $("#ifr-recibo").attr("src", "");
        $("#recibo_periodo_mes").change(function () {
          console.log('cambio');
          var recibo_periodo_mes = $(this).val(),
              recibo_periodo_anio = $("#recibo_periodo_anio").val();
          $("#wrap_evidencias").addClass("d-none");

          if (recibo_periodo_mes != '' && recibo_periodo_anio != '') {
            $("#wrap_evidencias").removeClass("d-none");
          }
        });
        $("#recibo_periodo_anio").change(function () {
          var recibo_periodo_anio = $(this).val(),
              recibo_periodo_mes = $("#recibo_periodo_mes").val();
          $("#wrap_evidencias").addClass("d-none");

          if (recibo_periodo_mes != '' && recibo_periodo_anio != '') {
            $("#wrap_evidencias").removeClass("d-none");
          }
        });
        return false;
      },
      uploadEvidences: function uploadEvidences(d) {
        var self = this;
        $("#loading").fadeIn(500);
        $("#evidencias_modal").data("id_colaborador_direccion_k", d.id_colaborador_direccion_k);
        $.ajax({
          url: constant.APIURL + "nom037/administrador/listarEvidencias",
          type: "post",
          data: {
            id_colaborador_direccion_k: d.id_colaborador_direccion_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              localStorage.setItem("prevEvidences", JSON.stringify(response.data));
              $("#evidencias_modal").modal("show");
              $("#evidencias_modal").on("hidden.bs.modal", function () {
                /* self.controller.currentDomicilio.click(); */
              });
            }
          }
        });
        return false;
      }
    }
  });

  _exports.default = _default;
});