define("comunidades-nafin/components/nom-035-stps/reportes/reports/audiencia-distribucion-cuestionarios-reporte", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    title: 'Distribución de los cuestionarios por centro de trabajo',
    clase: 'nom035stps/reporteadministrador',
    metodo: 'audienciaresumenporcentrodetrabajo',
    reporte: 'nom035stps/export/distribucioncuestionariosporcentrotrabajo',
    periodo_k: null,
    headers: [{
      dataIndex: 'centro_trabajo',
      title: window.translate('nom_035_stps.reportes_distribucion_de_guias.tlt.tlt_table_audiencia_cuestionarios_header_centro_trabajo.valor', 'Centro de Trabajo')
    }, {
      dataIndex: 'total_colaboradores',
      title: window.translate('nom_035_stps.reportes_distribucion_de_guias.tlt.tlt_table_audiencia_cuestionarios_header_total_colaboradores.valor', 'Trabajadores')
    }, {
      dataIndex: 'evaluaciones_aplicadas',
      title: window.translate('nom_035_stps.reportes_distribucion_de_guias.tlt.tlt_table_audiencia_cuestionarios_header_evaluaciones_aplicadas.valor', 'Cuestionarios enviados')
    }],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('params', {
        'periodo_k': this.periodo_k
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      setTimeout(function () {
        $('#tab_audiencia').dataTable({
          retrieve: true,
          language: {
            "processing": "Procesando...",
            "lengthMenu": "Mostrar _MENU_ registros",
            "zeroRecords": "No se encontraron resultados",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "search": "Buscar:",
            "infoThousands": ",",
            "loadingRecords": "Cargando...",
            "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
          }
        });
      }, 2000);
    }
  });

  _exports.default = _default;
});