define("comunidades-nafin/templates/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/distribucion-guias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "19w26Oa5",
    "block": "{\"symbols\":[],\"statements\":[[2,\" \\n<div class=\\\"row\\\" style=\\\"height: auto !important;\\\">\\n    <div class=\\\"col-12\\\">\\n        <div class=\\\"alert alert-info\\\" role=\\\"alert\\\">\\n            {{translate 'nom_035_stps.carpeta_evidencias.msg.msg_descripcion_distribucion_guias.valor' 'En esta sección podrá consultar la distribución de las guías.'}}\\n        </div>\\n    </div>\\n</div>\\n\\n<div class=\\\"accordion mt-2\\\">\\n    {{nom-035-stps/reportes/reports/audiencia-distribucion-cuestionarios-reporte\\n        periodo_k=periodo_k\\n    }}\\n</div>\\n\"],[0,\"\\n\\n\"],[2,\"\\n<div class=\\\"row mb-30\\\">\\n    <div class=\\\"col\\\">\\n        <p>{{translate 'nom_035_stps.carpeta_evidencias.msg.msg_descripcion_distribucion_guias.valor' 'En esta sección podrá consultar la distribución de las guías.'}}</p>\\n    </div>\\n</div>\\n\"],[0,\"\\n\\n\"],[1,[26,\"nom-035-stps/reportes/reports/audiencia-distribucion-cuestionarios-reporte\",null,[[\"periodo_k\"],[[22,[\"periodo_k\"]]]]],false],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/distribucion-guias.hbs"
    }
  });

  _exports.default = _default;
});