define("comunidades-nafin/routes/nom-037/evidencias/lista-de-asistencia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    searchValue: '',
    isEditing: false,
    isSearching: false,
    isLoading: false,
    deleteStore: null,
    isVisibleDeleteForm: false,
    createDeleteCommand: 'create',
    isVisibleCreateUpdateForm: false,
    tab_lista_asistencia: null,
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var self = this;
      self._controller = controller;
      controller.set('isLoading', this.isSearching);
      controller.set('archivo_a_borrar', '');
      controller.set('documentacion_k', 0);
      controller.set('historial', []);
      $(".sidebar__item").removeClass("-is-active -dark-bg-dark-2");
      $("#wrap_lista_asistencia").addClass("-is-active -dark-bg-dark-2");
      /* Mostramos menu dashboard en caso de movil 
      if (window.innerWidth <= 768) 
      {
          $('#btn_menu_dashboard').show();
      }
      else
      {
          $('#btn_menu_dashboard').css( 'cssText', 'display: none !important;' );
      }
      */

      setTimeout(function () {
        self.carga_de_asistencias(controller);
      }, 1000); // Obtenemos la URL del sitio actual

      var url_actual = window.location.href;
      var url_reg = url_actual.split("/");
      var nombre_page = url_reg[url_reg.length - 2];
      setTimeout(function () {
        $(".sidebar__item").removeClass("-is-active -dark-bg-dark-2");

        if (nombre_page === 'lista-de-asistencia') {
          $("#wrap_lista_asistencia").addClass("-is-active -dark-bg-dark-2");
        }
      }, 500);
    },
    carga_de_asistencias: function carga_de_asistencias(controller) {
      var self = this;

      if (self.tab_lista_asistencia !== null) {
        self.tab_lista_asistencia.clear().draw();
        self.tab_lista_asistencia.destroy();
      }

      controller.set("historial", []);
      $.ajax({
        url: constant.APIURL + "nom037/listadeasistencia/historialAsistencia",
        type: "post",
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        async: false,
        success: function success(response) {
          if (response.success == true) {
            controller.set("historial", response.data);
            setTimeout(function () {
              self.tab_lista_asistencia = $("#tab_asistencia").DataTable({
                language: {
                  "decimal": "",
                  "emptyTable": "Sin registros",
                  "info": "Mostrando del _START_ al  _END_ de _TOTAL_ Registros",
                  "infoEmpty": "Mosrando 0 to 0 de 0 registros",
                  "infoFiltered": "(Filtro _MAX_ registros totales)",
                  "infoPostFix": "",
                  "thousands": ",",
                  "lengthMenu": "Mostrar _MENU_ registros",
                  "loadingRecords": "Cargando...",
                  "processing": "",
                  "search": "Buscar:",
                  "zeroRecords": "Sin coincidencias",
                  "paginate": {
                    "first": "Primero",
                    "last": "Último",
                    "next": "Siguiente",
                    "previous": "Anterior"
                  },
                  "aria": {
                    "sortAscending": ": activate to sort column ascending",
                    "sortDescending": ": activate to sort column descending"
                  }
                },
                "order": []
                /*
                info: false,
                paging: false,
                scrollCollapse: true,
                scrollY: '400px',
                ordering: false
                */

              });
            }, 300);
          }
        }
      });
    },
    actions: {
      showCreateModal: function showCreateModal() {
        this.controller.set('updateStore', this.store.createRecord('nom-035-stps/acciones-internas/evidencia', {}));
        this.controller.set('createDeleteCommand', 'create');
        this.controller.set('isVisibleCreateUpdateForm', true);
        this.controller.set('isCommandUpdate', false);
        /**
         * [YURAM] HACK Begin
         *
         * Los cambios aplicados a continuación fueron aplicados a causa de una incompatibilidad entre componentes y elementos
         *
         */

        $('#button-save-changes').attr("disabled", "disabled");
        $("#planDeTrabajoNombreDocumento").val('');
        $("#planDeTrabajoRutaDocumento").val('');
        $("#fechaAsistencia").val('');
        $("#inputDescripcion").val('');
        $('#filesattached').html('');
        $('#createUpdateFormWorkPlan').modal({});
        $('#dtpicker').datetimepicker({
          locale: 'es',
          format: 'YYYY-MM-DD',
          ignoreReadonly: true //inline: true,
          // sideBySide: true

        });
      },
      afterCreate: function afterCreate(dataObject) {
        this.carga_de_asistencias(this._controller);
      },
      afterUpdate: function afterUpdate(dataObject) {},
      eliminarLista: function eliminarLista() {
        var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        this.controller.set('archivo_a_borrar', params.nombre_documento);
        this.controller.set('documentacion_k', params.documentacion_k);
        $("#confirm_apoyo_modal").modal("show");
      },
      confirmBorrarLista: function confirmBorrarLista() {
        var self = this,
            documentacion_k = self.controller.get('documentacion_k');
        $.ajax({
          url: constant.APIURL + "nom037/listadeasistencia/borrarLista",
          type: "post",
          data: {
            documentacion_k: documentacion_k
          },
          async: false,
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          success: function success(html) {
            self.carga_de_asistencias(self._controller);
            $('#confirm_apoyo_modal').modal('hide');
            $('#modal-avisos .modal-header').text('Registro');
            $('#modal-avisos .modal-body').text('Registro eliminado correctamente.');
            $('#modal-avisos').modal('show');
          }
        });
      }
    }
  });

  _exports.default = _default;
});