define("comunidades-nafin/routes/nom-035-stps/reportes/administrador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      /*
      let promise = new RSVP.Promise(function(resolve) {
      later(this, function() {
      resolve("ok");
      }, 2000);
      });
      return promise;
      */
      if (!window.constant.BAND_RELOAD) {
        Ember.$("body").html("");
        window.location.reload();
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.$.ajax({
        url: constant.APIURL + 'nom035stps/reporteadministrador/obtenerInformacionCardsPrincipal',
        type: "post",
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            controller.set('data', response.data);
            console.dir(response.data);
          }

          Ember.$('#loading').fadeOut('slow');
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    actions: {
      go: function go() {
        return false;
      },
      goAudiencia: function goAudiencia() {
        // document.location.href = "#/nom-035-stps/reportes/administrador/audiencia";
        // document.location.reload();
        // location.href = window.location.origin + "/#/nom-035-stps/reportes/administrador/audiencia";
        window.location.replace(window.location.origin + "/#/nom-035-stps/reportes/administrador/audiencia"); // this.get('router').transitionTo(window.location.origin + "/nom-035-stps/reportes/administrador/audiencia");

        window.location.reload();
      },
      goAcontecimientosTraumaticos: function goAcontecimientosTraumaticos() {
        // document.location.href = "#/nom-035-stps/reportes/administrador/acontecimientos-traumaticos-severos";
        // document.location.reload();
        // location.href = window.location.origin + "#/nom-035-stps/reportes/administrador/acontecimientos-traumaticos-severos";
        window.location.replace(window.location.origin + "#/nom-035-stps/reportes/administrador/acontecimientos-traumaticos-severos"); // this.get('router').transitionTo(window.location.origin + "/nom-035-stps/reportes/administrador/acontecimientos-traumaticos-severos");

        window.location.reload();
      },
      goFactoresRiesgo: function goFactoresRiesgo() {
        document.location.href = "#/nom-035-stps/reportes/administrador/factores-riesgo-psicosocial";
        document.location.reload();
      },
      goEntornoOrganizacional: function goEntornoOrganizacional() {
        document.location.href = "#/nom-035-stps/reportes/administrador/entorno-organizacional";
        document.location.reload();
      }
    }
  });

  _exports.default = _default;
});