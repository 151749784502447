define("comunidades-nafin/routes/cursos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    offset: 0,
    comunidad_k: "",
    dataPost: "",
    beforeModel: function beforeModel() {
      /*
      let promise = new RSVP.Promise(function (resolve) {
      	later(this, function () {
      		resolve("ok");
      	}, 0);
      });
      return promise;
      */
      if (!window.constant.BAND_RELOAD) {
        Ember.$("body").html("");
        window.location.reload();
      }
    },
    setupController: function setupController(controller, model) {
      controller.set("comunidad_k", localStorage.getItem("comunidad_k") != null && localStorage.getItem("comunidad_k") != undefined ? localStorage.getItem("comunidad_k") : "");
      controller.set("comunidad_nombre", localStorage.getItem("comunidad_nombre") != null && localStorage.getItem("comunidad_nombre") != undefined ? localStorage.getItem("comunidad_nombre") : "");

      if (controller.get("comunidad_k") != "") {
        controller.set("dataPost", ',"comunidad_k":' + controller.comunidad_k);
      }

      setTimeout(function () {
        controller.set("totalCursos", localStorage.getItem("totalCursos"));
        Ember.$(".text-cursos").addClass("selected-option-menu");
        Ember.$('#searchInputCursos').on('keyup', function () {
          if (Ember.$('#searchInputCursos').val().length == 0) {
            Ember.$('.dropdown > button').text('Selecciona una opción');
            controller.send('search');
          }
        });
      }, 2000);
    },
    drawStars: function drawStars(starts) {
      var html = ''; //curso[idx].ranking_raprendizaje

      if (starts > 0.999 && starts < 1.2999) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 1.2999 && starts < 1.699) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star-half-alt"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 1.699 && starts < 2.299) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 2.299 && starts < 2.699) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star-half-alt"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 2.699 && starts < 3.299) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 3.299 && starts < 3.699) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star-half-alt"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 3.699 && starts < 4.299) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>';
      } else if (starts > 4.299 && starts < 4.699) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star-half-alt"></i></li>';
      } else if (starts > 4.699) {
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
        html += '<li class="horizontal-list"><i class="fas fa-star"></i></li>';
      } else {
        /*
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>'
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>'
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>'
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>'
        html += '<li class="horizontal-list"><i class="far fa-star"></i></li>'
        */
        html += '<div class="icon-star text-9"></div>';
        html += '<div class="icon-star text-9"></div>';
        html += '<div class="icon-star text-9"></div>';
        html += '<div class="icon-star text-9"></div>';
        html += '<div class="icon-star text-9"></div>';
      }

      return html;
    },
    round: function round(number) {
      var result = 0;

      if (number > 0.999 && number < 1.2999) {
        result = 1;
      } else if (number > 1.2999 && number < 1.699) {
        result = 1.5;
      } else if (number > 1.699 && number < 2.299) {
        result = 2;
      } else if (number > 2.299 && number < 2.699) {
        result = 2.5;
      } else if (number > 2.699 && number < 3.299) {
        result = 3;
      } else if (number > 3.299 && number < 3.699) {
        result = 3.5;
      } else if (number > 3.699 && number < 4.299) {
        result = 4;
      } else if (number > 4.299 && number < 4.699) {
        result = 4.5;
      } else if (number > 4.699) {
        result = 5;
      }

      return result;
    },
    actions: {
      go: function go() {
        return false;
      },
      goBack: function goBack() {
        document.location.href = '/#/cursos';
      },
      regresarComunidades: function regresarComunidades() {
        window.location.href = localStorage.getItem("redirect");
        ; //window.location.href.split("#")[0] + window.constant.APPURL;
      },
      eliminarBusqueda: function eliminarBusqueda() {
        Ember.$('#searchInputCursos').val('');
        var self = this;
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarMisInscripcionesRa',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'params= {"agrupar":"agrupar_por_cursos","nombre":""' + controller.get("dataPost") + '}',
          success: function success(response) {
            var r = JSON.parse(response);
            r.data = r.data.sort(function (a, b) {
              return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
            });
            var curso = r.data;
            var html = '';
            var contador = 0;
            html += '<div class="row">';
            Ember.$.ajax({
              url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
              contentType: 'application/x-www-form-urlencoded',
              xhrFields: {
                withCredentials: true
              },
              type: 'POST',
              data: {
                "method": "score_courses_resume"
              },
              success: function success(response_text) {
                var score_courses_resume = JSON.parse(response_text);

                if (r.data != undefined) {
                  r.data.forEach(function (item, idx) {
                    try {
                      if (score_courses_resume.data) {
                        if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined') {
                          r.data[idx].ranking_raprendizaje = score_courses_resume.data[r.data[idx].ra_k].rate;
                        } else {
                          r.data[idx].ranking_raprendizaje = 0;
                        }
                      } else {
                        r.data[idx].ranking_raprendizaje = 0;
                      }
                    } catch (e) {
                      console.log(e);
                    }
                  });
                  Object.keys(curso).forEach(function (idx) {
                    /*
                     * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                     */
                    curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);
                    html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">';
                    html += '<div class="card text-white text-center">';
                    html += '<a class="white_link" href="#/cursos/' + curso[idx].ra_k + '">';
                    html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                    html += '<div class="icon_approved">';

                    if (curso[idx].estatus === "8") {
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                    }

                    html += '</div>';
                    html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                    html += '<div class="row text-center">';
                    html += '<div class="col-12">';
                    html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul>';
                    html += '</div>';
                    html += '</div>';
                    html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                    html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                    html += '</div>';
                    html += '<div class="icon_approved">';
                    html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    html += '<div class="row">';
                    html += '<div class="col-10">';
                    html += '<div class="progress margin_top_5">';
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                    html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="col-2">';
                    html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                  });
                  html += '<div class="col-12"><center><hr/></center></div>';
                  Ember.$('#searchContainer').html(html);
                }
              }
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      search: function search() {
        var parametro = Ember.$('#searchInputCursos').val();
        var self = this;
        var controller = self.controller;
        var dataPost = 'params={"agrupar":"agrupar_por_cursos","comunidad_k":' + controller.comunidad_k + '}';

        if (parametro != "") {
          if (controller.get("dataPost") != undefined) var dataPost = 'params= {"agrupar":"agrupar_por_cursos","nombre":"' + parametro + '"' + controller.get("dataPost") + '}';else var dataPost = 'params= {"agrupar":"agrupar_por_cursos","nombre":"' + parametro + '"}';
        }

        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarMisInscripcionesRa',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: dataPost,
          success: function success(response) {
            var r = JSON.parse(response);
            r.data = r.data.sort(function (a, b) {
              return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
            });
            var curso = r.data;
            var html = '';
            var contador = 0;
            var detalleRutas = [];
            localStorage.setItem("detalleRutas", JSON.stringify(detalleRutas));
            html += '<div class="col-12 hidden-standalone"><center><h2>Resultados de Busqueda<h2></center></div><div class="row">';
            /*
             *
             *t
             *
             */

            Ember.$.ajax({
              url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
              contentType: 'application/x-www-form-urlencoded',
              xhrFields: {
                withCredentials: true
              },
              type: 'POST',
              data: {
                "method": "score_courses_resume"
              },
              success: function success(response_text) {
                var score_courses_resume = JSON.parse(response_text);
                r.data.forEach(function (item, idx) {
                  try {
                    if (score_courses_resume.data) {
                      if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined') {
                        r.data[idx].ranking_raprendizaje = score_courses_resume.data[r.data[idx].ra_k].rate;
                      } else {
                        r.data[idx].ranking_raprendizaje = 0;
                      }
                    } else {
                      r.data[idx].ranking_raprendizaje = 0;
                    }
                  } catch (e) {
                    console.log(e);
                  }
                });
                Object.keys(curso).forEach(function (idx) {
                  curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);
                  var nomb = curso[idx].nombre.toString();
                  curso[idx].nombre_completo = nomb;

                  if (curso[idx].nombre.length > 50) {
                    curso[idx].nombre = nomb.substr(0, 47) + "...";
                  }

                  if (curso[idx].nombre != "") {
                    html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10 hidden-standalone">';
                    html += '<div class="card text-white text-center">';
                    html += '<a class="white_link" href="#/cursos/' + curso[idx].ra_k + '">';
                    html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>';
                    html += '<div class="icon_approved">';

                    if (curso[idx].estatus === "8") {
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />';
                    }

                    html += '</div>';
                    html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">';
                    html += '<div class="row text-center">';
                    html += '<div class="col-12">';
                    html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul>';
                    html += '</div>';
                    html += '</div>';
                    html += '<p class="card-text ">' + curso[idx].nombre + '</p>';
                    html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>';
                    html += '</div>';
                    html += '<div class="icon_approved">';
                    html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    html += '<div class="row">';
                    html += '<div class="col-10">';
                    html += '<div class="progress margin_top_5">';
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance;
                    html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="col-2">';
                    html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="col-12 d-none show-standalone my-3" style="border:solid 1px #bbb; border-left:solid 3px ' + curso[idx].color + ' ;background-color: white;">';
                    html += '<a class="" href="#/cursos/' + curso[idx].ra_k + '">';
                    html += '<div class=" row py-1">';
                    html += '<div class="col-4 pt-4" align="center">';
                    html += '<img class=" lazy" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image">';

                    if (curso[idx].estatus === "8") {
                      html += '<div class="curso_completado" style="">';
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" style="" hidden/>';
                      html += '</div>';
                    }

                    html += '</div>';
                    html += '<div class="col-8">';
                    html += '<div class="" style="font-size:0.75rem"><b>';
                    html += curso[idx].nombre_completo;
                    html += '</b></div>';
                    html += '<br>';
                    html += '<div class=" d-none d-md-block " style="font-size:0.75rem">';
                    html += '<div class="curso-nombre">' + curso[idx].descripcion + '</div>';
                    html += '</div>';
                    html += '<div class="row">';
                    html += '<div class="col-9">';
                    html += '<div class="progress margin_top_5 progress-bar-client-theme">';
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style="width:' + curso[idx].porcentaje_avance + '%" aria-valuenow="' + curso[idx].porcentaje_avance + '" aria-valuemin="' + curso[idx].porcentaje_avance + '" aria-valuemax="' + curso[idx].porcentaje_avance + '"></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="col-2">';
                    html += '<p class="number_bar number-bar-client-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div align="center"><ul class="horizontal-list no_padding cursos-stars " align="center" style="font-size:18px">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    var objetoRa = {
                      ra_k: curso[idx].ra_k,
                      imagen: curso[idx].imagen_raprendizaje
                    };
                    detalleRutas.push(objetoRa);
                    localStorage.setItem("detalleRutas", JSON.stringify(detalleRutas));
                  }
                });
                html += '<div class="col-12"><center><hr/></center></div>';
                Ember.$('#searchContainer').html(html);
              }
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      sortProgreso: function sortProgreso() {
        var self = this;
        var controller = self.controller;
        controller.set('comunidad', false);
        controller.set('valorados', false);
        controller.set('recien', false);
        controller.set('progreso', true);
        controller.set('nombre', false);
        Ember.$('.dropdown > button').text('Progreso');
        Ember.$('.dropdown .dropdown-menu a').show();
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarMisInscripcionesRa/',
          type: 'POST',
          data: 'params={"agrupar":"agrupar_por_cursos"' + controller.get("dataPost") + '}',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          success: function success(response) {
            var r = JSON.parse(response);
            var data = r.data;
            var html = '';
            var contador = 0;
            r.data = r.data.sort(function (b, a) {
              return a.porcentaje_avance > b.porcentaje_avance ? 1 : b.porcentaje_avance > a.porcentaje_avance ? -1 : 0;
            });
            var curso = [];
            Object.keys(data).forEach(function (idx) {
              if (data[idx].nombre.length > 50) {
                var nomb = data[idx].nombre.toString();
                data[idx].nombre = nomb.substr(0, 47) + "...";
              }

              curso.push(data[idx]);
            });
            curso.sort(function (a, b) {
              return b.porcentaje_avance - a.porcentaje_avance;
            });
            var cont = 0; // html += '<div class="col-12"><center><h2>Resultados de Busqueda<h2></center></div><div class="row">'

            Ember.$.ajax({
              url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
              contentType: 'application/x-www-form-urlencoded',
              xhrFields: {
                withCredentials: true
              },
              type: 'POST',
              data: {
                "method": "score_courses_resume"
              },
              success: function success(response_text) {
                var score_courses_resume = JSON.parse(response_text);
                r.data.forEach(function (item, idx) {
                  try {
                    if (score_courses_resume.data) {
                      if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined') {
                        r.data[idx].ranking_raprendizaje = score_courses_resume.data[r.data[idx].ra_k].rate;
                      } else {
                        r.data[idx].ranking_raprendizaje = 0;
                      }
                    } else {
                      r.data[idx].ranking_raprendizaje = 0;
                    }
                  } catch (e) {
                    console.log(e);
                  }
                });
                var cursosSeriados = [];
                Object.keys(curso).forEach(function (idx) {
                  if (!cursosSeriados.includes(r.data[idx].ra_k)) {
                    cursosSeriados.push(r.data[idx].ra_k);
                    var next = curso[idx + 1];
                    var prev = curso[idx - 1];
                    cont++;
                    /*
                     * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                     */

                    curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);

                    if (prev !== undefined) {
                      if (curso[idx].porcentaje_avance !== curso[idx - 1].porcentaje_avance) {// html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + parseInt(curso[idx].porcentaje_avance) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>'
                      }
                    } else if (cont === 1) {// html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + parseInt(curso[idx].porcentaje_avance) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>'
                    }

                    html += '<div class="col-xl-3 col-lg-4 col-md-6">';
                    html += '<a href="#/cursos/' + curso[idx].ra_k + '" class="coursesCard -type-1 ">';
                    html += '<div class="relative">';
                    html += '<div class="coursesCard__image overflow-hidden rounded-8">';
                    html += '<img class="w-1/1" src="' + curso[idx].imagen_raprendizaje + '" alt="image">';
                    html += '<div class="coursesCard__image_overlay rounded-8"></div>';
                    html += '</div>';
                    html += '<div class="d-flex justify-between py-10 px-10 absolute-full-center z-3">';
                    html += '<div>';
                    html += '<div class="px-15 rounded-200 ">';
                    html += '<span class="text-11 lh-1 uppercase fw-500 text-white"></span>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div>';
                    html += '<div class="px-15 rounded-200" style="text-align: right">';
                    html += '<span class="text-11 lh-1 uppercase fw-500 text-dark-1">';

                    if (curso[idx].estatus === "8") {
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" hidden style="width:50%" />';
                    }

                    html += '</span>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="h-100 pt-15">';
                    html += '<div class="d-flex items-center">';
                    html += '<div class="text-14 lh-1 text-yellow-1 mr-10"></div>';
                    html += '<div class="d-flex x-gap-5 items-center">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</div>';
                    html += '<div class="text-13 lh-1 ml-10">' + curso[idx].nombre_comunidad + '</div>';
                    html += '</div>';
                    html += '<div class="text-17 lh-15 fw-500 text-dark-1 mt-10">' + curso[idx].nombre + '</div>';
                    html += '<div class="progress-bar mt-10">';
                    html += '<div class="progress-bar__bg bg-light-3"></div>';
                    html += '<div class="progress-bar__bar bg-purple-1" style="width: ' + curso[idx].porcentaje_avance + '%"></div>';
                    html += '</div>';
                    html += '<div class="d-flex y-gap-10 justify-between items-center mt-10">';
                    html += '<div class="text-dark-1">' + parseInt(curso[idx].porcentaje_avance) + '% de avance</div>';
                    html += '<div></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    /*
                    html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">'
                    html += '<div class="card text-white text-center">'
                    html += '<a class="white_link" href="#/cursos/' + curso[idx].ra_k + '">'
                    html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>'
                    html += '<div class="icon_approved">'
                    if (curso[idx].estatus === "8") {
                    	html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />'
                    }
                    html += '</div>'
                    html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">'
                    html += '<div class="row text-center">'
                    html += '<div class="col-12">'
                    html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">'
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul>'
                    html += '</div>'
                    html += '</div>'
                    html += '<p class="card-text ">' + curso[idx].nombre + '</p>'
                    html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>'
                    html += '</div>'
                    html += '<div class="icon_approved">'
                    //html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>'
                    html += '</div>'
                    html += '</a>'
                    html += '</div>'
                    html += '<div class="row">'
                    html += '<div class="col-10">'
                    html += '<div class="progress margin_top_5">'
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance
                    html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>'
                    html += '</div>'
                    html += '</div>'
                    html += '<div class="col-2">'
                    html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>'
                    html += '</div>'
                    html += '</div>'
                    html += '</div>';
                    */
                  }
                });
                html += '<div class="col-12"><center><hr/></center></div>';
                Ember.$('#searchContainer').html(html);
              }
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      sortNombre: function sortNombre() {
        var self = this;
        var controller = self.controller;
        controller.set('comunidad', false);
        controller.set('valorados', false);
        controller.set('recien', false);
        controller.set('progeso', false);
        controller.set('nombre', true);
        Ember.$('.dropdown > button').text('Nombre');
        Ember.$('.dropdown .dropdown-menu a').show();
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarMisInscripcionesRa/',
          data: 'params={"agrupar":"agrupar_por_cursos"' + controller.get("dataPost") + '' + controller.get("dataPost") + '}',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          success: function success(response) {
            var r = JSON.parse(response);
            r.data = r.data.sort(function (a, b) {
              return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
            });
            var data = r.data;
            var html = '';
            var contador = 0;
            var curso = [];
            Object.keys(data).forEach(function (idx) {
              if (data[idx].nombre.length > 50) {
                var nomb = data[idx].nombre.toString();
                data[idx].nombre = nomb.substr(0, 47) + "...";
              }

              curso.push(data[idx]);
            });
            curso.sort(function (a, b) {
              return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
            });
            var contador = 0; // html += '<div class="col-12"><center><h2>Resultados de Búsqueda<h2></center></div><div class="row">'

            Ember.$.ajax({
              url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
              contentType: 'application/x-www-form-urlencoded',
              xhrFields: {
                withCredentials: true
              },
              type: 'POST',
              data: {
                "method": "score_courses_resume"
              },
              success: function success(response_text) {
                var score_courses_resume = JSON.parse(response_text);
                r.data.forEach(function (item, idx) {
                  try {
                    if (score_courses_resume.data) {
                      if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined') {
                        r.data[idx].ranking_raprendizaje = score_courses_resume.data[r.data[idx].ra_k].rate;
                      } else {
                        r.data[idx].ranking_raprendizaje = 0;
                      }
                    } else {
                      r.data[idx].ranking_raprendizaje = 0;
                    }
                  } catch (e) {
                    console.log(e);
                  }
                });
                var cursosSeriados = [];
                Object.keys(curso).forEach(function (idx) {
                  if (!cursosSeriados.includes(r.data[idx].ra_k)) {
                    cursosSeriados.push(r.data[idx].ra_k);
                    /*
                     * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                     */

                    curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);
                    html += '<div class="col-xl-3 col-lg-4 col-md-6">';
                    html += '<a href="#/cursos/' + curso[idx].ra_k + '" class="coursesCard -type-1 ">';
                    html += '<div class="relative">';
                    html += '<div class="coursesCard__image overflow-hidden rounded-8">';
                    html += '<img class="w-1/1" src="' + curso[idx].imagen_raprendizaje + '" alt="image">';
                    html += '<div class="coursesCard__image_overlay rounded-8"></div>';
                    html += '</div>';
                    html += '<div class="d-flex justify-between py-10 px-10 absolute-full-center z-3">';
                    html += '<div>';
                    html += '<div class="px-15 rounded-200 ">';
                    html += '<span class="text-11 lh-1 uppercase fw-500 text-white"></span>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div>';
                    html += '<div class="px-15 rounded-200" style="text-align: right">';
                    html += '<span class="text-11 lh-1 uppercase fw-500 text-dark-1">';

                    if (curso[idx].estatus === "8") {
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" hidden style="width:50%" />';
                    }

                    html += '</span>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="h-100 pt-15">';
                    html += '<div class="d-flex items-center">';
                    html += '<div class="text-14 lh-1 text-yellow-1 mr-10"></div>';
                    html += '<div class="d-flex x-gap-5 items-center">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</div>';
                    html += '<div class="text-13 lh-1 ml-10">' + curso[idx].nombre_comunidad + '</div>';
                    html += '</div>';
                    html += '<div class="text-17 lh-15 fw-500 text-dark-1 mt-10">' + curso[idx].nombre + '</div>';
                    html += '<div class="progress-bar mt-10">';
                    html += '<div class="progress-bar__bg bg-light-3"></div>';
                    html += '<div class="progress-bar__bar bg-purple-1" style="width: ' + curso[idx].porcentaje_avance + '%"></div>';
                    html += '</div>';
                    html += '<div class="d-flex y-gap-10 justify-between items-center mt-10">';
                    html += '<div class="text-dark-1">' + parseInt(curso[idx].porcentaje_avance) + '% de avance</div>';
                    html += '<div></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    /*
                    html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">'
                    html += '<div class="card text-white text-center">'
                    html += '<a class="white_link" href="#/cursos/' + curso[idx].ra_k + '">'
                    html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>'
                    html += '<div class="icon_approved">'
                    if (curso[idx].estatus == "8") {
                    	html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />'
                    }
                    html += '</div>'
                    html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">'
                    html += '<div class="row text-center">'
                    html += '<div class="col-12">'
                    html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">'
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul>'
                    html += '</div>'
                    html += '</div>'
                    html += '<p class="card-text ">' + curso[idx].nombre + '</p>'
                    html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>'
                    html += '</div>'
                    html += '<div class="icon_approved">'
                    //html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>'
                    html += '</div>'
                    html += '</a>'
                    html += '</div>'
                    html += '<div class="row">'
                    html += '<div class="col-10">'
                    html += '<div class="progress margin_top_5">'
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance
                    html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>'
                    html += '</div>'
                    html += '</div>'
                    html += '<div class="col-2">'
                    html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>'
                    html += '</div>'
                    html += '</div>'
                    html += '</div>'
                    */
                  }
                });
                html += '<div class="col-12"><center><hr/></center></div>';
                Ember.$('#searchContainer').html(html);
              }
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      sortRecent: function sortRecent() {
        var self = this;
        var controller = self.controller;
        controller.set('comunidad', false);
        controller.set('valorados', false);
        controller.set('recien', true);
        controller.set('progeso', false);
        controller.set('nombre', false);
        Ember.$('.dropdown > button').text('Recién agregados');
        Ember.$('.dropdown .dropdown-menu a').show();
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarMisInscripcionesRa/',
          type: 'POST',
          data: 'params={"agrupar":"agrupar_por_cursos"' + controller.get("dataPost") + '' + controller.get("dataPost") + '}',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          success: function success(response) {
            var r = JSON.parse(response); //r.data = r.data.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));

            var data = r.data;
            var html = '';
            var contador = 0;
            var curso = [];
            Object.keys(data).forEach(function (idx) {
              if (data[idx].nombre.length > 50) {
                var nomb = data[idx].nombre.toString();
                data[idx].nombre = nomb.substr(0, 47) + "...";
              }

              curso.push(data[idx]);
            });
            curso.sort(function (a, b) {
              return new Date(b.lms_ra_fecha_publicacion).getTime() - new Date(a.lms_ra_fecha_publicacion).getTime();
            });
            var cont = 0; // html += '<div class="col-12"><center><h2>Resultados de Busqueda<h2></center></div><div class="row">'

            Ember.$.ajax({
              url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
              contentType: 'application/x-www-form-urlencoded',
              xhrFields: {
                withCredentials: true
              },
              type: 'POST',
              data: {
                "method": "score_courses_resume"
              },
              success: function success(response_text) {
                var score_courses_resume = JSON.parse(response_text);
                r.data.forEach(function (item, idx) {
                  try {
                    if (score_courses_resume.data) {
                      if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined') {
                        r.data[idx].ranking_raprendizaje = score_courses_resume.data[r.data[idx].ra_k].rate;
                      } else {
                        r.data[idx].ranking_raprendizaje = 0;
                      }
                    } else {
                      r.data[idx].ranking_raprendizaje = 0;
                    }
                  } catch (e) {
                    console.log(e);
                  }
                });
                var cursosSeriados = [];
                Object.keys(curso).forEach(function (idx) {
                  if (!cursosSeriados.includes(r.data[idx].ra_k)) {
                    cursosSeriados.push(r.data[idx].ra_k);
                    var next = curso[idx + 1];
                    var prev = curso[idx - 1];
                    cont++;
                    /*
                     * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                     */

                    curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);

                    if (prev !== undefined) {
                      var d1 = new Date(curso[idx].lms_ra_fecha_publicacion);
                      var d2 = new Date(curso[idx - 1].lms_ra_fecha_publicacion); //if (curso[idx].lms_ra_fecha_publicacion !== curso[idx - 1].lms_ra_fecha_publicacion) {

                      if (d1.getMonth() + 1 !== d2.getMonth() + 1) {// html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + curso[idx].lms_ra_fecha_publicacion.substring(0,7) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>'
                      }
                    } else if (cont === 1) {// html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + curso[idx].lms_ra_fecha_publicacion.substring(0,7) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>'
                    }

                    html += '<div class="col-xl-3 col-lg-4 col-md-6">';
                    html += '<a href="#/cursos/' + curso[idx].ra_k + '" class="coursesCard -type-1 ">';
                    html += '<div class="relative">';
                    html += '<div class="coursesCard__image overflow-hidden rounded-8">';
                    html += '<img class="w-1/1" src="' + curso[idx].imagen_raprendizaje + '" alt="image">';
                    html += '<div class="coursesCard__image_overlay rounded-8"></div>';
                    html += '</div>';
                    html += '<div class="d-flex justify-between py-10 px-10 absolute-full-center z-3">';
                    html += '<div>';
                    html += '<div class="px-15 rounded-200 ">';
                    html += '<span class="text-11 lh-1 uppercase fw-500 text-white"></span>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div>';
                    html += '<div class="px-15 rounded-200" style="text-align: right">';
                    html += '<span class="text-11 lh-1 uppercase fw-500 text-dark-1">';

                    if (curso[idx].estatus === "8") {
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" hidden style="width:50%" />';
                    }

                    html += '</span>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="h-100 pt-15">';
                    html += '<div class="d-flex items-center">';
                    html += '<div class="text-14 lh-1 text-yellow-1 mr-10"></div>';
                    html += '<div class="d-flex x-gap-5 items-center">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</div>';
                    html += '<div class="text-13 lh-1 ml-10">' + curso[idx].nombre_comunidad + '</div>';
                    html += '</div>';
                    html += '<div class="text-17 lh-15 fw-500 text-dark-1 mt-10">' + curso[idx].nombre + '</div>';
                    html += '<div class="progress-bar mt-10">';
                    html += '<div class="progress-bar__bg bg-light-3"></div>';
                    html += '<div class="progress-bar__bar bg-purple-1" style="width: ' + curso[idx].porcentaje_avance + '%"></div>';
                    html += '</div>';
                    html += '<div class="d-flex y-gap-10 justify-between items-center mt-10">';
                    html += '<div class="text-dark-1">' + parseInt(curso[idx].porcentaje_avance) + '% de avance</div>';
                    html += '<div></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    /*
                    html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">'
                    html += '<div class="card text-white text-center">'
                    html += '<a class="white_link" href="#/cursos/' + curso[idx].ra_k + '">'
                    html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>'
                    html += '<div class="icon_approved">'
                    if (curso[idx].estatus === "8") {
                    	html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />'
                    }
                    html += '</div>'
                    html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">'
                    html += '<div class="row text-center">'
                    html += '<div class="col-12">'
                    html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">'
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</ul>'
                    html += '</div>'
                    html += '</div>'
                    html += '<p class="card-text ">' + curso[idx].nombre + '</p>'
                    html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>'
                    html += '</div>'
                    html += '<div class="icon_approved">'
                    html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>'
                    html += '</div>'
                    html += '</a>'
                    html += '</div>'
                    html += '<div class="row">'
                    html += '<div class="col-10">'
                    html += '<div class="progress margin_top_5">'
                    html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance
                    html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>'
                    html += '</div>'
                    html += '</div>'
                    html += '<div class="col-2">'
                    html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>'
                    html += '</div>'
                    html += '</div>'
                    html += '</div>'
                    */
                  }
                });
                html += '<div class="col-12"><center><hr/></center></div>';
                Ember.$('#searchContainer').html(html);
              }
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      sortRanking: function sortRanking() {
        var self = this;
        var controller = self.controller;
        controller.set('comunidad', false);
        controller.set('valorados', true);
        controller.set('recien', false);
        controller.set('progeso', false);
        controller.set('nombre', false);
        Ember.$('.dropdown > button').text('Mejor valorados');
        Ember.$('.dropdown .dropdown-menu a').show();
        Ember.$.ajax({
          url: constant.APIURL + 'comunidad/listarMisInscripcionesRa/',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'params={"agrupar":"agrupar_por_cursos"' + controller.get("dataPost") + '}',
          success: function success(response) {
            var r = JSON.parse(response);
            var data = r.data;
            var html = '';
            var contador = 0;
            var curso = [];
            Object.keys(data).forEach(function (idx) {
              if (data[idx].nombre.length > 50) {
                var nomb = data[idx].nombre.toString();
                data[idx].nombre = nomb.substr(0, 47) + "...";
              }

              curso.push(data[idx]);
            });
            var cont = 0; // html += '<div class="col-12"><center><h2>Resultados de Busqueda<h2></center></div><div class="row">'

            /*
             *
             *  [YURAM]
             *
             */

            Ember.$.ajax({
              url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
              contentType: 'application/x-www-form-urlencoded',
              xhrFields: {
                withCredentials: true
              },
              type: 'POST',
              data: {
                "method": "score_courses_resume"
              },
              success: function success(response_text) {
                var score_courses_resume = JSON.parse(response_text);
                r.data.forEach(function (item, idx) {
                  try {
                    if (score_courses_resume.data) {
                      if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined') {
                        r.data[idx].ranking_raprendizaje = score_courses_resume.data[r.data[idx].ra_k].rate;
                      } else {
                        r.data[idx].ranking_raprendizaje = 0;
                      }
                    } else {
                      r.data[idx].ranking_raprendizaje = 0;
                    }
                  } catch (e) {
                    console.log(e);
                  }
                });
                curso.sort(function (a, b) {
                  return a.ranking_raprendizaje < b.ranking_raprendizaje ? 1 : b.ranking_raprendizaje < a.ranking_raprendizaje ? -1 : 0;
                });
                var cursosSeriados = [];
                Object.keys(curso).forEach(function (idx) {
                  if (!cursosSeriados.includes(r.data[idx].ra_k)) {
                    cursosSeriados.push(r.data[idx].ra_k);
                    var next = curso[idx + 1];
                    var prev = curso[idx - 1];
                    cont++;
                    /*
                     * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
                     */

                    curso[idx].imagen_raprendizaje = solveImageURL(curso[idx].imagen_raprendizaje);

                    if (prev !== undefined) {
                      if (self.round(curso[idx].ranking_raprendizaje) !== self.round(curso[idx - 1].ranking_raprendizaje)) {// html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + self.drawStars(curso[idx].ranking_raprendizaje) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>'
                      }
                    } else if (cont === 1) {//  html += '<div class="col-12 col-sm-12 col-xl-12"><h2> <i class="fas fa-angle-up arrow-up"></i> ' + self.drawStars(curso[idx].ranking_raprendizaje) + '</h2></div><div class="col-12 col-sm-12 col-xl-12"><hr class="hr_divider_line"></div>'
                    }

                    html += '<div class="col-xl-3 col-lg-4 col-md-6">';
                    html += '<a href="#/cursos/' + curso[idx].ra_k + '" class="coursesCard -type-1 ">';
                    html += '<div class="relative">';
                    html += '<div class="coursesCard__image overflow-hidden rounded-8">';
                    html += '<img class="w-1/1" src="' + curso[idx].imagen_raprendizaje + '" alt="image">';
                    html += '<div class="coursesCard__image_overlay rounded-8"></div>';
                    html += '</div>';
                    html += '<div class="d-flex justify-between py-10 px-10 absolute-full-center z-3">';
                    html += '<div>';
                    html += '<div class="px-15 rounded-200 ">';
                    html += '<span class="text-11 lh-1 uppercase fw-500 text-white"></span>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div>';
                    html += '<div class="px-15 rounded-200" style="text-align: right">';
                    html += '<span class="text-11 lh-1 uppercase fw-500 text-dark-1">';

                    if (curso[idx].estatus === "8") {
                      html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" hidden style="width:50%" />';
                    }

                    html += '</span>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="h-100 pt-15">';
                    html += '<div class="d-flex items-center">';
                    html += '<div class="text-14 lh-1 text-yellow-1 mr-10"></div>';
                    html += '<div class="d-flex x-gap-5 items-center">';
                    html += self.drawStars(curso[idx].ranking_raprendizaje);
                    html += '</div>';
                    html += '<div class="text-13 lh-1 ml-10">' + curso[idx].nombre_comunidad + '</div>';
                    html += '</div>';
                    html += '<div class="text-17 lh-15 fw-500 text-dark-1 mt-10">' + curso[idx].nombre + '</div>';
                    html += '<div class="progress-bar mt-10">';
                    html += '<div class="progress-bar__bg bg-light-3"></div>';
                    html += '<div class="progress-bar__bar bg-purple-1" style="width: ' + curso[idx].porcentaje_avance + '%"></div>';
                    html += '</div>';
                    html += '<div class="d-flex y-gap-10 justify-between items-center mt-10">';
                    html += '<div class="text-dark-1">' + parseInt(curso[idx].porcentaje_avance) + '% de avance</div>';
                    html += '<div></div>';
                    html += '</div>';
                    html += '</div>';
                    html += '</a>';
                    html += '</div>';
                    /*
                                            html += '<div class="col-xl-3 col-sm-6 col-12 margin_top_10">'
                                            html += '<div class="card text-white text-center">'
                                            html += '<a class="white_link" href="#/cursos/' + curso[idx].ra_k + '">'
                                            html += '<img class="card-img" src="' + curso[idx].imagen_raprendizaje + '" alt="Card image"/>'
                                            html += '<div class="icon_approved">'
                                            if (curso[idx].estatus === "8") {
                                                html += '<img class="image_remove ' + curso[idx].estatus + '" src="/img/cursos/aprobado.png" width="50" height="50" />'
                                            }
                                            html += '</div>'
                                            html += '<div class="card-img-overlay img_gradient" style="top: auto; padding: 0px;">'
                                            html += '<div class="row text-center">'
                                            html += '<div class="col-10">'
                                            html += '<ul class="horizontal-list no_padding" id="' + contador++ + '">'
                                             html += self.drawStars(curso[idx].ranking_raprendizaje);
                                             html += '</ul>'
                                            html += '</div>'
                                            html += '</div>'
                                            html += '<p class="card-text ">' + curso[idx].nombre + '</p>'
                                            html += '<p class="card-text uppercase"><small>' + curso[idx].nombre_comunidad + '</small></p>'
                                            html += '</div>'
                                             html += '<div class="icon_approved">'
                                            html += '<img class="image_remove ' + curso[idx].estatus + '" src="' + curso[idx].imagen + '" width="50" height="50" hidden/>'
                                            html += '</div>'
                                            html += '</a>'
                                            html += '</div>'
                                            html += '<div class="row">'
                                            html += '<div class="col-10">'
                                            html += '<div class="progress margin_top_5">'
                                            html += '<div class="progress-bar progress-bar-theme" role="progressbar" style=width:' + curso[idx].porcentaje_avance + '% aria-valuenow=' + curso[idx].porcentaje_avance
                                            html += 'aria-valuemin=' + curso[idx].porcentaje_avance + ' aria-valuemax=' + curso[idx].porcentaje_avance + '></div>'
                                            html += '</div>'
                                            html += '</div>'
                                            html += '<div class="col-2">'
                                            html += '<p class="number_bar text-theme">' + parseInt(curso[idx].porcentaje_avance) + '%</p>'
                                            html += '</div>'
                                            html += '</div>'
                                            html += '</div>'
                    */
                  }
                });
                html += '<div class="col-12"><center><hr/></center></div>';
                Ember.$('#searchContainer').html(html);
              }
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    }
  });

  _exports.default = _default;
});