define("comunidades-nafin/components/novedades/consulta-noticias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Esto indica que el componente no debe envolverse en un div adicional
    classNames: [],
    // Esto asegura que no se apliquen clases adicionales al div
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      var url_page = window.location.href; // let comunidad_id = url_page.substring(url_page.lastIndexOf('/') + 1);

      var comunidad_id = '';
      var params = new URLSearchParams(window.location.search);
      var noticias_k = params.get('noticia_k');

      if (isNaN(parseInt(comunidad_id))) {
        Ember.$.ajax({
          url: constant.APIURL + 'noticia/listarMisNoticias',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          success: function success(r) {
            var respons = JSON.parse(r);
            localStorage.setItem('total_noticias', respons.numFilas);

            if (respons.numFilas == 0) {
              self.withoutNews();
            }

            respons.data.forEach(function (item, idx) {
              respons.data[idx].imagen = solveImageURL(respons.data[idx].imagen_crop);
            });
            self.formatter(respons.data, self);
          },
          error: function error(e) {
            console.log(e);
          }
        });
      } else {
        Ember.$.ajax({
          url: constant.APIURL + 'noticia/listarMisNoticias',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          data: 'params={"comunidad_k":"' + comunidad_id + '"}',
          success: function success(r) {
            var respons = JSON.parse(r);
            localStorage.setItem('total_noticias', respons.numFilas);

            if (respons.numFilas == 0) {
              self.withoutNews();
            }

            respons.data.forEach(function (item, idx) {
              respons.data[idx].imagen = solveImageURL(respons.data[idx].imagen_crop);
            });
            self.formatter(respons.data, self);
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    },
    setNews: function setNews(r, self) {
      self.set('news-cards', r);
    },
    //Dar formato deseado a mes y fechas
    formatter: function formatter(data, self) {
      if (data != undefined) {
        Object.keys(data).forEach(function (idx) {
          //var test = new Date(data);
          var date_time = data[idx].timestamp_creacion.split(" ");
          var date = date_time[0].split('-');
          var month = date[1];
          data[idx].weekDay = self.timeStamp(date_time[0]);
          data[idx].monthFormath = self.monthFormatter(month);
          data[idx].dayFormath = date[2];
          data[idx].yearFormath = date[0];
          data[idx].date = window.dateFormat(date[2], window.translate('general.mes.lbl.lbl_mes_' + self.monthFormatter(month) + ".valor", self.monthFormatter(month)), date[0]); //console.log(date[0]);
          //data[idx].formatt_hora = data[idx].hora.substr(0,5);
          //data[idx].formatt_hora_fin = data[idx].hora_fin.substr(0,5);
        });
      } //console.log(data);


      self.setNews(data, self);
    },
    // Asignar el més en el que nos encontramos
    monthFormatter: function monthFormatter(month) {
      if (month === "01") {
        return "enero";
      } else if (month === "02") {
        return "febrero";
      } else if (month === "03") {
        return "marzo";
      } else if (month === "04") {
        return "abril";
      } else if (month === "05") {
        return "mayo";
      } else if (month === "06") {
        return "junio";
      } else if (month === "07") {
        return "julio";
      } else if (month === "08") {
        return "agosto";
      } else if (month === "09") {
        return "septiembre";
      } else if (month === "10") {
        return "octubre";
      } else if (month === "11") {
        return "noviembre";
      } else {
        return "diciembre";
      }
    },
    timeStamp: function timeStamp(date) {
      var array = date.split('-');
      var weeks = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'];
      var newDate = array[1] + "/" + array[2] + "/" + array[0];
      var temp = new Date(newDate);
      return weeks[temp.getDay()];
    },
    withoutNews: function withoutNews() {
      var html = '';
      html += '<div class="carousel-item">';
      html += '<div class="card box_shado ">';
      html += '<div class="card-body">';
      html += '<div class="row">';
      html += '<div class="col-12 margin_ud_10px"><br><h2>';
      html += window.translate('comunidades.noticias.lbl.lbl_card_noticias_sin_noticias_asociadas.valor', 'Sin noticias asociadas');
      html += '</h2></div>';
      html += '</div>';
      html += '</div>';
      html += '</div>';
      html += '</div>';
      Ember.$('#caruselActive').html(html);
    },
    actions: {
      goNoticia: function goNoticia(noticia_k) {
        window.document.location.href = '/#/noticias/?noticia_k=' + noticia_k;
        window.location.reload();
      }
    }
  });

  _exports.default = _default;
});