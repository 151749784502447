define("comunidades-nafin/templates/components/nom037/reportes/reports/estatus-de-capacitacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CdUoJv+E",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n<div class=\\\"card\\\">\\n\\n    <div class=\\\"card-header\\\" id=\\\"heading{{guid}}\\\">\\n        <h2 class=\\\"mb-0\\\">\\n            <button class=\\\"btn btn-link\\\" type=\\\"button\\\"\\n                    data-toggle=\\\"collapse\\\" data-target=\\\"#collapse{{guid}}\\\" aria-expanded=\\\"true\\\" aria-controls=\\\"collapse{{guid}}\\\">\\n                {{title}}\\n            </button>\\n        </h2>\\n    </div>\\n\\n    <div id=\\\"collapse{{guid}}\\\" class=\\\"collapse show\\\" aria-labelledby=\\\"heading{{guid}}\\\" data-parent=\\\"#accordionExample\\\">\\n\\n        <div class=\\\"card-body\\\">\\n\\n          \\n            <div class=\\\"table-responsive\\\" id=\\\"table-estatus-capacitacion\\\">\\n                {{human-talent-components/h-t-table data=dataTable headers=headersTable isStriped=true}}\\n            </div>\\n        </div>\\n    </div>\\n\\n</div>\\n\"],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"tabs -pills mt-20 js-tabs\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"tabs__controls d-flex items-center js-tabs-controls\"],[8],[0,\"\\n        \"],[6,\"div\"],[8],[6,\"button\"],[10,\"class\",\"tabs__button px-20 py-8 rounded-8 text-light-1 js-tabs-button is-active\"],[10,\"data-tab-target\",\".-tab-item-1\"],[10,\"type\",\"button\"],[8],[1,[20,\"title\"],false],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"tabs__content mt-20 js-tabs-content\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"tabs__pane -tab-item-1 is-active\"],[8],[0,\"\\n\\n            \"],[6,\"div\"],[10,\"class\",\"table-responsive pt-4\"],[8],[0,\"\\n                \"],[1,[26,\"human-talent-components/h-t-table\",null,[[\"data\",\"headers\",\"isStriped\"],[[22,[\"dataTable\"]],[22,[\"headersTable\"]],true]]],false],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/nom037/reportes/reports/estatus-de-capacitacion.hbs"
    }
  });

  _exports.default = _default;
});