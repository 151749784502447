define("comunidades-nafin/routes/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/estatus-de-capacitacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /*
    beforeModel() {
    let promise = new RSVP.Promise(function(resolve) {
    later(this, function() {
    resolve("ok");
                console.log('mensaje de promise');
    }, 3000);
    });
    return promise;
    },
    */
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('periodo_k', model.periodo_k); // Obtenemos la URL del sitio actual

      var url_actual = window.location.href;
      var url_reg = url_actual.split("/");
      var nombre_page = url_reg[url_reg.length - 2];
      setTimeout(function () {
        $(".sidebar__item").removeClass("-is-active -dark-bg-dark-2");

        if (nombre_page === 'estatus-de-capacitacion') {
          $("#wrap_sensibilizacion").addClass("-is-active -dark-bg-dark-2");
        }
      }, 500);
      /* Mostramos menu dashboard en caso de movil 
      if (window.innerWidth <= 768) 
      {
          $('#btn_menu_dashboard').show();
      }
      else
      {
          $('#btn_menu_dashboard').css( 'cssText', 'display: none !important;' );
      }
      */
    },
    actions: {
      showFormRequestGenerateZip: function showFormRequestGenerateZip(record) {
        /**
         * [YURAM] HACK Begin
         *
         * Los cambios aplicados a continuación fueron aplicados a causa de una incompatibilidad entre componentes y elementos
         *
         */
        $("#compresoK").val(null);
        $("#inputEmail").val('');
        $('#requestFormMassiveUserReports').modal({});
        /**
         * [YURAM] HACK End
         */
      },
      bindScopeToReportAtsGeneracionMasivaReportesComponent: function bindScopeToReportAtsGeneracionMasivaReportesComponent(scope_reporte_ats_generacion_masiva_reportes) {
        this.reporteGeneracionMasivaReportesScope = scope_reporte_ats_generacion_masiva_reportes;
      },
      afterCreate: function afterCreate() {
        if (this.reporteGeneracionMasivaReportesScope) {
          if (isFunction(this.reporteGeneracionMasivaReportesScope.propagationAfterCreateEvent)) {
            this.reporteGeneracionMasivaReportesScope.propagationAfterCreateEvent();
          }
        }
      },
      afterUpdate: function afterUpdate() {}
    }
  });

  _exports.default = _default;
});