define("comunidades-nafin/components/communities-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    tagName: '',
    // Esto indica que el componente no debe envolverse en un div adicional
    classNames: [],
    // Esto asegura que no se apliquen clases adicionales al div
    verMasComunidadesAutoinscribibles: true,
    didRender: function didRender() {
      console.log('RENDER ****');
      localStorage.setItem('seccion-anterior', Ember.$(".titulo-seccion").text());
    },
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      var response_data = '';
      Ember.$.ajax({
        url: constant.APIURL + 'comunidad/listarMisComunidades',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: 'limit=100',
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          var respons = JSON.parse(r);

          if (respons.data != undefined) {
            Object.keys(respons.data).forEach(function (idx) {
              if (respons.data[idx].img.indexOf('http') != -1) {
                respons.data[idx].img = respons.data[idx].img;
              } else {
                respons.data[idx].img = constant.BASEURL + respons.data[idx].img;
              }

              if (respons.data[idx].descripcion.split(' ').length > 22) {
                respons.data[idx].descripcion = respons.data[idx].descripcion.split(' ').slice(0, 22).join(' ') + '...';
              }
            });
            var firstCourses = [];

            for (var i = 0; i < 4; i++) {
              if (respons.data[i]) {
                firstCourses.push(respons.data[i]);
              } else {
                Ember.$('#seeMoreCommunities').remove();
              } // Almacenamos la primera comunidad para el boton de Novedades


              if (i === 0) {
                localStorage.setItem("primer_id_comunidad", respons.data[i].comunidad_k);
              }
            }

            self.setComunidades(firstCourses);
            /*
            $('#seeMoreCommunities').click(function() {
                console.log('Mostrar mas comunidades');
                self.setComunidades(respons.data);
                $('#seeMoreCommunities').remove();
            });  
            */

            response_data = respons.data;
            /*
            setTimeout(function(){
                $('#loading').fadeOut('slow');  
                lazyLoad.update();
            },3000); 
            */

            /** Redes sociales Comunidad **/

            self.set("comunidades_facebook", true);

            if (window.instanciaConfiguracion.general_compatir_comunidad_en_facebook_habilitar == false) {
              self.set("comunidades_facebook", false);
            }

            self.set("comunidades_twitter", true);

            if (window.instanciaConfiguracion.general_compatir_comunidad_en_twitter_habilitar == false) {
              self.set("comunidades_twitter", false);
            }

            self.set("comunidades_linkedin", true);

            if (window.instanciaConfiguracion.general_compatir_comunidad_en_linkedin_habilitar == false) {
              self.set("comunidades_linkedin", false);
            }
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
      Ember.$('.seccion_tarjetas').on('click', '#seeMoreCommunities', function () {
        console.log('Mostrar mas comunidades');
        self.setComunidades(response_data);
        Ember.$('#seeMoreCommunities').remove();
      });
      self.send("getComunidadesAuto", false);
    }
  }, _defineProperty(_EmberComponent$exte, "didRender", function didRender() {
    var self = this._super.apply(this, arguments);
  }), _defineProperty(_EmberComponent$exte, "setComunidades", function setComunidades(r) {
    this.set('comunidades-cards', r);
  }), _defineProperty(_EmberComponent$exte, "setOtherComunidades", function setOtherComunidades(r) {
    this.set('other-comunidades-cards', r);
  }), _defineProperty(_EmberComponent$exte, "actions", {
    // Cargamos la comundad_especifica
    verComunidad: function verComunidad(comunidad_k) {
      //Creamos una variable en session que permita validar en comunidad especifica la url de referencia
      var url = "/#/comunidades/" + comunidad_k;
      sessionStorage.setItem("referrerComunidad", window.location.href);
      window.location.href = url;
      window.location.reload();
    },
    getComunidadesAuto: function getComunidadesAuto(all) {
      var self = this;
      Ember.$.ajax({
        url: constant.APIURL + 'comunidad/listarOtrasComunidades',
        type: 'GET',
        contentType: 'application/x-www-form-urlencoded',
        data: '',
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          var respons = JSON.parse(r);
          Object.keys(respons.data).forEach(function (idx) {
            if (respons.data[idx].img.indexOf('http') != -1) {
              respons.data[idx].img = respons.data[idx].img;
            } else {
              respons.data[idx].img = constant.BASEURL + respons.data[idx].img;
            }
          });
          var firstCourses = [];
          var to = all ? respons.data.length : 2;

          for (var i = 0; i < to; i++) {
            if (respons.data[i]) {
              firstCourses.push(respons.data[i]);
            }
          }

          if (all || firstCourses.length <= 2) {
            // $('#seeMoreOtherCommunities').remove();
            self.set("verMasComunidadesAutoinscribibles", false);
          }

          Ember.$('#seeMoreOtherCommunities').click(function () {
            self.setOtherComunidades(respons.data);
            Ember.$('#seeMoreOtherCommunities').remove();
          });
          self.setOtherComunidades(firstCourses);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    suscribe2other: function suscribe2other(comunidad_k) {
      Ember.$.ajax({
        url: constant.APIURL + 'comunidad/inscribirUsuarioAComunidad',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: 'comunidad_k=' + comunidad_k,
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          document.location.href = "/comunidades/" + comunidad_k;
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    facebook: function facebook(e) {
      this._super();

      var self = this;
      var aTagCurso = Ember.$(e.target).parent().parent().parent().parent().parent().children()[0];
      var arrayComunidad = Ember.$(aTagCurso).data('href').split('/');
      var comunidad_k = arrayComunidad[arrayComunidad.length - 1];
      shareFB(constant.SHAREURL + 'comunidad/' + comunidad_k);
      window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_FACEBOOK_COMPARTIR_COMUNIDAD, comunidad_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_FACEBOOK);
    },
    twitter: function twitter(e) {
      this._super();

      var self = this; // Selector a card del elemento dom

      var elementoPadreDom = Ember.$(e.target).parent().parent().parent().parent().parent(); // Elementos hijos de elemento padre del dom

      var arrayChildParents = elementoPadreDom.children();
      var elementoContenido = arrayChildParents[0]; //  selector a elemeto que contiene imagen, titulo, numero de integrantes de la comunidad

      var arrayElementoPadreContenido = Ember.$(elementoContenido).children().children();
      var elementoImagenComunidad = arrayElementoPadreContenido[0]; //Recuperación Imagen fondo de comunidad

      var imagenSrc = Ember.$(elementoImagenComunidad).data('src');
      var elementoCardImgOverlay = arrayElementoPadreContenido[1];
      var arrayChildsCardImgOverlay = Ember.$(elementoCardImgOverlay).children();
      var elementoComunidadTextDom = arrayChildsCardImgOverlay[1];
      var elementoComunidadParticipantes = arrayChildsCardImgOverlay[3];
      var numeroDeParticipatesEnComunidad = Ember.$(elementoComunidadParticipantes).text().trim();
      var nombreDeLaComunidad = Ember.$(elementoComunidadTextDom).text();
      var twitterMsm = 'Forma parte de más de los ' + numeroDeParticipatesEnComunidad + ' que son parte de la comunidad ' + nombreDeLaComunidad + ' en NAFIN te capacita';
      var aTagCurso = Ember.$(e.target).parent().parent().parent().parent().parent().children()[0];
      var arrayComunidad = Ember.$(aTagCurso).data('href').split('/');
      var comunidad_k = arrayComunidad[arrayComunidad.length - 1];
      var url = constant.SHAREURL + 'comunidad/' + comunidad_k;
      var width = 575,
          height = 400,
          left = (Ember.$(window).width() - width) / 2,
          top = (Ember.$(window).height() - height) / 2,
          url = 'https://twitter.com/share?url=' + url + '&text=' + twitterMsm,
          opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
      window.open(url, 'twitter', opts);
      window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_TWITTER_COMPARTIR_COMUNIDAD, comunidad_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_TWITTER);
    },
    linkedin: function linkedin(e) {
      this._super();

      var self = this;
      var aTagCurso = Ember.$(e.target).parent().parent().parent().parent().parent().children()[0];
      var arrayComunidad = Ember.$(aTagCurso).data('href').split('/');
      var comunidad_k = arrayComunidad[arrayComunidad.length - 1];
      shareLI(constant.SHAREURL + 'comunidad/' + comunidad_k);
      window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_LINKEDIN_COMPARTIR_COMUNIDAD, comunidad_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_LINKEDIN);
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});