define("comunidades-nafin/templates/consulta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MaWAcH7y",
    "block": "{\"symbols\":[],\"statements\":[[1,[20,\"outlet\"],false],[0,\"\\n\\n\"],[2,\" Agregar footer si se encuentra en la pagina principal \"],[0,\"\\n\"],[1,[20,\"body-footer\"],false],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/consulta.hbs"
    }
  });

  _exports.default = _default;
});