define("comunidades-nafin/routes/nom-037/evidencias/resguardo-de-equipos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    searchValue: '',
    isEditing: false,
    isSearching: false,
    isLoading: false,
    deleteStore: null,
    isVisibleDeleteForm: false,
    createDeleteCommand: 'create',
    isVisibleCreateUpdateForm: false,
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      var self = this;
      controller.set('isLoading', this.isSearching);
      controller.set('periodo_k', model.periodo_k);
      self.set('params', {
        'periodo_k': model.periodo_k
      });
      $(".sidebar__item").removeClass("-is-active -dark-bg-dark-2");
      $("#wrap_resguardo_equipos").addClass("-is-active -dark-bg-dark-2");
      /* Mostramos menu dashboard en caso de movil 
      if (window.innerWidth <= 768) 
      {
          $('#btn_menu_dashboard').show();
      }
      else
      {
          $('#btn_menu_dashboard').css( 'cssText', 'display: none !important;' );
      }
      */

      setTimeout(function () {
        _this.cargarPDF();
      }, 1000); // Obtenemos la URL del sitio actual

      var url_actual = window.location.href;
      var url_reg = url_actual.split("/");
      var nombre_page = url_reg[url_reg.length - 2];
      setTimeout(function () {
        $(".sidebar__item").removeClass("-is-active -dark-bg-dark-2");

        if (nombre_page === 'resguardo-de-equipos') {
          $("#wrap_resguardo_equipos").addClass("-is-active -dark-bg-dark-2");
        }
      }, 500);
    },
    cargarPDF: function cargarPDF() {
      var self = this;
      $.ajax({
        url: constant.APIURL + '/nom037/resguardodeequipos/downloadactivedocumento',
        type: "post",
        async: false,
        data: {
          params: self.params
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            var url = response.data.url_download;
            var extension = url.split(".")[url.split(".").length - 1];
            var extensiones_google = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
            var extensiones_navegador = ['pdf', 'gif', 'jpg', 'jpeg', 'png', 'bmp'];

            if (extensiones_navegador.includes(extension)) {
              $("#previewDocumentIFrame").attr("src", url);
            } else if (extensiones_google.includes(extension)) {
              $("#previewDocumentIFrame").attr("src", "https://docs.google.com/viewer?url=" + url + "&embedded=true");
            } else {
              showModal({
                titulo: 'No se puede previsualizar el documento.',
                mensaje: 'Solamente se puede previsualizar imagenes, documentos PDF. Te recomendamos descargar el documento para consultarlo.'
              });
            }
          } else {
            window.showModal({
              titulo: 'Error en sistema.',
              mensaje: !window.isEmpty(response.error) ? response.error : 'Ups! acaba de suceder algo inesperado. Por favor intente nuevamente en otro momento.'
            });
          }
        },
        error: function error(e) {
          window.showModalError();
          console.log(e);
        }
      });
    },
    actions: {
      downloadPoliticas: function downloadPoliticas() {
        this.controller.set('isLoading', true);
        var self = this;
        $.ajax({
          url: constant.APIURL + '/nom037/resguardodeequipos/downloadactivedocumento',
          type: "post",
          async: false,
          data: {
            params: self.params
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              window.open(response.data.url_download, '_blank');
              self.controller.set('isLoading', false);
            } else {
              window.showModal({
                titulo: 'Error en sistema.',
                mensaje: !window.isEmpty(response.error) ? response.error : 'Ups! acaba de suceder algo inesperado. Por favor intente nuevamente en otro momento.'
              });
              self.controller.set('isLoading', false);
            }
          },
          error: function error(e) {
            window.showModalError();
            self.controller.set('isLoading', false);
            console.log(e);
          }
        });
      },
      showCreateModal: function showCreateModal() {
        this.controller.set('updateStore', this.store.createRecord('nom-035-stps/acciones-internas/evidencia', {}));
        this.controller.set('createDeleteCommand', 'create');
        this.controller.set('isVisibleCreateUpdateForm', true);
        this.controller.set('isCommandUpdate', false);
        /**
         * [YURAM] HACK Begin
         *
         * Los cambios aplicados a continuación fueron aplicados a causa de una incompatibilidad entre componentes y elementos
         *
         */

        $('#button-save-changes').attr("disabled", "disabled");
        $("#planDeTrabajoK").val(null);
        $("#planDeTrabajoNombreDocumento").val('');
        $("#planDeTrabajoNombreDocumento").val('');
        $("#inputTitulo").val('');
        $("#inputDescripcion").val('');
        $('#filesattached').html('');
        $('#createUpdateFormWorkPlan').modal({});
      },
      afterCreate: function afterCreate(dataObject) {},
      afterUpdate: function afterUpdate(dataObject) {}
    }
  });

  _exports.default = _default;
});