define("comunidades-nafin/routes/nom-037/evidencias/politicas-de-riesgo-visitas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    didInsertElement: function didInsertElement() {
      /**
       * Validamos si es necesario recargar la pagina para cargar todos los componenetes del template nuevo
       */
      if (!window.constant.BAND_RELOAD) {
        $("body").html("");
        window.location.reload();
      }
    },
    reporte: 'nom037/export/reportevisitas',
    params: {},
    _controller: null,
    headers: [{
      dataIndex: 'politica_visualizacion_k',
      title: '#'
    }, {
      dataIndex: 'nombre_completo',
      title: 'Nombre completo'
    }, {
      dataIndex: 'puesto',
      title: 'Puesto'
    }, {
      dataIndex: 'total_visitas',
      title: 'Total de visitas'
    }, {
      dataIndex: 'fecha_visita',
      title: 'Fecha de visita'
    }],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.resetNewEvent(this);
    },
    setupController: function setupController(controller, model) {
      var self = this;
      self._controller = controller;
      setTimeout(function () {
        $("#loading").fadeOut(1500);
        self.loadData(controller);
        controller.set('title', self.get("title"));
        controller.set('headersTable', self.get("headers"));
        self.set('params', {
          'periodo_k': model.periodo_k
        });
      }, 1000);
      $(".sidebar__item").removeClass("-is-active -dark-bg-dark-2");
      $("#wrap_registro_politicas_teletrabajo").addClass("-is-active -dark-bg-dark-2");
      /* Mostramos menu dashboard en caso de movil 
      if (window.innerWidth <= 768) 
      {
          $('#btn_menu_dashboard').show();
      }
      else
      {
          $('#btn_menu_dashboard').css( 'cssText', 'display: none !important;' );
      }
      */
      // Obtenemos la URL del sitio actual

      var url_actual = window.location.href;
      var url_reg = url_actual.split("/");
      var nombre_page = url_reg[url_reg.length - 2];
      setTimeout(function () {
        $(".sidebar__item").removeClass("-is-active -dark-bg-dark-2");

        if (nombre_page === 'politicas-de-riesgo-visitas') {
          $("#wrap_registro_politicas_teletrabajo").addClass("-is-active -dark-bg-dark-2");
        }
      }, 500);
    },
    loadData: function loadData(controller) {
      var self = this;
      $.ajax({
        url: constant.APIURL + 'nom037/politicaderiesgo/reportevisitas',
        type: "post",
        data: {
          params: self.params
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          controller.set('dataTable', response.data);
          setTimeout(function () {
            $("#visitasTable").DataTable({
              language: {
                "decimal": "",
                "emptyTable": "Sin registros",
                "info": "Mostrando del _START_ al  _END_ de _TOTAL_ Registros",
                "infoEmpty": "Mosrando 0 to 0 de 0 registros",
                "infoFiltered": "(Filtro _MAX_ registros totales)",
                "infoPostFix": "",
                "thousands": ",",
                "lengthMenu": "Mostrar _MENU_ registros",
                "loadingRecords": "Cargando...",
                "processing": "",
                "search": "Buscar:",
                "zeroRecords": "Sin coincidencias",
                "paginate": {
                  "first": "Primero",
                  "last": "Último",
                  "next": "Siguiente",
                  "previous": "Anterior"
                },
                "aria": {
                  "sortAscending": ": activate to sort column ascending",
                  "sortDescending": ": activate to sort column descending"
                }
              }
            });
          }, 1000);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    actions: {
      downloadReporte: function downloadReporte() {
        var self = this;
        document.location.href = constant.APIURL + String(this.reporte) + '/' + JSON.stringify(this.params);
      }
    }
  });

  _exports.default = _default;
});