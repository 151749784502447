define("comunidades-nafin/initializers/look-and-feel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application, model) {
    Ember.$.ajax({
      url: constant.APIURL + 'usuario/datospersonales/',
      type: "post",
      async: false,
      data: {},
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      xhrFields: {
        withCredentials: true
      },
      dataType: "json",
      success: function success(response) {
        if (response.success == true) {
          window.instanciaConfiguracion = response.configuracion;
          window.usuarioConfiguracion = response.usuario_configuracion;
          /*
          *  En caso de que tengamos un redireccionamiento por usuario al finalizar la sesión. se ajusta en esta sección.
          */

          if (response.usuario_configuracion) {
            if (response.usuario_configuracion.url_redireccion_logout) {
              constant.URL_REDIRECT_AFTER_LOGOUT = response.usuario_configuracion.url_redireccion_logout;
            }
          }
        }
      },
      error: function error(e) {
        console.log(e);
      }
    });
    var secciones = window.location.href.split("#");
    var subsecciones = '';
    var usuario_k = 0;

    if (secciones[1] != null) {
      subsecciones = secciones[1].split("/");

      if (typeof subsecciones[3] != "undefined") {
        if (subsecciones[2] == 'evidencias_galeria') {
          usuario_k = atob(subsecciones[3]);
        }
      }
    }

    Ember.$.ajax({
      url: constant.APIURL + 'usuario/configuraciondeempresa',
      type: "post",
      async: false,
      data: {
        "usuario_k": usuario_k
      },
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      xhrFields: {
        withCredentials: true
      },
      dataType: "json",
      success: function success(response) {
        if (response.success == true) {
          if (response.data.look_and_feel) {
            window.constant.configuracionEmpresa = response.data;
            window.constant.THEME = response.data.look_and_feel; // Cambiamos el PAHT del Look&feel

            if (window.constant.THEME.search(/back/) >= 0) {
              window.constant.URL_THEME = {
                logo: window.constant.BASEURL + "lookandfeel/" + window.constant.THEME + "/comunidad/img/logo.png",
                style_css: window.constant.BASEURL + "lookandfeel/" + window.constant.THEME + "/comunidad/style.css",
                style_js: window.constant.BASEURL + "lookandfeel/" + window.constant.THEME + "/comunidad/style.js",
                img_evaluacion: window.constant.BASEURL + "lookandfeel/" + window.constant.THEME + "/comunidad/img/iconos_cursos/evaluacion.png",
                img_scorm: window.constant.BASEURL + "lookandfeel/" + window.constant.THEME + "/comunidad/img/iconos_cursos/scorm.png",
                img_encuesta: window.constant.BASEURL + "lookandfeel/" + window.constant.THEME + "/comunidad/img/iconos_cursos/encuesta.png",
                img_actividad: window.constant.BASEURL + "lookandfeel/" + window.constant.THEME + "/comunidad/img/iconos_cursos/actividad.png",
                img_presencial: window.constant.BASEURL + "lookandfeel/" + window.constant.THEME + "/comunidad/img/iconos_cursos/presencial.png",
                lookandfeel: '',
                logo_login: ''
              };
            } else {
              window.constant.URL_THEME = {
                logo: "lookandfeel/" + window.constant.THEME + "/img/logo.png",
                style_css: "lookandfeel/" + window.constant.THEME + "/style.css",
                style_js: "lookandfeel/" + window.constant.THEME + "/style.js",
                img_evaluacion: "lookandfeel/" + window.constant.THEME + "/img/iconos_cursos/evaluacion.png",
                img_scorm: "lookandfeel/" + window.constant.THEME + "/img/iconos_cursos/scorm.png",
                img_encuesta: "lookandfeel/" + window.constant.THEME + "/img/iconos_cursos/encuesta.png",
                img_actividad: "lookandfeel/" + window.constant.THEME + "/img/iconos_cursos/actividad.png",
                img_presencial: "lookandfeel/" + window.constant.THEME + "/img/iconos_cursos/presencial.png"
              };
            }

            document.querySelector("link[rel='shortcut icon']").href = "lookandfeel/" + constant.THEME + "/img/favicon.ico";
          }
        }
      },
      error: function error(e) {
        console.log(e);
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});