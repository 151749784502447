define("comunidades-nafin/components/admin-components/noticias-editar-noticia", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
      set = Ember.set;

  var _default = Ember.Component.extend({
    noticia_id: '',
    edicionImagen: false,
    nombreFoto: '',
    imagenCrop: '',
    imagenCropX: '',
    imagenCropY: '',
    imagenCropWidth: '',
    imagenCropHeight: '',
    //  remove: function(){
    //      $("#imagen_seleccionada").remove();
    //
    //   },
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.noticia_id = this.noticia_k;
    },
    didRender: function didRender() {},
    actions: {
      removeImage: function removeImage(_noticia_k) {
        this.set('imageUploaded', null);
        Ember.$('.texto-subio-imagen').hide();
        Ember.$("#removeImg").hide();
        Ember.$("#changeImg").hide();
      },
      cierraModalEditarNoticia: function cierraModalEditarNoticia(_noticia_k) {
        Ember.$("#modalNoticiaEdicion-" + _noticia_k + " #noticia").jqte();
      },
      editarNoticia: function editarNoticia(_noticia_k, _titulo) {
        var self = this;
        Ember.$('#loading-modal').show();
        var modal_k = Ember.$("#modalNoticiaEdicion-" + _noticia_k).modal({
          backdrop: "static",
          keyboard: false
        });
        var jsonComunidades;

        var _maxComunidades;

        var arIdComunidades = [];
        var arComunidades = [];

        var _titulo;

        var _subtitulo;

        var _comunidad;

        var _comunidad_k;

        var _cuerpo;

        var _tags;

        var _imagen;

        var _imagen_crop_x;

        var _imagen_crop_y;

        var _imagen_crop_width;

        var _imagen_crop_height;

        var _select = '';
        Ember.$.ajax({
          url: constant.APIURL + 'noticia/listarNoticiasPropietarioComunidades',
          type: 'POST',
          //async:false,
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: '',
          success: function success(r) {
            var respons = JSON.parse(r); //console.log(respons);
            //Recorrer el obj Noticias

            Object.keys(respons.success.data).forEach(function (idx) {
              if (_noticia_k === respons.success.data[idx].noticia_k) {
                _titulo = respons.success.data[idx].titulo;
                _subtitulo = respons.success.data[idx].subtitulo;
                _comunidad = respons.success.data[idx].comunidad;
                _comunidad_k = respons.success.data[idx].comunidad_k;
                _tags = respons.success.data[idx].tags;
                _cuerpo = respons.success.data[idx].cuerpo;
                _imagen = respons.success.data[idx].imagen;
                _imagen_crop_x = respons.success.data[idx].imagen_crop_x;
                _imagen_crop_y = respons.success.data[idx].imagen_crop_y;
                _imagen_crop_width = respons.success.data[idx].imagen_crop_width;
                _imagen_crop_height = respons.success.data[idx].imagen_crop_height;
                Ember.$("#modalNoticiaEdicion-" + _noticia_k + " #titulo").val(_titulo);
                Ember.$("#modalNoticiaEdicion-" + _noticia_k + " #subtitulo").val(_subtitulo);
                Ember.$("#modalNoticiaEdicion-" + _noticia_k + " #noticia").val(_cuerpo);
                Ember.$("#modalNoticiaEdicion-" + _noticia_k + " #noticia").jqte();
                Ember.$("#modalNoticiaEdicion-" + _noticia_k + " #tags").val(_tags);

                var arrayTags = _tags.split(',');

                for (var i = 0; i < arrayTags.length; i++) {
                  Ember.$("#modalNoticiaEdicion-" + _noticia_k + " #tags").tagsinput('add', arrayTags[i]);
                }

                Ember.$(".tag").css("background", window.constant.COLOR);

                if (_imagen.indexOf('http') != 0) {//_imagen = constant.APIURL+_imagen;
                }

                _imagen = _imagen.replace('/index.php', '');
                var cropper = new Slim(document.getElementById('cropperEditNews-' + _noticia_k), {
                  ratio: '16:9',
                  willLoad: function willLoad(data, ready) {
                    cropper.edit(); //$("#modalNoticiaEdicion-"+_noticia_k+" #editarNoticiaButton").css('display', 'none');
                  },
                  willSave: function willSave(data, ready) {
                    Ember.$('#imagen-cargando').css('display', 'block');
                    Ember.$("#modalNoticiaEdicion-" + _noticia_k + " #editarNoticiaButton").css('display', 'none');
                    ready(data);
                  },
                  service: constant.APIURL + 'noticia/uploadImgNoticia',
                  download: false,
                  buttonConfirmLabel: 'Aceptar',
                  buttonCancelLabel: 'Cancelar',
                  post: "input, output, actions",
                  didRemove: function didRemove() {
                    self.edicionImagen = true;
                    self.nombreFoto = '';
                  },
                  didTransform: function didTransform() {},
                  didUpload: function didUpload(error, data, response) {
                    Ember.$("#modalNoticiaEdicion-" + _noticia_k + " #editarNoticiaButton").css('display', 'block');

                    if (error == null) {
                      Ember.$('#imagen-cargando').css('display', 'none');
                      self.edicionImagen = true;
                      self.nombreFoto = response.pathOriginal;
                      self.imagenCrop = response.path;
                      self.imagenCropX = response.crop_x;
                      self.imagenCropY = response.crop_y;
                      self.imagenCropWidth = response.crop_width;
                      self.imagenCropHeight = response.crop_height;
                    } else {
                      console.log(error);
                    }

                    Ember.$("#editarNoticiaButton").show();
                  },
                  label: window.translate('noticias.noticias.lbl.lbl_arrastra_y_suelta_tu_imagen.valor', 'Arrastra y suelta tu imagen o selecciónala desde tu equipo')
                });
                self.nombreFoto = _imagen;
                cropper.load(window.constant.BASEURL + "system/application/libraries/thumbs.php?s=" + _imagen + "&w=40&h=40&d=5", {
                  ratio: '16:9',
                  post: "input, output, actions",
                  minSize: {
                    width: 640,
                    height: 480
                  }
                }, function (error, data) {
                  if (parseInt(_imagen_crop_width) > 0 && parseInt(_imagen_crop_height) > 0) {
                    cropper.setRatio('16:9', function (data) {
                      cropper.crop({
                        x: parseInt(_imagen_crop_x),
                        y: parseInt(_imagen_crop_y),
                        width: parseInt(_imagen_crop_width),
                        height: parseInt(_imagen_crop_height),
                        type: "manual"
                      }, function (result) {});
                    });
                  }
                });
                modal_k.on('hidden.bs.modal', function () {
                  cropper.destroy();
                });
                Ember.$('#imagen-cargando').css('display', 'none'); //MostrarEn

                Ember.$.ajax({
                  url: constant.APIURL + 'noticia/mostrarEn',
                  type: 'POST',
                  //async:false,
                  xhrFields: {
                    withCredentials: true
                  },
                  contentType: 'application/x-www-form-urlencoded',
                  data: 'start=0&limit=20&params={"bandera":"1"}',
                  success: function success(r) {
                    var respons = JSON.parse(r); //console.log(respons);

                    var _verCboComunidades = 'none'; //console.log('_comunidad_k->'+_comunidad_k);

                    if (_comunidad_k > 0) {
                      Ember.$.ajax({
                        url: constant.APIURL + 'noticia/noticiasRecientes',
                        type: 'POST',
                        async: false,
                        cache: false,
                        xhrFields: {
                          withCredentials: true
                        },
                        contentType: 'application/x-www-form-urlencoded',
                        data: 'start=0&limit=1&params={"comunidad_k":"0", "noticia_k":"' + _noticia_k + '"}',
                        success: function success(r) {
                          var res = JSON.parse(r);
                          Object.keys(res.data).forEach(function (idx) {
                            if (_noticia_k === res.data[idx].llave_k) {
                              console.log('noticiasRecientes->idx->' + idx);
                              jsonComunidades = res.data[idx].comunidades; //console.log(jsonComunidades);

                              _maxComunidades = Object.keys(jsonComunidades).length;
                              Object.keys(jsonComunidades).forEach(function (x) {
                                arIdComunidades[x] = jsonComunidades[x].comunidad_k;
                                arComunidades[x] = jsonComunidades[x].nombre; //console.log(arIdComunidades[x]);
                              });
                              return;
                            }
                          });
                        },
                        error: function error(e) {
                          console.log(e);
                        }
                      });
                    }

                    var html = '';
                    Object.keys(respons.data).forEach(function (idx) {
                      if (_comunidad_k > 0) {
                        if (respons.data[idx].llave_k == 3) {
                          _select = 'selected="selected"';
                        } else {
                          _select = '';
                        }

                        _verCboComunidades = 'block';
                      } else {
                        if (_comunidad_k == null) {
                          _select = 'selected="selected"';
                          _comunidad_k = '-1';
                        } else {
                          _select = '';
                        }

                        _verCboComunidades = 'none';
                      } // Forzamos a que siempre seleccionemos 'Comunidad'


                      if (respons.data[idx].llave_k == 3) {
                        _select = 'selected="selected"';
                      } else {
                        _select = '';
                      }

                      html += '<option ' + _select + ' value="' + respons.data[idx].llave_k + '">' + respons.data[idx].nombre + '</option>';
                    });
                    _verCboComunidades = 'block';
                    Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' #tipo_noticia').html(html);
                    Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' .cls-noticia-mostrar-en').css('display', _verCboComunidades);
                    Ember.$.ajax({
                      url: constant.APIURL + 'comunidad/listar',
                      type: 'POST',
                      async: false,
                      //cache: false,
                      xhrFields: {
                        withCredentials: true
                      },
                      contentType: 'application/x-www-form-urlencoded',
                      data: 'start=0&limit=200&params={"noticias_activo":"1"}',
                      success: function success(r) {
                        Ember.$('#loading-modal').fadeOut('slow');
                        var respons = JSON.parse(r);

                        var htmls = [],
                            _htmls;

                        Object.keys(respons.data).forEach(function (idx) {
                          var _select = '';
                          var rCom = respons.data[idx].comunidad_k;

                          for (var x = 0; x <= _maxComunidades - 1; x++) {
                            if (rCom == arIdComunidades[x]) {
                              _select = 'selected="selected"';
                              break;
                            } else {
                              _select = '';
                            }
                          }

                          var html = '<option ' + _select + ' value="' + respons.data[idx].comunidad_k + '">' + respons.data[idx].nombre + '</option>';
                          htmls.push(html);
                        });
                        _htmls = htmls.join(''); //console.log(_htmls);

                        Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' .form-control-chosen').chosen('destroy');
                        Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' #mostrar_en')[0].innerHTML = _htmls;
                        Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' .form-control-chosen').chosen({
                          allow_single_deselect: true,
                          width: '100%'
                        });
                      },
                      error: function error(e) {
                        console.log(e);
                      }
                    });

                    if (_comunidad_k > 0) {} else {
                      // $('#modalNoticiaEdicion-'+_noticia_k+' .cls-noticia-mostrar-en').css('display', 'none');
                      Ember.$('#modalNoticiaEdicion-' + _noticia_k + ' .cls-noticia-mostrar-en').css('display', 'block');
                    }
                  },
                  error: function error(e) {
                    console.log(e);
                  }
                });
                return;
              }
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
        /*setTimeout(function(){
            $('#loading-modal').fadeOut('slow');
        }, 1500);*/
      },
      onSelectTipoNoticia: function onSelectTipoNoticia(val) {
        var _noticia_k = this.noticia_id;

        if (val == 3) {
          Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' .cls-noticia-mostrar-en').css('display', 'block');
        } else {
          Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' .cls-noticia-mostrar-en').css('display', 'none');
        }
      },
      updateNoticia: function updateNoticia(_noticia_k) {
        Ember.$("#modalNoticiaEdicion-" + _noticia_k + " #imagen-cargando").css('display', 'none');
        var self = this;
        var strMessage;
        var strData;
        var tipo_noticia_k = Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' #tipo_noticia').val();
        var comunidad_k = Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' #mostrar_en').val();
        var titulo = Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' #titulo').val();
        var subtitulo = Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' #subtitulo').val();
        var tags = Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' #tags').val(); //var imagen       	= 'uploads/noticias/noticias/' + this.nombreFoto;

        var imagen = this.nombreFoto;
        var imagen_crop = self.imagenCrop;
        var imagen_crop_x = self.imagenCropX;
        var imagen_crop_y = self.imagenCropY;
        var imagen_crop_width = self.imagenCropWidth;
        var imagen_crop_height = self.imagenCropHeight;
        var edicion_imagen = self.edicionImagen; //var imagen          = $('#imagen_seleccionada').attr('src');

        if (titulo == null || titulo.length == 0 || /^\s+$/.test(titulo)) {
          strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_titulo.valor", "Por favor indique el título.");
          alert(strMessage);
          Ember.$('#modalNoticiaEdicion-' + _noticia_k + ' #titulo').focus();
          return false;
        }

        if (subtitulo == null || subtitulo.length == 0 || /^\s+$/.test(subtitulo)) {
          strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_subtitulo.valor", "Por favor indique el subtítulo.");
          alert(strMessage);
          Ember.$("#modalNoticia #subtitulo").focus();
          return false;
        }

        if (tipo_noticia_k == null || tipo_noticia_k.length == 0 || /^\s+$/.test(tipo_noticia_k)) {
          strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_tipo_noticia.valor", "Por favor indique el tipo de noticia.");
          alert(strMessage);
          Ember.$('#modalNoticiaEdicion-' + _noticia_k + ' #tipo_noticia').focus();
          return false;
        }

        if (tipo_noticia_k == 3) {
          if (comunidad_k == null || comunidad_k.length == 0 || /^\s+$/.test(comunidad_k)) {
            strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_comunidad.valor", "Por favor indique la comunidad.");
            alert(strMessage);
            Ember.$('#modalNoticiaEdicion-' + _noticia_k + ' #mostrar_en').focus();
            return false;
          }

          var strComunidad = '"comunidad_k":"' + comunidad_k + '",';
        } else {
          if (comunidad_k == null || comunidad_k == 'Seleciona una opción' || !comunidad_k.isInteger) {
            var strComunidad = '';
          }
        }

        if (this.nombreFoto == null || this.nombreFoto.length == 0 || /^\s+$/.test(this.nombreFoto)) {
          strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_imagen.valor", "Por favor cargue la imagen.");
          alert(strMessage);
          return false;
        }

        if (tags == null || tags.length == 0 || /^\s+$/.test(tags)) {
          strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_tag.valor", "Por favor indique el tag.");
          alert(strMessage);
          Ember.$('#modalNoticiaEdicion-' + _noticia_k + ' #tags').focus();
          return false;
        }

        Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' #noticia').jqte();
        var noticia = Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' #noticia').val();

        if (noticia == null || noticia.length == 0 || /^\s+$/.test(noticia)) {
          strMessage = window.translate("noticias.noticias.msg.msg_noticia_alerta_noticia.valor", "Por favor indique el contenido de la noticia.");
          alert(strMessage);
          Ember.$('#modalNoticiaEdicion-' + _noticia_k + ' #noticia').focus();
          Ember.$("#modalNoticiaEdicion-" + _noticia_k + ' #noticia').jqte();
          return false;
        }

        Ember.$("#modalNoticiaEdicion-" + _noticia_k + " #editarNoticiaButton").css('display', 'none');
        strData = encodeURI('i={"titulo":"' + titulo + '","subtitulo":"' + subtitulo + '","tags":"' + tags + '",' + strComunidad + '"imagen":"' + imagen + '","edicion_imagen":"' + edicion_imagen + '","imagen_crop":"' + imagen_crop + '","imagen_crop_x":"' + imagen_crop_x + '","imagen_crop_y":"' + imagen_crop_y + '","imagen_crop_width":"' + imagen_crop_width + '","imagen_crop_height":"' + imagen_crop_height + '","cuerpo":"","tipo":"' + tipo_noticia_k + '","eliminadas":[]}&cuerpo=' + noticia.replace(/&/g, '%26') + '&llave_k=' + _noticia_k).replace('/[+]/g', '%20').replace('/[=]/g', '%3D').replace('/[:]/g', '%3A').replace('/[/]/', '%2F'), Ember.$.ajax({
          url: constant.APIURL + 'noticia/actualizar',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: encodeURI(strData).replace('/[+]/g', '%20').replace('/[=]/g', '%3D').replace('/[:]/g', '%3A').replace('/[/]/', '%2F'),
          success: function success(response) {
            var r = JSON.parse(response).data;
            Ember.$('#modalNoticiaEdicion-' + _noticia_k + ' .close').click();
            document.location.reload();
          },
          error: function error(response) {
            console.log(response);
            Ember.$("#modalNoticiaEdicion-" + _noticia_k + " #editarNoticiaButton").css('display', 'block');
            var parsedResponse = JSON.parse(response.body[0].innerText);

            if (parsedResponse.error) {
              Ember.$('#modal-avisos .modal-header').text('Error');
              Ember.$('#modal-avisos .modal-body').text(parsedResponse.error);
              Ember.$('#modal-avisos').modal('show');
            } else {
              if (parsedResponse.path.indexOf('http') != 0) {
                parsedResponse.path = constant.BASEURL + parsedResponse.path;
              }

              this.set('imageUploaded', parsedResponse);
            }
          }
        });
      },
      eliminarNoticia: function eliminarNoticia(_noticia_k) {
        Ember.$("#modalNoticiaEliminar-" + _noticia_k).modal({
          backdrop: "static",
          keyboard: false
        });
      },
      deleteNoticia: function deleteNoticia(_noticia_k) {
        Ember.$.ajax({
          url: constant.APIURL + 'noticia/eliminar',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'i=' + _noticia_k + '&llave_k=',
          success: function success(response) {
            var r = JSON.parse(response).data;
            console.log(r);
            Ember.$('.cont-noticia-' + _noticia_k).remove();
            Ember.$('#modal-avisos .modal-header').text('Eliminada');
            Ember.$('#modal-avisos .modal-body').text('La noticia ha sido eliminada');
            Ember.$('#modal-avisos').modal('show'); //Force

            Ember.$('body > .modal-backdrop').remove();
            Ember.$('body').removeClass('modal-open');
            Ember.$("body").html("");
            window.document.location.href = '/#/noticias/0';
            window.location.reload();
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      uploadImage: function uploadImage(file) {
        //this.nombreFoto = file.name;
        //console.log(file);
        get(this, 'uploadPhoto').perform(file);
        Ember.$("#imagen_seleccionada").show();
        Ember.$('.texto-subio-imagen').show();
        Ember.$("#removeImg").show();
        Ember.$("#changeImg").show();
      },
      someActionAfteRender: function someActionAfteRender() {}
    }
  });

  _exports.default = _default;
});