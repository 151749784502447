define("comunidades-nafin/routes/nom-037/mi-expediente", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    _controller: null,
    currentDomicilio: null,
    tabla_historial: null,
    setupController: function setupController(controller) {
      var self = this;
      self._controller = controller;
      self.loadData(controller);
      setTimeout(function () {
        $("#loading").fadeOut(1500);
        controller.set("direcciones", []);
        controller.set("colaborador_header", window.datosPersonales.nombre);
        controller.set("domicilio_visita", "");
        controller.set("id_colaborador_direccion_k", "");
        controller.set("url_google_maps", "");
        controller.set("domicilio", "");
        controller.set("anios", []);
        controller.set("anio_actual", "");
        controller.set("mes_actual", "");
        controller.set("historial", []);
        controller.set("path_pdf", constant.BASEURL);
        controller.set("tipo_documento", "");
        controller.set("lbl_tipo_documento", "");
        controller.set("iframeShowDocument", "");

        self._controller.set('nombre_usuario', window.datosPersonales.nombre);

        self._controller.set("evidencia_usuario_k", window.datosPersonales.usuario_k);

        self.loadData(controller);
        self.cargarApoyos('EQUIPO', 'wrapper_tabequipoasignado');
        self.cargarApoyos('MEDICA', 'wrapper_tabevidenciamedica');
        $(".card-header").removeClass("alert-info");
        $("#audienciaCard").addClass("alert-info"); // En el caso de utilizar los Tabs del nuevo template

        $('.tabs__pane').css({
          display: 'none'
        });
        $('.is-active').css({
          display: 'block'
        });
        $(".tabs__button").unbind().click(function () {
          var elemento = $(this);
          var tab = elemento.data('tab-target');
          $('.tabs__pane').css({
            display: 'none'
          });
          $(tab).css({
            display: 'block'
          });
        });
      }, 1000);
    },
    loadData: function loadData(controller) {
      var self = this;
      /* Carga de Domicilios */

      self.carga_domicilios(controller);
      /* Listado de años */

      self.obtiene_anios(controller);
      $("#recibos_modal").on("hidden.bs.modal", function () {
        self.carga_domicilios(self._controller);
      });
      $('#nav-tab button').on('click', function (event) {
        event.preventDefault();

        if ($(this).attr('id') == 'nav-historial-tab') {
          var id_colaborador_direccion_k = $("#recibos_modal").data("id_colaborador_direccion_k"),
              tipo = $("#recibos_modal").data("tipo");

          if (self.tabla_historial != null) {
            self.tabla_historial.destroy();
          }

          self.carga_historial(tipo, id_colaborador_direccion_k, controller);
        }

        $(this).tab('show');
      });

      if (window.matchMedia("(max-width: 767px)").matches) {
        $("#map-search").attr('size', 20);
      }

      ; // Cagramos el Contrato y politica del usuario

      this.cargarPDF("previewContratoIFrame", "CONTRATO");
      this.cargarPDF("previewPoliticaIFrame", "POLITICA"); // Ocultamos el nombre del colaborador en el formulario de apoyo

      $('#createUpdateFormApoyo .modal-body .form-group:first-child').hide(); // Asignación de equipo

      $(".tab-content").on("click", ".asignaciondeequipo", function () {
        console.log('asignacion de equipo');

        self._controller.set('updateStore_apoyo', self.store.createRecord('nom-035-stps/acciones-internas/evidencia', {}));

        self._controller.set('createDeleteCommand', 'create');

        self._controller.set('isVisibleCreateUpdateForm', true);

        self._controller.set('isCommandUpdate', false);

        self._controller.set('lbl_form', 'Asignación de equipo');

        self._controller.set('lbl_campo', 'Apoyo'); // self._controller.set( 'nombre_usuario',              $(this).data("nombre"));
        // self._controller.set( 'evidencia_usuario_k',         $(this).data("usuariok"));


        self._controller.set('tipo_evidencia', 'equipo');

        $('#button-save-changes-apoyo').attr("disabled", "disabled");
        $("#documentoNombre").val('');
        $("#documentoRuta").val('');
        $('#filesattached_apoyo').html('');
        $("#apoyo_nombre").val('');
        $("#apoyo_descripcion").val('');
        $("#createUpdateFormApoyo").modal({});
      }); // Evidencia medica

      $(".tab-content").on("click", ".evidenciamedica", function () {
        self._controller.set('updateStore_apoyo', self.store.createRecord('nom-035-stps/acciones-internas/evidencia', {}));

        self._controller.set('createDeleteCommand', 'create');

        self._controller.set('isVisibleCreateUpdateForm', true);

        self._controller.set('isCommandUpdate', false);

        self._controller.set('lbl_form', 'Evidencia medica');

        self._controller.set('lbl_campo', 'Evidencia medica'); // self._controller.set( 'nombre_usuario',              $(this).data("nombre"));
        // self._controller.set( 'evidencia_usuario_k',         $(this).data("usuariok"));


        self._controller.set('tipo_evidencia', 'medica');

        $('#button-save-changes-apoyo').attr("disabled", "disabled");
        $("#documentoNombre").val('');
        $("#documentoRuta").val('');
        $('#filesattached').html('');
        $("#apoyo_nombre").val('');
        $("#apoyo_descripcion").val('');
        $("#createUpdateFormApoyo").modal("show");
      });
    },
    carga_domicilios: function carga_domicilios(controller) {
      $.ajax({
        url: constant.APIURL + "nom037/administrador/direccionesUsuario",
        type: "post",
        data: {
          usuario_k: window.datosPersonales.usuario_k
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            controller.set("direcciones", response.data);
          }
        }
      });
    },
    obtiene_anios: function obtiene_anios(controller) {
      var fecha_actual = new Date(),
          anio_actual = fecha_actual.getFullYear(),
          anio_anterior = anio_actual - 1,
          anios = [];
      anios.push(anio_actual);
      anios.push(anio_anterior);
      controller.set("anios", anios);
      controller.set("anio_actual", anio_actual);
      controller.set("mes_actual", fecha_actual.getMonth() + 1);
    },
    getPeriodo: function getPeriodo() {
      var fecha = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var datos_fecha = {
        anio: null,
        mes: null
      };

      if (fecha != '') {
        var datos = fecha.split("-");
        datos_fecha.anio = datos[0];
        datos_fecha.mes = datos[1];
      }

      return datos_fecha;
    },
    carga_historial: function carga_historial() {
      var tipo = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var id_colaborador_direccion_k = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var controller = arguments.length > 2 ? arguments[2] : undefined;
      var self = this;
      $.ajax({
        url: constant.APIURL + "nom037/administrador/comprobantesHistorial",
        type: "post",
        data: {
          usuario_k: window.datosPersonales.usuario_k,
          tipo: tipo,
          id_colaborador_direccion_k: id_colaborador_direccion_k
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        async: false,
        success: function success(response) {
          if (response.success == true) {
            controller.set("historial", response.data);
            setTimeout(function () {
              /*
              self.tabla_historial = $("#tabla_historial").DataTable({
                  language: {
                      "decimal": "",
                      "emptyTable": "Sin registros",
                      "info": "Mostrando del _START_ al  _END_ de _TOTAL_ Registros",
                      "infoEmpty": "Mosrando 0 to 0 de 0 registros",
                      "infoFiltered": "(Filtro _MAX_ registros totales)",
                      "infoPostFix": "",
                      "thousands": ",",
                      "lengthMenu": "Mostrar _MENU_ registros",
                      "loadingRecords": "Cargando...",
                      "processing": "",
                      "search": "Buscar:",
                      "zeroRecords": "Sin coincidencias",
                      "paginate": {
                          "first": "Primero",
                          "last": "Último",
                          "next": "Siguiente",
                          "previous": "Anterior"
                      },
                      "aria": {
                          "sortAscending": ": activate to sort column ascending",
                          "sortDescending": ": activate to sort column descending"
                      }
                  },
                  info: false,
                  paging: false,
                  scrollCollapse: true,
                  scrollY: '400px',
                  ordering: false
              });
              */
            }, 300);
          }
        }
      });
    },
    cargarPDF: function cargarPDF(previewDocumentIFrame, tipo_documento) {
      var self = this;
      $.ajax({
        url: constant.APIURL + 'nom037/evidenciadocumentacion/obtenerarchivo',
        type: "post",
        async: false,
        data: {
          usuario_k: window.datosPersonales.usuario_k,
          tipo_documento: tipo_documento
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            console.log('response.data.url_download ', response.data.url_download);

            if (response.data.url_download != '') {
              var url = response.data.url_download;
              var extension = url.split(".")[url.split(".").length - 1];
              var extensiones = ['pdf'];

              if (extensiones.includes(extension)) {
                $("#" + previewDocumentIFrame).attr("src", url);
              } else {
                showModal({
                  titulo: 'No se puede previsualizr el documento.',
                  mensaje: 'Solamente se puede previsualizar documentos PDF.'
                });
              }
            } else {
              $("#" + previewDocumentIFrame).attr("srcdoc", "<p>No se ha cargado el documento correspondiente.</p>");
            }
          } else {
            window.showModal({
              titulo: 'Error en sistema.',
              mensaje: !window.isEmpty(response.error) ? response.error : 'Ups! acaba de suceder algo inesperado. Por favor intente nuevamente en otro momento.'
            });
          }
        },
        error: function error(e) {
          window.showModalError();
          console.log(e);
        }
      });
    },
    cargarApoyos: function cargarApoyos(tipo_evidencia, wrapper) {
      var div = $('#' + wrapper).addClass('loading').text('Cargando...');
      $.ajax({
        url: constant.APIURL + 'nom037/administrador/detalle_apoyo',
        type: "post",
        data: {
          usuario_k: window.datosPersonales.usuario_k,
          tipo_evidencia: tipo_evidencia,
          vista: 'usuario'
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        success: function success(html) {
          div.html(html).removeClass('loading');
        }
      });
    },
    actions: {
      loadMap: function loadMap(d) {
        var latitud = 19.4136491,
            longitud = -99.1033026,
            zoom = 9;

        if (typeof d !== "undefined") {
          if (d.id_colaborador_direccion_k > 0) {
            $(".btn-add-domicilio").addClass("invisible");
            $("#map-search").val(d.domicilio);
            $("#latitude").val(d.latitud);
            $("#longitude").val(d.longitude);
            $("#id_colaborador_direccion_k").val(d.id_colaborador_direccion_k);
            latitud = d.latitud;
            longitud = d.longitud;
            zoom = 20;
          }
        } else {
          $("#map-search").val("");
          $("#latitude").val("");
          $("#longitude").val("");
          $("#valid_address").val(0);
          $("#id_colaborador_direccion_k").val(0);
        }

        $("#table-direcciones").addClass("d-none");
        $("#mapa").removeClass("d-none");
        var mapOptions,
            map,
            marker,
            searchBox,
            city,
            infoWindow = '',
            addressEl = document.querySelector('#map-search'),
            latEl = document.querySelector('.latitude'),
            longEl = document.querySelector('.longitude'),
            element = document.getElementById('map-canvas');
        city = document.querySelector('.reg-input-city');
        mapOptions = {
          // How far the maps zooms in.
          zoom: zoom,
          // Current Lat and Long position of the pin/
          center: new google.maps.LatLng(latitud, longitud),
          // center : {
          // 	lat: -34.397,
          // 	lng: 150.644
          // },
          disableDefaultUI: false,
          // Disables the controls like zoom control on the map if set to true
          scrollWheel: true,
          // If set to false disables the scrolling on the map.
          draggable: true // If set to false , you cannot move the map around.
          // mapTypeId: google.maps.MapTypeId.HYBRID, // If set to HYBRID its between sat and ROADMAP, Can be set to SATELLITE as well.
          // maxZoom: 11, // Wont allow you to zoom more than this
          // minZoom: 9  // Wont allow you to go more up.

        };
        /**
         * Creates the map using google function google.maps.Map() by passing the id of canvas and
         * mapOptions object that we just created above as its parameters.
         *
         */
        // Create an object map with the constructor function Map()

        map = new google.maps.Map(element, mapOptions); // Till this like of code it loads up the map.

        /**
         * Creates the marker on the map
         *
         */

        marker = new google.maps.Marker({
          position: mapOptions.center,
          map: map,
          // icon: 'http://pngimages.net/sites/default/files/google-maps-png-image-70164.png',
          draggable: true
        });
        /**
         * Creates a search box
         */

        searchBox = new google.maps.places.SearchBox(addressEl);
        /**
         * When the place is changed on search box, it takes the marker to the searched location.
         */

        google.maps.event.addListener(searchBox, 'places_changed', function () {
          var places = searchBox.getPlaces(),
              bounds = new google.maps.LatLngBounds(),
              i,
              place,
              lat,
              long,
              resultArray,
              addresss = places[0].formatted_address;

          for (i = 0; place = places[i]; i++) {
            bounds.extend(place.geometry.location);
            marker.setPosition(place.geometry.location); // Set marker position new.
          }

          map.fitBounds(bounds); // Fit to the bound

          map.setZoom(15); // This function sets the zoom to 15, meaning zooms to level 15.

          lat = marker.getPosition().lat();
          long = marker.getPosition().lng();
          latEl.value = lat;
          longEl.value = long;
          resultArray = places[0].address_components; // Get the city and set the city input value to the one selected

          for (var i = 0; i < resultArray.length; i++) {
            if (resultArray[i].types[0] && 'administrative_area_level_2' === resultArray[i].types[0]) {
              citi = resultArray[i].long_name;
              city.value = citi;
            }
          } // Closes the previous info window if it already exists


          if (infoWindow) {
            infoWindow.close();
          }
          /**
           * Creates the info Window at the top of the marker
           */


          infoWindow = new google.maps.InfoWindow({
            content: addresss
          });
          infoWindow.open(map, marker);
        });
        /**
         * Finds the new position of the marker when the marker is dragged.
         */

        google.maps.event.addListener(marker, "dragend", function (event) {
          var lat, long, address, resultArray, citi;
          lat = marker.getPosition().lat();
          long = marker.getPosition().lng();
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({
            latLng: marker.getPosition()
          }, function (result, status) {
            if ('OK' === status) {
              // This line can also be written like if ( status == google.maps.GeocoderStatus.OK ) {
              address = result[0].formatted_address;
              resultArray = result[0].address_components; // Get the city and set the city input value to the one selected

              for (var i = 0; i < resultArray.length; i++) {
                if (resultArray[i].types[0] && 'administrative_area_level_2' === resultArray[i].types[0]) {
                  citi = resultArray[i].long_name;
                  city.value = citi;
                }
              }

              addressEl.value = address;
              latEl.value = lat;
              longEl.value = long;
            } else {
              console.log('Geocode was not successful for the following reason: ' + status);
            } // Closes the previous info window if it already exists


            if (infoWindow) {
              infoWindow.close();
            }
            /**
             * Creates the info Window at the top of the marker
             */


            infoWindow = new google.maps.InfoWindow({
              content: address
            });
            infoWindow.open(map, marker);
          });
        });
      },
      cancelNewAddress: function cancelNewAddress() {
        $("#table-direcciones").removeClass("d-none");
        $("#mapa").addClass("d-none");
        $("#map-search").val('');
        $("#mapa #longitude").val('');
        $("#mapa #latitude").val('');
        $(".btn-add-domicilio").removeClass("invisible");
      },
      saveAddress: function saveAddress() {
        var self = this;
        var id_colaborador_direccion_k = $("#id_colaborador_direccion_k").val();
        var url = id_colaborador_direccion_k > 0 ? "nom037/administrador/editarDireccion" : "nom037/administrador/guardarDireccion";
        var data = {
          "usuario_k": window.datosPersonales.usuario_k,
          "domicilio": $("#map-search").val(),
          "longitud": $("#mapa #longitude").val(),
          "latitud": $("#mapa #latitude").val(),
          "url_google_maps": "https://www.google.com.mx/maps/place/" + $("#map-search").val() + "/@" + $("#mapa #longitude").val() + "," + $("#mapa #latitude").val(),
          "id_colaborador_direccion_k": id_colaborador_direccion_k
        };
        $.ajax({
          url: constant.APIURL + url,
          type: "post",
          data: data,
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              $("#map-search").val('');
              $("#mapa #longitude").val('');
              $("#mapa #latitude").val('');

              self._controller.set("direcciones", response.data);

              self.send("cancelNewAddress");
            }
          }
        });
      },
      uploadEvidencesInternet: function uploadEvidencesInternet(d, tipo) {
        var self = this;
        $("#recibos_modal").modal("show");
        $("#lbl_evidencia").html("");
        $("#recibos_modal").data("id_colaborador_direccion_k", d.id_colaborador_direccion_k);
        $("#recibos_modal").data("tipo", tipo); // En caso de que se tenga el periodo, se coloca en los select

        var datos_fecha_luz = self.getPeriodo(d.periodo_luz),
            datos_fecha_internet = self.getPeriodo(d.periodo_internet);
        $("#recibo_periodo_mes").val("");
        $("#recibo_periodo_anio").val(self._controller.get("anio_actual"));
        var urlRecibo = false;

        if (tipo == "luz") {
          urlRecibo = d.documento_luz != null ? d.documento_luz : false;

          if (d.periodo_luz != '') {
            $("#recibo_periodo_mes").val(datos_fecha_luz.mes);
            $("#recibo_periodo_anio").val(datos_fecha_luz.anio);
          }
        } else {
          urlRecibo = d.documento_internet != null ? d.documento_internet : false;

          if (d.periodo_internet != '') {
            $("#recibo_periodo_mes").val(datos_fecha_internet.mes);
            $("#recibo_periodo_anio").val(datos_fecha_internet.anio);
          }
        }

        if (urlRecibo) {
          $("#ifr-recibo").attr("src", constant.BASEURL + urlRecibo); // En caso de que se tenga un documento cargado, se muestra

          $("#wrap_evidencias").removeClass("d-none");
          $("#carga_evidencia").addClass("d-none");
        } else {
          $("#ifr-recibo").attr("src", "");
          $("#wrap_evidencias").addClass("d-none");
          $("#carga_evidencia").removeClass("d-none");
        }

        $("#recibo_periodo_mes").on('change', function () {
          var recibo_periodo_mes = $(this).val(),
              recibo_periodo_anio = $("#recibo_periodo_anio").val();
          $("#wrap_evidencias").addClass("d-none");
          $("#carga_evidencia").removeClass("d-none");

          if (recibo_periodo_mes != '' && recibo_periodo_anio != '') {
            $("#wrap_evidencias").removeClass("d-none");
            $("#carga_evidencia").addClass("d-none");
          }
        });
        $("#recibo_periodo_anio").on('change', function () {
          var recibo_periodo_anio = $(this).val(),
              recibo_periodo_mes = $("#recibo_periodo_mes").val();
          $("#wrap_evidencias").addClass("d-none");
          $("#carga_evidencia").removeClass("d-none");

          if (recibo_periodo_mes != '' && recibo_periodo_anio != '') {
            $("#wrap_evidencias").removeClass("d-none");
            $("#carga_evidencia").addClass("d-none");
          }
        }); // Cargamos el historial de comprobantes cargados
        // self.carga_historial( tipo, d.id_colaborador_direccion_k, self._controller );

        $("#lbl_evidencia").html(" de " + tipo.toUpperCase());
        $('#nav-tab .nav-link:first-child').tab('show');
        return false;
      },
      uploadEvidences: function uploadEvidences(d) {
        var self = this;
        $("#loading").fadeIn(500);
        $("#evidencias_modal").data("id_colaborador_direccion_k", d.id_colaborador_direccion_k);
        $.ajax({
          url: constant.APIURL + "nom037/administrador/listarEvidencias",
          type: "post",
          data: {
            id_colaborador_direccion_k: d.id_colaborador_direccion_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              localStorage.setItem("prevEvidences", JSON.stringify(response.data));
              $("#evidencias_modal").modal("show");
              $("#evidencias_modal").on("hidden.bs.modal", function () {
                /* self.controller.currentDomicilio.click(); */
              });
            }
          }
        });
        return false;
      },
      afterCreate: function afterCreate(dataObject) {},
      afterUpdate: function afterUpdate(dataObject) {},
      showCreateModal: function showCreateModal() {
        var tipo_documento = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        var self = this;

        self._controller.set('updateStore', this.store.createRecord('nom-035-stps/acciones-internas/evidencia', {}));

        self._controller.set('createDeleteCommand', 'create');

        self._controller.set('isVisibleCreateUpdateForm', true);

        self._controller.set('isCommandUpdate', false);

        self._controller.set('tipo_documento', tipo_documento);

        if (tipo_documento == 'contrato') {
          self._controller.set("lbl_tipo_documento", "Contrato");

          self._controller.set("iframeShowDocument", "previewContratoIFrame");
        }

        if (tipo_documento == 'politica') {
          self._controller.set("lbl_tipo_documento", "Política");

          self._controller.set("iframeShowDocument", "previewPoliticaIFrame");
        }

        $('#button-save-changes').attr("disabled", "disabled");
        $("#politicasDeRiesgoK").val(null);
        $("#politicasDeRiesgoNombreDocumento").val('');
        $('#filesattached').html('');
        $('#createUpdateFormRiskEvidencias').modal({});
      }
    }
  });

  _exports.default = _default;
});