define("comunidades-nafin/routes/lms/administracion-usuarios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    params: {},
    _controller: null,
    headers: [{
      dataIndex: 'nombre_completo',
      title: 'Nombre'
    }, {
      dataIndex: 'email',
      title: 'Email'
    }, {
      dataIndex: 'datos_empresa',
      title: 'Datos de Empresa'
    },
    /*{ dataIndex: 'centro', title: 'Centro' },
    { dataIndex: 'departamento', title: 'Departamento' },
    { dataIndex: 'puesto', title: 'Puesto' }, */
    {
      dataIndex: 'username',
      title: 'Username'
    }, {
      dataIndex: 'rol',
      title: 'Rol(s)'
    }, {
      dataIndex: 'estatus',
      title: 'Estatus'
    }],
    idiomaTable: {
      "decimal": "",
      "emptyTable": "Sin registros",
      "info": "Mostrando del _START_ al  _END_ de _TOTAL_ Registros",
      "infoEmpty": "Mosrando 0 to 0 de 0 registros",
      "infoFiltered": "(Filtro _MAX_ registros totales)",
      "infoPostFix": "",
      "thousands": ",",
      "lengthMenu": "Mostrar _MENU_ registros",
      "loadingRecords": "Cargando...",
      "processing": "",
      "search": "Buscar:",
      "zeroRecords": "Sin coincidencias",
      "paginate": {
        "first": "Primero",
        "last": "Último",
        "next": "Siguiente",
        "previous": "Anterior"
      },
      "aria": {
        "sortAscending": ": activate to sort column ascending",
        "sortDescending": ": activate to sort column descending"
      }
    },
    config_table_usuarios: null,
    beforeModel: function beforeModel() {
      if (!window.constant.BAND_RELOAD) {
        $("body").html("");
        window.location.reload();
      }
    },
    setupController: function setupController(controller) {
      var self = this;
      self._controller = controller;
      setTimeout(function () {
        self.loadData(controller);
        controller.set('headersTable', self.get("headers"));
        controller.set('sucursal_usuario', "");
      }, 1000);
    },
    loadData: function loadData(controller) {
      var self = this;
      /* Carga de USUARIOS */

      self.carga_de_usuarios(controller);
      /* Carga de CENTROS */

      self.carga_sucursales(controller);
      /* Carga de DEPARTAMENTO */

      self.carga_departamento(controller);
      /* Carga de PUESTOS */

      self.carga_puestos(controller);
      /* Carga de COMUNIDADES */

      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/listarcomunidades',
        type: 'POST',
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('comunidades', response.data);
          controller.set('total_comunidad', response.numFilas);
          /* 
              Si la Empresa solo cuenta con una comunidad, no se muestra en el formulario y los usuarios se daran de alta con esa comunidad
           */

          if (response.numFilas > 1) {
            controller.set('isVisibleComunidades', '');
            controller.set('selectedComunidades', true);
          } else {
            controller.set('isVisibleComunidades', 'd-none');
            controller.set('selectedComunidades', false);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
      /* Carga de ROLES */

      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/listarroles',
        type: 'POST',
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('roles', response.data);

          if (response.numFilas > 0) {
            controller.set('error_empresassoluciones', false);
          } else {
            controller.set('error_empresassoluciones', true);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
      /* Solo ingresar minusculas */

      $(".solominusculas").keyup(function () {
        $(this).val($(this).val().toLowerCase());
      });
      /* Solo ingresar mayusculas */

      $(".solomayusculas").keyup(function () {
        $(this).val($(this).val().toUpperCase());
      });
      $('#nuevoUsuarioModal, #nuevoElementoModal').modal({
        show: false,
        keyboard: false,
        backdrop: 'static'
      });
      $('#nuevoUsuarioModal').on('shown.bs.modal', function () {
        $('#frm_nombre').trigger('focus');
      });
      $('#nuevoUsuarioModal').on('hidden.bs.modal', function (e) {
        self.form_nuevousuario_removeinvalid();
        self.form_nuevousuario_clearinput();
      });
      $("#frm_correo").on("keyup change", function (e) {
        $("#frm_username").val($(this).val());
      }); // Agregamos una nueva Sucursal en caso de seleccionar la opción 'OTRO'

      $("#frm_sucursal, #frm_departamento, #frm_puesto").on("change", function (e) {
        var sucursal = $(this).val().trim(),
            tipo_elemento = $(this).attr("data-tipo");

        if (sucursal === 'OTRO') {
          $('#nuevoElementoModal').modal('show');
          $('.lbl_nuevo_elemento').html(tipo_elemento);
          $('#tipoElemento').val(tipo_elemento);
        }
      });
      $('#nuevoElementoModal').on('hidden.bs.modal', function (e) {
        self.form_nuevoelemento_clearinput();
      });
      $('#nuevoElementoModal').on('shown.bs.modal', function () {
        $('#frm_elemento_nombre').trigger('focus');
      });
    },
    carga_de_usuarios: function carga_de_usuarios(controller) {
      var self = this;
      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/listarusuarios',
        type: 'POST',
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('dataTable', response.data);
          setTimeout(function () {
            self.config_table_usuarios = $("#usuariosTable").DataTable({
              language: self.idiomaTable,
              scrollY: '480px',
              scrollCollapse: true,
              paging: false
            });
            $("#loading").fadeOut(1000);
          }, 1000);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    carga_sucursales: function carga_sucursales(controller) {
      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/listarsucursales',
        type: 'POST',
        data: {},
        async: false,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('sucursales', response.data);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    carga_departamento: function carga_departamento(controller) {
      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/listardepartamentos',
        type: 'POST',
        data: {},
        async: false,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('departamentos', response.data);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    carga_puestos: function carga_puestos(controller) {
      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/listarpuestos',
        type: 'POST',
        data: {},
        async: false,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('puestos', response.data);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    form_nuevousuario_clearinput: function form_nuevousuario_clearinput() {
      $('#form_nuevousuario').trigger("reset");
    },
    form_nuevoelemento_clearinput: function form_nuevoelemento_clearinput() {
      $('#form_nuevoelemento').trigger("reset");
    },
    form_nuevousuario_removeinvalid: function form_nuevousuario_removeinvalid() {
      $("#form_nuevousuario input[type='text']").removeClass("is-invalid");
      $("#form_nuevousuario select").removeClass("is-invalid");
      $(".frm_rol").each(function () {
        $(this).removeClass("is-invalid");
      });
    },
    actions: {
      guardarDatos: function guardarDatos() {
        var self = this,
            regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
            error = false,
            inputerror = [];
        self.form_nuevousuario_removeinvalid();
        var nombre = $("#frm_nombre").val().trim(),
            ap_paterno = $("#frm_apellido_paterno").val().trim(),
            ape_materno = $("#frm_apellido_materno").val().trim(),
            genero = $("#frm_genero").val().trim(),
            email = $("#frm_correo").val().trim(),
            sucursal = $("#frm_sucursal").val().trim(),
            departamento = $("#frm_departamento").val().trim(),
            puesto = $("#frm_puesto").val().trim(),
            total_comunidades = parseInt($("#total_comunidades").val()),
            comunidad = $("#frm_comunidad").val().trim(),
            username = $("#frm_username").val().trim(),
            estatus = 1,
            // $("#frm_estatus").val().trim()
        roles = []; // Validaciones del formulario

        if (nombre == "" || nombre.length <= 3) {
          inputerror.push("#frm_nombre");
          error = true;
        }

        if (ap_paterno == "" || ap_paterno.length <= 3) {
          inputerror.push("#frm_apellido_paterno");
          error = true;
        }

        if (ape_materno == "" || ape_materno.length <= 3) {
          inputerror.push("#frm_apellido_materno");
          error = true;
        }

        if (genero == "") {
          inputerror.push("#frm_genero");
          error = true;
        }

        if (!regex.test(email) || email == "") {
          inputerror.push("#frm_correo");
          error = true;
        }

        if (sucursal == "" || sucursal == "OTRO") {
          inputerror.push("#frm_sucursal");
          error = true;
        }

        if (departamento == "" || departamento == "OTRO") {
          inputerror.push("#frm_departamento");
          error = true;
        }

        if (puesto == "" || puesto == "OTRO") {
          inputerror.push("#frm_puesto");
          error = true;
        }

        if (username == "" || username.length <= 3) {
          inputerror.push("#frm_username");
          error = true;
        } // Validamos la comunidad


        if (total_comunidades > 1 && comunidad == "") {
          inputerror.push("#frm_comunidad");
          error = true;
        } else if (total_comunidades === 0) {
          // Empresa sin comunidad
          showModal({
            titulo: 'Comunidad.',
            mensaje: 'Es nesesario tener una comunidad asociada.'
          });
          error = true;
          return false;
        } // Validamos el Rol


        $(".frm_rol:checked").each(function () {
          roles.push($(this).val());
        });

        if (roles.length === 0) {
          $(".frm_rol").each(function () {
            $(this).addClass("is-invalid");
          });
          error = true;
        } // Mostramos los primeros errores al usuario


        if (error) {
          inputerror.forEach(function (valor, indice, array) {
            if (indice === 0) {
              $(valor).focus();
            }

            $(valor).addClass("is-invalid");
          });
          showModal({
            titulo: 'Validación de campos.',
            mensaje: 'Verifica que todos los campos estén llenos correctamente.'
          });
          return false;
        }

        $("#loading").show(); // Enviamos parametros

        if (!error) {
          var datos = new FormData();
          datos.append('nombre', nombre);
          datos.append('ap_paterno', ap_paterno);
          datos.append('ape_materno', ape_materno);
          datos.append('genero', genero);
          datos.append('email', email);
          datos.append('sucursal', sucursal);
          datos.append('departamento', departamento);
          datos.append('puesto', puesto);
          datos.append('comunidad', comunidad);
          datos.append('username', username);
          datos.append('estatus', estatus);
          datos.append('roles', roles);
          $.ajax({
            url: constant.APIURL + 'lms/administradorusuarios/altadeusuario',
            type: 'POST',
            data: datos,
            processData: false,
            contentType: false,
            xhrFields: {
              withCredentials: true
            },
            success: function success(html) {
              $("#loading").fadeOut(1000);
              var response = JSON.parse(html);

              if (response.band) {
                self.config_table_usuarios.destroy(); // Destruimos la configuración de la tabla

                self.carga_de_usuarios(self._controller); // Cargamos los usuarios

                self.form_nuevousuario_clearinput(); // Limpiamos el formulario

                $('#nuevoUsuarioModal').modal('hide'); // Ocultamos el formulario de alta

                showModal({
                  titulo: 'Alta de Usuario.',
                  mensaje: "El usuario ha sido dado de alta correctamente.<br>Los <b>ACCESOS</b> a la plataforma fueron enviados al correo registrado."
                });
              } else {
                showModal({
                  titulo: 'Error en el alta de usuario.',
                  mensaje: response.msj
                });
              }
            },
            error: function error(e) {
              console.log(e);
            }
          });
        }
      },
      cerrar_elemento: function cerrar_elemento() {
        var tipo = $("#tipoElemento").val().trim(),
            self = this;

        if (tipo === 'SUCURSAL') {
          $("#frm_sucursal").prop('selectedIndex', 0);
        } else if (tipo === 'DEPARTAMENTO') {
          $("#frm_departamento").prop('selectedIndex', 0);
        } else if (tipo === 'PUESTO') {
          $("#frm_puesto").prop('selectedIndex', 0);
        }

        self.form_nuevoelemento_clearinput();
        $('#nuevoElementoModal').modal('hide');
      },
      agregar_elemento: function agregar_elemento() {
        var nombre = $("#frm_elemento_nombre").val().trim(),
            tipo = $("#tipoElemento").val().trim(),
            url = '',
            self = this,
            datos = new FormData(); // Validacion del Nombre

        if (nombre.length === 0) {
          showModal({
            titulo: 'Validación de campos.',
            mensaje: 'Es necesario ingresar el nombre.'
          });
          return false;
        } // Validacion del Nombre


        if (nombre === 'OTRO') {
          showModal({
            titulo: 'Validación de campos.',
            mensaje: 'Ingrese un Nombre para el registro valido.'
          });
          return false;
        } // Definimos URL para la alta del elemento


        if (tipo === 'SUCURSAL') {
          url = constant.APIURL + 'lms/administradorusuarios/altasucursal';
        } else if (tipo === 'DEPARTAMENTO') {
          url = constant.APIURL + 'lms/administradorusuarios/altadepartamento';
        } else if (tipo === 'PUESTO') {
          url = constant.APIURL + 'lms/administradorusuarios/altapuesto';
        }

        datos.append('nombre', nombre);
        $.ajax({
          url: url,
          type: 'POST',
          data: datos,
          processData: false,
          contentType: false,
          xhrFields: {
            withCredentials: true
          },
          success: function success(html) {
            var response = JSON.parse(html);

            if (response.band) {
              self.form_nuevoelemento_clearinput(); // Limpiamos el formulario

              $('#nuevoElementoModal').modal('hide'); // Ocultamos el formulario de alta

              if (tipo === 'SUCURSAL') {
                self.set('sucursal_usuario', nombre);
                self.carga_sucursales(self._controller); // $('#frm_sucursal').val( nombre );
              } else if (tipo === 'DEPARTAMENTO') {
                self.carga_departamento(self._controller);
                $('#frm_departamento').val(nombre);
              } else if (tipo === 'PUESTO') {
                self.carga_puestos(self._controller); // $('#frm_puesto').val( nombre );

                $('#frm_puesto option[value=' + nombre + ']').attr('selected', 'selected');
              }

              showModal({
                titulo: 'Alta de ' + tipo,
                mensaje: "Registro dado de alta correctamente."
              });
            } else {
              showModal({
                titulo: 'Error en el alta de ' + tipo,
                mensaje: response.msj
              });
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    }
  });

  _exports.default = _default;
});