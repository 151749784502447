define("comunidades-nafin/components/nom-035-stps/reportes/cards/audiencia-card", ["exports", "comunidades-nafin/components/human-talent-components/h-t-card"], function (_exports, _hTCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTCard.default.extend({
    data: {
      total: 0,
      data: {
        aplicados: 0,
        restantes: 0,
        hombres: {
          total: 0,
          data: {
            aplicados: 0,
            restante: 0
          }
        },
        mujeres: {
          total: 0,
          data: {
            aplicados: 0,
            restante: 0
          }
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('data', this.data);
    },
    didInsertElement: function didInsertElement() {
      // Colocamos activa la tarjeta seleccionada
      var url = $(location).attr('href');
      var parts = url.split("/");
      var modulo = parts.pop();

      if (modulo === 'audiencia') {
        $("#audienciaCard > .wrap_nom_hover").attr('style', 'opacity: 1 !important;');
        $("#audienciaCard > .items-center > p").attr('style', 'color: #FFFFFF; !important;');
        $("#audienciaCard .lbl_principal").attr('style', 'color: #FFFFFF !important;');
        $("#audienciaCard .text-theme").attr('style', 'color: #FFFFFF !important;');
      }
    },
    actions: {}
  });

  _exports.default = _default;
});