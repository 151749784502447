define("comunidades-nafin/routes/nom-037/evidencias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      console.log('window.constant.BAND_RELOAD 1: ', window.constant.BAND_RELOAD);

      if (!window.constant.BAND_RELOAD) {
        $("body").html("");
        window.location.reload();
      }
    },
    setupController: function setupController(controller) {
      this._super(controller);

      console.log('mod principal');
      var periodo_k = localStorage.getItem("periodo_nom037_k");
      /* Obtener PERIDO_K de la Empresa */

      controller.set('periodo_k', periodo_k); // Validamos si mostramos el Menu principal

      if (window.location.hash === '#/nom-037/evidencias/0') {
        controller.set('band_menu_principal', true);
        controller.set('nombre_modulo', null);
      } else {
        controller.set('band_menu_principal', false);
        controller.set('nombre_modulo', localStorage.getItem('nombre_modulo'));
      }
      /* Funciones del menu lateral */


      setTimeout(function () {
        $(".elemento").click(function () {
          $(".arrowRight").addClass("d-none");
          $(".elemento").removeClass("active");
          $(".elemento").parent().removeClass("text-right");
          $(this).addClass("active");
          $(this).parent().addClass("text-right");
          $(this).find(".arrowRight").removeClass("d-none");
          $("#card-header-open").text($(this).text());
        });
        $("#opcion-evidencias").change(function () {
          window.location.href = $(this).find("option:selected").data("url");
        });

        if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
          $("#contenedorEvidencia").removeAttr("style");
        }
        /* Mostramos menu dashboard en caso de movil 
        if (window.innerWidth <= 768) 
        {
            $('#btn_menu_dashboard').show();
        }
        else
        {
            $('#btn_menu_dashboard').css( 'cssText', 'display: none !important;' );
        }
        */


        $('#loading').fadeOut('slow');
      }, 1000);
    },
    actions: {
      goPoliticaTeletrabajo: function goPoliticaTeletrabajo(nombre_modulo) {
        $("body").html("");
        this.controller.set('band_menu_principal', false);
        this.controller.set('nombre_modulo', nombre_modulo);
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-037/evidencias/0/politicas-de-riesgo/' + periodo_k;
        window.location.reload();
      },
      goRegistroPolitica: function goRegistroPolitica(nombre_modulo) {
        $("body").html("");
        this.controller.set('band_menu_principal', false);
        this.controller.set('nombre_modulo', nombre_modulo);
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-037/evidencias/0/politicas-de-riesgo-visitas/' + periodo_k;
        window.location.reload();
      },
      goBuzonQuejas: function goBuzonQuejas(nombre_modulo) {
        $("body").html("");
        this.controller.set('band_menu_principal', false);
        this.controller.set('nombre_modulo', nombre_modulo);
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-037/evidencias/0/consultas/' + periodo_k;
        document.location.reload();
      },
      goCapacitacion: function goCapacitacion(nombre_modulo) {
        $("body").html("");
        this.controller.set('band_menu_principal', false);
        this.controller.set('nombre_modulo', nombre_modulo);
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-037/evidencias/0/estatus-de-capacitacion/' + periodo_k;
        document.location.reload();
      },
      goResguardoEquipos: function goResguardoEquipos(nombre_modulo) {
        $("body").html("");
        this.controller.set('band_menu_principal', false);
        this.controller.set('nombre_modulo', nombre_modulo);
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-037/evidencias/0/resguardo-de-equipos/' + periodo_k;
        document.location.reload();
      },
      goProgramaMantenimiento: function goProgramaMantenimiento(nombre_modulo) {
        $("body").html("");
        this.controller.set('band_menu_principal', false);
        this.controller.set('nombre_modulo', nombre_modulo);
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-037/evidencias/0/programa-de-mantenimiento/' + periodo_k;
        document.location.reload();
      },
      goContratoColectivo: function goContratoColectivo(nombre_modulo) {
        $("body").html("");
        this.controller.set('band_menu_principal', false);
        this.controller.set('nombre_modulo', nombre_modulo);
        localStorage.setItem('nombre_modulo', nombre_modulo);
        var periodo_k = this.controller.get("periodo_k");
        window.document.location.href = '#/nom-037/evidencias/0/contrato-colectivo/' + periodo_k;
        document.location.reload();
      },
      goListaAsistencia: function goListaAsistencia(nombre_modulo) {
        $("body").html("");
        this.controller.set('band_menu_principal', false);
        this.controller.set('nombre_modulo', nombre_modulo);
        localStorage.setItem('nombre_modulo', nombre_modulo);
        window.document.location.href = '#/nom-037/evidencias/0/lista-de-asistencia';
        document.location.reload();
      },
      gonom037STPSEvidenciasPorPeriodo: function gonom037STPSEvidenciasPorPeriodo(periodo_k) {
        $("body").html("");
        localStorage.setItem("periodo_nom037_k", periodo_k);
        document.location.href = '/#/nom-037/evidencias/0';
        document.location.reload();
      }
    }
  });

  _exports.default = _default;
});