define("comunidades-nafin/components/nom-035-stps/reportes/cards/entorno-organizacional-card", ["exports", "comunidades-nafin/components/human-talent-components/h-t-card"], function (_exports, _hTCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTCard.default.extend({
    data: {
      total: 0,
      data: {
        aplicados: 0,
        restantes: 0,
        nivel_riesgo: "Sin asignar"
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('data', this.data);
      var data = this.get('data');

      if (data != null) {
        if (data.data != null) {
          //alert(data.data.nivel_riesgo);
          if (data.data.nivel_riesgo != null) {
            data.data.nivel_riesgo = data.data.nivel_riesgo != null ? data.data.nivel_riesgo : "Nulo";
            data.data.tipo_riesgo = data.data.nivel_riesgo.replace(" ", "_");
            this.set('data', data);
          }
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var data = this.get('data');
      window.localStorage.setItem("reporte-guia-3-datastudio", data.data.url_reporte); // Colocamos activa la tarjeta seleccionada

      var url = $(location).attr('href');
      var parts = url.split("/");
      var modulo = parts.pop();

      if (modulo === 'entorno-organizacional') {
        $("#entornoCard > .wrap_nom_hover").attr('style', 'opacity: 1 !important;');
        $("#entornoCard > .items-center > p").attr('style', 'color: #FFFFFF; !important;');
        $("#entornoCard .lbl_principal").attr('style', 'color: #FFFFFF !important;');
        $("#entornoCard .text-theme").attr('style', 'color: #FFFFFF !important;');
      }
    },
    actions: {}
  });

  _exports.default = _default;
});