define("comunidades-nafin/components/nom-035-stps/reportes/reports/estatus-de-capacitacion", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    title: 'Estatus de capacitación',
    clase: 'nom035stps/capacitaciones',
    metodo: 'estatusdecapacitaciones',
    reporte: 'nom035stps/capacitaciones/exportestatusdecapacitaciones',
    periodo_k: null,
    headers: [{
      dataIndex: 'nombre_usuario',
      title: 'Usuario'
    }, {
      dataIndex: 'puesto',
      title: 'Puesto'
    }, {
      dataIndex: 'sucursal',
      title: 'Centro de trabajo'
    }, {
      dataIndex: 'departamento',
      title: 'Departamento'
    }, {
      dataIndex: 'curso',
      title: 'Curso'
    }, {
      dataIndex: 'estatus',
      title: 'Estatus'
    }],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('params', {
        'periodo_k': this.periodo_k
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      setTimeout(function () {
        $('#tab_estatuscapa').dataTable({
          retrieve: true,
          language: {
            "processing": "Procesando...",
            "lengthMenu": "Mostrar _MENU_ registros",
            "zeroRecords": "No se encontraron resultados",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "search": "Buscar:",
            "infoThousands": ",",
            "loadingRecords": "Cargando...",
            "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
          }
        });
      }, 2000);
    }
  });

  _exports.default = _default;
});