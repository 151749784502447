define("comunidades-nafin/templates/nom-035-stps/acontecimientos-traumaticos-severos/administrador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L36VzKHU",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/reportes-administrador-nom-035.css\"],[8],[9],[0,\"\\n\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\"],[14,\"loading\",[]],[0,\"\\n\\n\"],[2,\" \\n<div class=\\\"container-fluid\\\" id=\\\"panel\\\">\\n\\n    <div class=\\\"row mb-2\\\">\\n        <div class=\\\"col-12\\\"> \"],[0,\"\\n            \"],[1,[20,\"outlet\"],false],[0,\"\\n            \"],[2,\" \\n        </div>\\n    </div>\\n\\n</div>\\n\"],[0,\"\\n\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/nom-035-stps/acontecimientos-traumaticos-severos/administrador.hbs"
    }
  });

  _exports.default = _default;
});