define("comunidades-nafin/routes/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/estatus-aplicacion-guias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('periodo_k', model.periodo_k); // Obtenemos la URL del sitio actual

      var url_actual = window.location.href;
      var url_reg = url_actual.split("/");
      var nombre_page = url_reg[url_reg.length - 2];
      setTimeout(function () {
        $(".sidebar__item").removeClass("-is-active -dark-bg-dark-2");

        if (nombre_page === 'estatus-aplicacion-guias') {
          $("#wrap_estatus_aplicacion").addClass("-is-active -dark-bg-dark-2");
        } // En el caso de utilizar los Tabs del nuevo template


        $('.tabs__pane').css({
          display: 'none'
        });
        $('.is-active').css({
          display: 'block'
        });
        $(".tabs__button").unbind().click(function () {
          var elemento = $(this);
          var tab = elemento.data('tab-target');
          $('.tabs__pane').css({
            display: 'none'
          });
          $(tab).css({
            display: 'block'
          });
        });
      }, 500);
      /* Mostramos menu dashboard en caso de movil 
      if (window.innerWidth <= 768) 
      {
          $('#btn_menu_dashboard').show();
      }
      else
      {
          $('#btn_menu_dashboard').css( 'cssText', 'display: none !important;' );
      }
      */
    },
    actions: {
      showFormRequestGenerateZip: function showFormRequestGenerateZip(record) {
        /**
         * [YURAM] HACK Begin
         *
         * Los cambios aplicados a continuación fueron aplicados a causa de una incompatibilidad entre componentes y elementos
         *
         */
        $("#compresoK").val(null);
        $("#inputEmail").val('');
        $('#requestFormMassiveUserReports').modal({});
        /**
         * [YURAM] HACK End
         */
      },
      bindScopeToReportAtsGeneracionMasivaReportesComponent: function bindScopeToReportAtsGeneracionMasivaReportesComponent(scope_reporte_ats_generacion_masiva_reportes) {
        this.reporteGeneracionMasivaReportesScope = scope_reporte_ats_generacion_masiva_reportes;
      },
      afterCreate: function afterCreate() {
        if (this.reporteGeneracionMasivaReportesScope) {
          if (isFunction(this.reporteGeneracionMasivaReportesScope.propagationAfterCreateEvent)) {
            this.reporteGeneracionMasivaReportesScope.propagationAfterCreateEvent();
          }
        }
      },
      afterUpdate: function afterUpdate() {}
    }
  });

  _exports.default = _default;
});