define("comunidades-nafin/components/nom-035-stps/reportes/reports/ats-generacion-masiva-reportes-de-respuestas", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    title: 'Exportar y descargar los reportes de respuestas de usuarios',
    clase: 'nom035stps/acontecimientostraumaticosseveros',
    metodo: 'listargeneracionmasivareportesderespuestasusuariostotal',
    reporte: null,
    buttonGenerateZipIsVisible: true,
    periodo_k: null,
    headers: [{
      dataIndex: 'fecha_hora_inicio_generacion',
      title: 'Fecha y Hora'
    }, {
      dataIndex: 'estatus',
      title: 'Estatus'
    }, {
      dataIndex: 'numero_documentos',
      title: 'Número documentos'
    }, {
      dataIndex: 'email_to',
      title: 'Email registrado'
    }, {
      dataIndex: 'download_path',
      title: 'Descargar'
    }],

    /**
     *
     **/
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('buttonGenerateZipIsVisible', this.buttonGenerateZipIsVisible);
      this.set('params', {
        'periodo_k': this.periodo_k
      });
      this.bindScopeToParent(this);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      setTimeout(function () {
        $('#tab_ats_generacion_masiva').dataTable({
          retrieve: true,
          language: {
            "processing": "Procesando...",
            "lengthMenu": "Mostrar _MENU_ registros",
            "zeroRecords": "No se encontraron resultados",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "search": "Buscar:",
            "infoThousands": ",",
            "loadingRecords": "Cargando...",
            "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
          }
        });
      }, 2000);
    },
    bindScopeToParent: function bindScopeToParent(_scope) {
      this.onBindScopeToParent(_scope);
    },
    propagationAfterCreateEvent: function propagationAfterCreateEvent() {
      this.loadData();
    },

    /**
     *
     */
    actions: {
      showCreateModal: function showCreateModal() {
        this.onShowFormRequestGenerateZip();
      }
    }
  });

  _exports.default = _default;
});