define("comunidades-nafin/components/courses-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Esto indica que el componente no debe envolverse en un div adicional
    classNames: [],
    // Esto asegura que no se apliquen clases adicionales al div
    router: Ember.inject.service(),
    offset: 0,
    comunidad_k: "",
    deshabilitarBotonMasCursos: function deshabilitarBotonMasCursos() {
      Ember.$('#boton-mostrar-mas-cursos-usuario').hide();
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      self.comunidad_k = localStorage.getItem("comunidad_k") != null && localStorage.getItem("comunidad_k") != undefined ? localStorage.getItem("comunidad_k") : "";
      var dataPost = 'params={"agrupar":"agrupar_por_cursos"}';

      if (self.comunidad_k != "") {
        dataPost = 'params={"agrupar":"agrupar_por_cursos","comunidad_k":' + self.comunidad_k + '}';
      }

      var position = Ember.$(window).scrollTop();
      /*
      		$(window).scroll(function() {
      			var scroll = $(window).scrollTop();
      			if(scroll > position) {
      				self.send("mostrarMasCursos");
      			} 
      			position = scroll;
      		});
      		*/

      Ember.$.ajax({
        url: constant.APIURL + '/comunidad/listarMisInscripcionesRa',
        type: 'POST',
        async: false,
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        data: dataPost,
        success: function success(r) {
          var r = JSON.parse(r);
          Object.keys(r.data).forEach(function (idx) {
            r.data[idx].boton_ir_curso = self.obtener_etiqueta_boton_string(r.data[idx].estatus_ruta_aprendizaje);

            if (r.data[idx].porcentaje_avance) {
              var porcentaje = parseInt(r.data[idx].porcentaje_avance);
              r.data[idx].porcentaje_avance = porcentaje;
              r.data[idx].porcentaje_avance_css = Ember.String.htmlSafe('width:' + porcentaje + '%');
            }

            if (r.data[idx].estatus === "8") {
              r.data[idx].imagen = "/img/cursos/aprobado.png";
            }
            /*
             *	Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
             *
             *	Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
             *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
             *  del servicio.
             * /
            	if (curso_imagen) {
            		if (curso_imagen[r.data[idx].ra_k]) {
            			r.data[idx].imagen_raprendizaje = curso_imagen[r.data[idx].ra_k];
            		}
            }
            	/*
             * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
             */


            r.data[idx].imagen_raprendizaje = solveImageURL(r.data[idx].imagen_raprendizaje);
            r.data[idx].nombre_completo = r.data[idx].nombre;

            if (r.data[idx].nombre != null && r.data[idx].nombre.length > 50) {
              var nomb = r.data[idx].nombre.toString();
              r.data[idx].nombre = nomb.substr(0, 47) + "...";
            }
          }); //Vamos por la calificación de los cursos

          Ember.$.ajax({
            url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
            contentType: 'application/x-www-form-urlencoded',
            xhrFields: {
              withCredentials: true
            },
            type: 'POST',
            async: false,
            data: {
              "method": "score_courses_resume"
            },
            success: function success(response_text) {
              var score_courses_resume = JSON.parse(response_text);
              self.set("allCoursesScores", score_courses_resume.data);
              r.data.forEach(function (item, idx) {
                if (score_courses_resume.data) {
                  if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined' && score_courses_resume.data[r.data[idx].ra_k] != null) {
                    r.data[idx].ranking_raprendizaje_score = Math.round(score_courses_resume.data[r.data[idx].ra_k].rate);
                  } else {
                    r.data[idx].ranking_raprendizaje_score = 0;
                  }
                } else {
                  r.data[idx].ranking_raprendizaje_score = 0;
                }
              });
              localStorage.setItem("totalCursos", r.data.length); //Se ordennan por ranking

              r.data = r.data.sort(function (b, a) {
                return a.ranking_raprendizaje_score > b.ranking_raprendizaje_score ? 1 : b.ranking_raprendizaje_score > a.ranking_raprendizaje_score ? -1 : 0;
              });
              self.set("allCourses", r.data);
              var cursosV = [];

              for (var i = 0; i < 12; i++) {
                if (r.data[self.offset + i]) {
                  cursosV.push(r.data[self.offset + i]);
                }
              }

              self.set("cursosV", cursosV);
              self.offset += 12;
              setTimeout(function () {
                Ember.$('#loading').fadeOut('slow');
                lazyLoad.update();
              }, 3000); //});
            }
          });
          Ember.$('#loading').fadeOut('slow'); //console.log(r.data)
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    obtener_etiqueta_boton_string: function obtener_etiqueta_boton_string(estatus) {
      var estatus_string = 'Comenzar';

      if (parseInt(estatus) === 0) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_comenzar.valor', "Comenzar"); // Programado
      } else if (parseInt(estatus) === 1) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_continuar.valor', "Continuar"); // En curso
      } else if (parseInt(estatus) === 2) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_revisar.valor', "Revisar"); // Cancelado
      } else if (parseInt(estatus) === 3) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_continuar.valor', "Continuar"); // Reabierto
      } else if (parseInt(estatus) === 4) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_revisar.valor', "Revisar"); // Cerrado Completo
      } else if (parseInt(estatus) === 5) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_revisar.valor', "Revisar"); // Cerrado Incompleto
      } else if (parseInt(estatus) === 6) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_revisar.valor', "Revisar"); // Preinscrito
      } else if (parseInt(estatus) === 7) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_comenzar.valor', "Comenzar"); // Inscrito
      } else if (parseInt(estatus) === 8) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_revisar.valor', "Revisar"); // Aprobado
      } else if (parseInt(estatus) === 9) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_revisar.valor', "Revisar"); // Reprobado
      } else if (parseInt(estatus) === 10) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_revisar.valor', "Revisar"); // Visitado
      } else if (parseInt(estatus) === 11) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_revisar.valor', "Revisar"); // No asistió
      } else if (parseInt(estatus) === 12) {
        estatus_string = window.translate('comunidades.curso_especifico.lbl.lbl_cursos_recurso_etiquetaboton_revisar.valor', "Revisar"); // Baja
      }

      return estatus_string;
    },
    numOfTimes: Ember.computed('times', function () {
      return [1, 2, 3, 4, 5];
    }),
    didRender: function didRender() {
      Ember.$('.8').removeAttr('hidden');
    },
    actions: {
      detallesCurso: function detallesCurso(curso) {
        if (Ember.$("#curso_" + curso.ra_k).find(".boton_abrir_curso").hasClass("d-none")) {
          Ember.$("#curso_" + curso.ra_k).find(".boton_abrir_curso").removeClass("d-none");
        } else {
          Ember.$("#curso_" + curso.ra_k).find(".boton_abrir_curso").addClass("d-none");
        }

        if (Ember.$("#curso_" + curso.ra_k).find(".modulos").hasClass("curso-descripcion")) {
          Ember.$("#curso_" + curso.ra_k).find(".modulos").removeClass("curso-descripcion");
        } else {
          Ember.$("#curso_" + curso.ra_k).find(".modulos").addClass("curso-descripcion");
        }
      },
      cursoEspecifico: function cursoEspecifico(path) {
        var nombre_comunidad = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
        var imagen_ra = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
        localStorage.setItem('imagen_ra', imagen_ra);
        localStorage.setItem('nombre_comunidad', nombre_comunidad);
        /* this.get('router').transitionTo('cursos/curso-especifico', path); */

        window.document.location.href = '/#/cursos/' + path;
        window.location.reload();
      },
      cursoEspecificoMovil: function cursoEspecificoMovil(path) {
        var nombre_comunidad = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
        var imagen_ra = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
        Ember.$("#loading").show();
        localStorage.setItem('imagen_ra', imagen_ra);
        localStorage.setItem('nombre_comunidad', nombre_comunidad);
        var url = window.location.href.split("#")[0] + "#/cursos/" + path;
        window.location.href = url;
        window.location.reload();
      },
      mostrarMasCursos: function mostrarMasCursos() {
        var allCourses = this.get("allCourses") != undefined ? this.get("allCourses") : [];
        var cursosV_temporal_array = [];

        var _self = this;

        var cuenta = 0;
        allCourses.forEach(function (_curso_record, _index, _cursos_lista_array) {
          cuenta += 1;
          /*
           *	Obtenemos la imagen de la ruta de aprendizaje, a patir de los siguienttes criterios:
           *
           *	Si existe una referencia a una imagen desde la relación local ( curso_imagen ), para
           *  la ruta de aprendizaje, se le da prioidad, en caso contario, se toma la imagen poveniente
           *  del servicio.
           * /
          	if (curso_imagen) {
          		if (curso_imagen[Ember.get(_curso_record, 'ra_k')]) {
          			Ember.set(_curso_record, 'imagen_raprendizaje', curso_imagen[Ember.get(_curso_record, 'ra_k')]);
          		}
          }
          	/*
           * Generamos la URL absoluta de la imagen, hacia el sitio en donde se encuetran los recursos gráficos.
           */

          Ember.set(_curso_record, 'imagen_raprendizaje', solveImageURL(_curso_record.imagen_raprendizaje));

          if (_index < _self.offset + 12) {
            if (_curso_record) {
              cursosV_temporal_array.pushObject(_curso_record);
            }
          }
        });
        this.offset += 12;
        this.set("cursosV", cursosV_temporal_array);
        /*
        La forma en cómo esta solucionado el mostrarMasCursos() se debe cambiar ya que para que desaparezca dicho botón, 
        se tuvo que realizar una solución parcial debido a que no se tiene el suficiente control en el listado de los cursos.
        */

        if (cuenta == cursosV_temporal_array.length) {
          _self.deshabilitarBotonMasCursos();
        }
      }
    }
  });

  _exports.default = _default;
});