define("comunidades-nafin/routes/mediateca", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      /*
      let promise = new RSVP.Promise(function (resolve) {
      	later(this, function () {
      		resolve("ok");
      	}, 1000);
      });
      return promise;
      */
      if (!window.constant.BAND_RELOAD) {
        $("body").html("");
        window.location.reload();
      }
    },
    setupController: function setupController(controller) {
      setTimeout(function () {
        /* Mostramos menu dashboard en caso de movil */
        if (window.innerWidth <= 768) {
          $('#btn_menu_dashboard').show();
        } else {
          $('#btn_menu_dashboard').css('cssText', 'display: none !important;');
        }
      }, 1000);
    }
  });

  _exports.default = _default;
});