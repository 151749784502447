define("comunidades-nafin/components/nom-035-stps/reportes/tables/carpeta-evidencias-consultas", ["exports", "comunidades-nafin/components/human-talent-components/h-t-table"], function (_exports, _hTTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTTable.default.extend({
    params: {},
    data: null,
    isStriped: false,
    headers: null,
    idTable: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('params', this.params);
      this.set('data', this.data);
      this.set('headers', this.headers);
      this.set('isStriped', this.isStriped);
      this.set('idTable', this.idTable);
    },
    didInsertElement: function didInsertElement() {
      this.loadData();
    },
    loadData: function loadData() {
      if (this.data) {
        this.data;
      } else if (String(this.metodo).length > 0 && String(this.clase).length > 0) {
        var self = this;
        $.ajax({
          url: constant.APIURL + String(this.clase) + '/' + String(this.metodo),
          type: "post",
          data: this.get('params'),
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              self.set('data', response.data);
            }

            self.set('data', response.data);
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    },
    actions: {
      load: function load() {
        this.load();
      },
      reload: function reload() {
        this.load();
      },
      toggleDetalle: function toggleDetalle(record) {
        if (record.isDetalleVisible == true) {
          Ember.set(record, 'isDetalleVisible', false);
        } else {
          Ember.set(record, 'isDetalleVisible', true);
        }
      }
    }
  });

  _exports.default = _default;
});