define("comunidades-nafin/routes/nom-035-stps/reportes/administrador/acontecimientos-traumaticos-severos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      setTimeout(function () {
        $(".card-header").removeClass("alert-info");
        $("#atsCard").addClass("alert-info"); // En el caso de utilizar los Tabs del nuevo template

        $('.tabs__pane').css({
          display: 'none'
        });
        $('.is-active').css({
          display: 'block'
        });
        $(".tabs__button").unbind().click(function () {
          var elemento = $(this);
          var tab = elemento.data('tab-target');
          $('.tabs__pane').css({
            display: 'none'
          });
          $(tab).css({
            display: 'block'
          });
        });
      }, 500);
    }
  });

  _exports.default = _default;
});